<template>
  <div
    id="hey"
    v-if="isViewPhotoModalShowing"
    class="modal-viewer"
    :class="{
      active: isViewPhotoModalShowing,
      hidden: !isViewPhotoModalShowing,
    }"
    @click="cancel()"
  >
    <div class="ui container modal-viewer-container">
      <div class="ui image image-preview">
        <img :src="imageSrc" />
      </div>
      <p
        @click="cancel"
        style="margin-top: 10px; color: white; text-align: center"
      >
        Tippen, um die Vorschau zu schließen.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalImageViewer",
  props: ["imageSrc", "isViewPhotoModalShowing", "isViewPhotoModalConfirmed"],

  methods: {
    cancel() {
      this.$emit("update:isViewPhotoModalShowing", false);
      this.$emit("update:isViewPhotoModalConfirmed", true);
    },
    mounted() {
      this.$nextTick(() => {
        $(".hello-world").dimmer("toggle");
      });
    },
  },
};
</script>

<style>
.modal-viewer {
  position: fixed;
  top: 0;
  margin: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 2000;
  opacity: 0;
  cursor: pointer;
}

.modal-viewer.active {
  opacity: 1;
  background: rgba(0, 0, 0, 0.658);
  animation: fadeIn 0.2s;
}

.modal-viewer-container {
  padding: 15px;
}

.image-preview {
  margin-top: 40px;
  border-radius: 8px;
  overflow: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>

<style scoped>
/* // .ui.modal > .content {
//   justify-content: center;
// }
// .ui.dimmer {
//   padding-top: 120px;
// }

// .image-preview {
//   margin: 0 auto;
// }

// @media screen and (min-width: 1025px) {
//   .ui.dimmer {
//     padding-top: 10%;
//   }
//   .modal-preview {
//     padding-left: 20vw;
//   }
// }
// */
</style>
