<template>
  <div class="ui grouped fields" :class="{
    disabled: response.editStatus > 0 && !userIsAdmin,
    required: isFieldRequired(field),
  }">
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">
      {{ field.sectionDescription }}
    </p>
    <div class="ui field grouped fields" :class="{
      disabled: response.editStatus > 0 && !userIsAdmin,
      required: isFieldRequired(field),
    }" :required="isFieldRequired(field)">
      <label v-if="field.headingTitle">
        {{ field.headingTitle }}
      </label>
      <p v-if="field.headingDescription">
        {{ field.headingDescription }}
      </p>
      <div class="ui form">

        <div v-for="subfield in field.formula.fields" v-bind:key="subfield.fieldId" class="field" :class="{
          disabled: (response.editStatus > 0 && !userIsAdmin) || field.readonly,
          required: isFieldRequired(field),
        }" :required="isFieldRequired(field)">
          <label v-if="subfield.label">
            {{ subfield.label }}
          </label>
          <input v-if="field.minValue || field.maxValue" type="number" :min="field.minValue" :max="field.maxValue"
            name="description" placeholder="Numerischen Wert eintragen…" :value="subfieldInputValue(subfield)"
            :required="isFieldRequired(field)" @blur="setCalculatedSubfieldText(subfield, $event)" />
          <input v-else type="text" :pattern="setRegExPattern(field)" name="description"
            placeholder="Numerischen Wert eintragen…" :value="subfieldInputValue(subfield)" :required="isFieldRequired(field)"
            @blur="setCalculatedSubfieldText(subfield, $event)" />
        </div>

        <div class="field">
          <label>= Berechnetes Ergebnis</label>
          <input type="text" :value="descriptionValue" readonly="" disabled style="opacity: .6;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isDescriptionRequired,
  isFieldRequired,
  setDescriptionText,
  setCalculatedValue,
} from "@/lib/FormUtil";

export default {
  name: "FormOpenEndedCalculated",
  props: ["field", "inquiry", "job", "response", "userIsAdmin"],
  asyncComputed: {
    /* eslint-disable */
    descriptionValue: {
      get() {
        const field = this.response.fields.find(f => f.id === this.field.id);
        if (!field) {
          return null;
        }

        return field.text;
      },
      watch() {
        this.response;
      },
    },
    /* eslint-enable */
  },
  computed: {
    subfieldInputValue()  {
      return (subfield) => {
        const responseField = this.response.fields.find(f => f.id === this.field.id);
        if (!responseField) {
          return null;
        }

        const inputIndex = responseField.inputs.findIndex(input => input.fieldId == subfield.fieldId);
        if (inputIndex < 0) {
          return null;
        }
        return responseField.inputs[inputIndex].value;
      };
    }
  },
  methods: {
    isFieldRequired(field) {
      return isFieldRequired(field);
    },
    isDescriptionRequired(field) {
      return isDescriptionRequired(field);
    },
    setDescriptionText(field, event) {
      setDescriptionText(field.id, this.response, event.target.value);
    },
    setCalculatedSubfieldText(subfield, event) {
      setCalculatedValue(this.field.id, this.response, subfield, this.field.formula.operation, {
        fieldId: subfield.fieldId,
        value: event.target.value
      });
    },
    setRegExPattern(field) {
      if (field.minLength && field.maxLength) {
        return `[0-9,-]{${field.minLength},${field.maxLength}}([-,\.][0-9]+)?`;
      } else if (field.minLength && !field.maxLength) {
        return `[0-9,-]{${field.minLength},}([-,\.][0-9]+)?`;
      } else if (!field.minLength && field.maxLength) {
        return `[0-9,-]{0,${field.maxLength}}([-,\.][0-9]+)?`;
      } else {
        return "[0-9,-]+([-,\.][0-9]+)?";
      }
    }
  },
};
</script>
