var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui grouped fields",class:{
    disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
    required: _vm.isFieldRequired(_vm.field),
  }},[(_vm.field.sectionTitle)?_c('h4',{staticClass:"ui dividing header"},[_vm._v(" "+_vm._s(_vm.field.sectionTitle)+" ")]):_vm._e(),(_vm.field.sectionDescription)?_c('p',[_vm._v(_vm._s(_vm.field.sectionDescription))]):_vm._e(),_c('div',{staticClass:"ui field grouped fields",class:{
      disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
      required: _vm.isFieldRequired(_vm.field),
    }},[(_vm.field.headingTitle)?_c('label',[_vm._v(_vm._s(_vm.field.headingTitle))]):_vm._e(),(_vm.field.headingDescription)?_c('p',[_vm._v(_vm._s(_vm.field.headingDescription))]):_vm._e(),_c('div',{staticClass:"ui fluid search selection dropdown",class:{
        disabled: (_vm.response.editStatus > 0 && !_vm.userIsAdmin) || _vm.field.readonly,
        required: _vm.isFieldRequired(_vm.field),
      },attrs:{"name":_vm.field.id}},[_c('input',{attrs:{"type":"hidden","name":_vm.field.id}}),_c('i',{staticClass:"dropdown icon"}),_c('div',{staticClass:"default text"},[_vm._v("Option auswählen …")]),_c('div',{staticClass:"menu"},[_vm._l((_vm.field.choices),function(choice){return [_c('div',{key:choice.id,staticClass:"item",attrs:{"data-value":choice.text,"v-bind":choice,"id":choice.id}},[_vm._v(" "+_vm._s(choice.text)+" ")])]})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }