<template>
  <div class="ui container container--list">
    <template v-for="job in jobs">
      <SyncListItem
        :job="job"
        :key="job.id"
        :responses="responses"
        :images="images"
      />
    </template>
  </div>
</template>

<script>
import SyncListItem from "@/components/SyncListItem";

export default {
  name: "SyncList",
  props: ["jobs", "responses", "images"],
  components: {
    SyncListItem,
  },
};
</script>
