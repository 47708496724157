<template>
  <div>
    <nav class="ui top fixed menu">
      <span>Einstellungen</span>
    </nav>
    <main class="ui container">
      <div class="ui cards">
        <div class="card" style="width: 100%">
          <div class="content">
            <div class="header">
              <h4>Benutzerkonto</h4>
            </div>
            <div v-if="user" class="description">
              Der Benutzer
              <strong>{{ user.username }}</strong>
              hat sich {{ getTimestamp(user.createdAt) }} auf diesem Gerät
              angemeldet.
            </div>
            <div v-if="error" class="ui red message">
              <div class="header">Fehler aufgetreten</div>
              <p>Beim letzten Vorgang ist ein Fehler aufgetreten.</p>
              <p>Fehlermeldung: {{ error }}</p>
            </div>
          </div>
          <div class="extra content">
            <div
              class="ui button fluid btn-logout"
              @click="isUserLogoutModalShowing = true"
              v-bind:class="{ red: hasPendingItems() }"
            >
              <i class="power icon"></i>
              Benutzer abmelden
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="header">
              <h4>App-Version</h4>
            </div>
            <div class="description">
              Die installierte Versionsnummer lautet {{ currentVersion }}.
            </div>
          </div>
          <div class="extra content" v-bind:class="{ disabled: online }">
            <div
              v-if="remoteVersion && currentVersion !== remoteVersion"
              class="ui button fluid btn-update primary"
              @click="clickedCheckForUpdates"
            >
              <i class="refresh icon"></i>
              Auf Version {{ remoteVersion }} aktualisieren
            </div>
            <div
              v-else
              class="ui button fluid btn-update"
              @click="clickedCheckForUpdates"
            >
              <i class="refresh icon"></i>Nach Aktualisierung suchen
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="header">
              <h4>Support</h4>
            </div>
            <div class="description">
              Falls bei der Nutzung Probleme auftreten sollten, kann zwischen
              diesen beiden Funktionen gewählt werden.
            </div>
          </div>
          <div class="extra content">
            <div class="ui button fluid" @click="clickedDiagnosis">
              <i class="terminal icon"></i>Support öffnen
            </div>
          </div>
        </div>
      </div>
    </main>
    <tab-bar
      :pendingJobs="pendingJobs"
      :pendingResponses="pendingResponses"
    ></tab-bar>

    <modal black v-model="isUserLogoutModalShowing" modalVariation="basic">
      <div slot="header" class="center btn-logout">
        <div v-if="hasPendingItems()">
          <i class="exclamation triangle icon"></i>
          Datenverlust möglich
        </div>
        <div v-else>
          <i class="power icon"></i>
          Benutzer abmelden
        </div>
      </div>
      <div class="content" slot="content">
        <div v-if="hasPendingItems() && !userIsAdmin">
          <p>
            <strong>
              Es befinden sich noch nicht abgeschlossene Aufträge auf dem Gerät
              bzw. nicht übertragene Aufträge in der Warteschlange.
            </strong>
          </p>
          <p>
            Um einen Datenverlust zu verhindern, ist eine Abmeldung erst
            möglich, wenn alle bearbeiteten Aufträge erfolgreich abgeschlossen
            und die Warteschlange vollständig übertragen worden ist.
          </p>
        </div>
        <div v-else class="ui red">
          <p>
            <strong>Möchten Sie sich wirklich abmelden?</strong>
          </p>
          <p>
            Für eine erneute Anmeldung ist eine aktive Internetverbindung
            erforderlich.
          </p>
        </div>
      </div>
      <template v-if="hasPendingItems() && !userIsAdmin" slot="actions">
        <div
          class="ui black deny inverted fluid button"
          @click="isUserLogoutModalShowing = false"
          style="margin-left: 0"
        >
          Abbrechen
        </div>
      </template>
      <template v-else slot="actions">
        <div
          class="ui black deny inverted button"
          @click="isUserLogoutModalShowing = false"
        >
          <i class="remove icon"></i> Nein
        </div>
        <div
          class="ui red right inverted button"
          @click="confirmUserLogoutModal"
        >
          <i class="checkmark icon"></i> Ja
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import modal from "vue-semantic-modal";
import TabBar from "./../components/TabBar";
import { getVersionFromManifest } from "./../util/helper";

moment.locale("de");

export default {
  name: "settings",
  components: {
    TabBar,
    modal,
  },
  data() {
    return {
      loading: false,
      error: null,
      currentVersion: null,
      remoteVersion: null,
      isUserLogoutModalShowing: false,
      isUserLogoutConfirmed: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/current",
      userIsAdmin: "user/isAdmin",
      notCompletedJobs: "job/drafts",
      pendingJobs: "job/pending",
      pendingResponses: "response/pending",
    }),
  },
  async created() {
    this.online = window.navigator.onLine;
    this.currentVersion = process.env.VUE_APP_RELEASE;
    this.remoteVersion = await getVersionFromManifest();
  },
  methods: {
    hasPendingItems() {
      return (
        (this.pendingJobs && this.pendingJobs.length > 0) ||
        (this.notCompletedJobs && this.notCompletedJobs.length > 0) ||
        (this.pendingResponses && this.pendingResponses.length > 0)
      );
    },
    getInquiriesFromIdb() {
      this.loading = true;
      this.$store.dispatch("job/setJobs");
      this.loading = false;
    },
    getTimestamp(date) {
      moment.locale("de");
      if (date) {
        return moment(date).fromNow();
      }
      return null;
    },
    showUserLogoutModal() {
      this.isUserLogoutModalShowing = true;
      this.isUserLogoutConfirmed = false;
    },
    confirmUserLogoutModal() {
      this.isUserLogoutModalShowing = false;
      this.isUserLogoutConfirmed = true;

      const logoutBtn = document.querySelector(".btn-logout");
      logoutBtn.className += " loading";

      this.$store.dispatch("user/logout").then(() =>
        this.$router.push({
          path: "/",
        })
      );
    },
    clickedDiagnosis() {
      this.$router.push({
        path: "/settings/diagnosis",
      });
    },
    clickedCheckForUpdates() {
      const updateBtn = document.querySelector(".btn-update");
      const classListBackup = updateBtn.classList;
      updateBtn.classList += " loading";

      setTimeout(() => {
        this.loading = true;
        this.$store.dispatch("sync/setIsSyncing", false);
        window.location.reload(true);
        updateBtn.classList = classListBackup;
        this.loading = false;
      }, 300);
    },
  },
};
</script>

<style scoped>
.card {
  width: 100% !important;
}
.cards {
  padding-bottom: 50px;
}
</style>
