import { EventBus } from "@/event-bus";
import * as types from "../mutation-types";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  isSyncing: false,
};

/**
 * Getters
 */
export const getters = {
  isSyncing: state => state.isSyncing,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_IS_SYNCING](state, isSyncing) {
    state.isSyncing = isSyncing;
  },
};

/**
 * Actions
 */
export const actions = {
  /**
   * Toggles isSyncing state
   */
  setIsSyncing({ commit }, isSyncing) {
    commit(types.SET_IS_SYNCING, isSyncing);
  },
  notifySuccess() {
    EventBus.$emit("synced");
  },
  notifyError(_, error) {
    EventBus.$emit("synced-error");
    console.error("Error in performSync", error.stack || error);
  },
  /**
   * Download inquiries and jobs
   */
  download({ state, dispatch }) {
    if (state.isSyncing) {
      return false;
    }

    return dispatch("setIsSyncing", true)
      .then(() => dispatch("inquiry/fetch", {}, { root: true }))
      .then(() => dispatch("job/fetch", {}, { root: true }))
      .then(() => dispatch("setIsSyncing", false))
      .catch(error => {
        dispatch("notifyError", error);
        dispatch("setIsSyncing", false);
      })
      .finally(() => dispatch("setIsSyncing", false));
  },
  /**
   * Uploading pending jobs, responses and images
   */
  upload({ state, dispatch }) {
    if (state.isSyncing) {
      return false;
    }

    return dispatch("setIsSyncing", true)
      .then(() => dispatch("response/uploadPending", {}, { root: true }))
      .then(() => dispatch("job/uploadPending", {}, { root: true }))
      .then(() => dispatch("response/deleteCompleted", {}, { root: true }))
      .then(() => dispatch("job/deleteCompleted", {}, { root: true }))
      .then(() => dispatch("notifySuccess"))
      .catch(error => {
        dispatch("notifyError", error);
        dispatch("setIsSyncing", false);
      })
      .finally(() => dispatch("setIsSyncing", false));
  },
};
