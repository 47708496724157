import { apiClient } from "./ApiClient";

export default {
  download() {
    return apiClient.sharedInstance().get("jobs");
  },
  downloadById(params) {
    return apiClient.sharedInstance().get(`jobs/${params.id}`);
  },
  upload(params) {
    const job = {
      id: params.id,
      editStatus: params.editStatus,
    };
    return apiClient.sharedInstance().post("jobs", job);
  },
};
