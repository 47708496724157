<template>
  <div
    v-bind="job"
    v-bind:key="job.id"
    class="ui fluid card"
    v-if="job.syncStatus >= 0 && job.syncStatus < 2"
  >
    <div class="content">
      <div class="content__status-edit">
        <div
          class="ui top right attached label tiny"
          :class="{
            yellow: job.editStatus === 1,
            green: job.editStatus === 2,
          }"
        >
          {{
            job.editStatus === 1
              ? "IN BEARBEITUNG"
              : job.editStatus === 2
              ? "ABGESCHLOSSEN"
              : ""
          }}
        </div>
      </div>
      <div class="header">{{ job.jobNumber }}</div>
      <div class="extra content">
        <div class="item">
          <div class="content">
            <span class="header deactive">
              <strong>Auftrag</strong>
            </span>
            <br />
            <div class="description deactive">
              <span
                class="ui inline loader tiny"
                :class="{ active: job.syncStatus === 1 }"
              ></span>
              {{
                job.syncStatus === 0
                  ? "0 % übertragen …"
                  : job.syncStatus === 1
                  ? "wird übertragen …"
                  : job.syncStatus === 2
                  ? "100 % übertragen"
                  : ""
              }}
            </div>
          </div>
        </div>
        <div
          class="ui list"
          v-for="response in responses"
          :bind="response"
          :key="response.id"
        >
          <div v-if="response.jobNumber === job.jobNumber">
            <div class="item">
              <div class="content">
                <span class="header deactive">
                  <strong>Rückmeldung zum Auftrag</strong>
                </span>
                <br />
                <div class="description deactive">
                  <span
                    class="ui inline loader tiny"
                    :class="{ active: response.syncStatus === 1 }"
                  ></span>
                  {{
                    response.syncStatus === 0
                      ? "0 % übertragen …"
                      : response.syncStatus === 1
                      ? "wird übertragen …"
                      : response.syncStatus === 2
                      ? "100 % übertragen"
                      : ""
                  }}
                </div>
              </div>
            </div>
            <div
              class="item"
              v-for="image in images"
              :bind="image"
              :key="image.id"
            >
              <template v-if="image.responseId === response.id">
                <div class="content">
                  <p>
                    <span class="header deactive">
                      <strong>Foto</strong>
                    </span>
                    <br />
                    <span class="description deactive">
                      <span
                        class="ui inline loader tiny"
                        :class="{ active: image.syncStatus === 1 }"
                      ></span>
                      {{ getUploadProgressForFile(image) }}
                    </span>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { formatBytes, formatPercentage } from "../util/filters";

moment.locale("de");

export default {
  name: "SyncListItem",
  props: ["job", "responses", "images"],
  methods: {
    hasJobPendingResponses(job) {
      if (!this.responses || this.responses.length === 0) {
        return 0;
      }

      const responses = this.responses.filter(
        response => response.jobId === job.id && response.syncStatus < 2
      );
      return responses.length > 0;
    },
    getUploadProgressForFile(image) {
      let localCurrent = image.uploadCurrentRange || 0;
      let localTotal = image.size || 0;

      let percentage = null;
      if (localCurrent === 0 && !image.uploadedAt) {
        percentage = 0;
      } else if (image.syncStatus === 2) {
        percentage = 100;
      } else {
        percentage = formatPercentage(localCurrent, localTotal);
      }

      localTotal = formatBytes(localTotal);

      if (percentage && (percentage === 0 || percentage >= 100)) {
        return `${percentage} % (${localTotal}) übertragen`;
      }

      localCurrent = formatBytes(localCurrent);
      return `${percentage} % (${localCurrent} / ${localTotal}) übertragen`;
    },
    getTimestamp(date) {
      if (date) {
        return moment(date).fromNow();
      }
      return null;
    },
  },
};
</script>

<style scoped>
.item .content,
.extra.content {
  padding-top: 4px !important;
}

.ui.list {
  margin: 0 !important;
}

.ui.tiny.loader:before,
.ui.tiny.loader:after {
  width: 12px;
  height: 12px;
}
</style>
