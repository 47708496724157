<template>
  <div>
    <NavBarJobs
      v-on:load="loadRemoteJobs()"
      :loading="syncing"
      :isAdmin="admin"
      :jobs="jobs"
    />
    <main class="ui vertical-center">
      <JobListEmptyState
        v-on:load="loadRemoteJobs()"
        :loading="syncing"
        :isAdmin="isAdmin"
        :jobs="jobs"
      />

      <SyncButton
        v-if="
          (pendingJobs && pendingJobs.length > 0) ||
          (pendingResponses && pendingResponses.length > 0)
        "
        :loading="loading"
        :syncing="syncing"
        :pendingJobs="pendingJobs"
        :pendingResponses="pendingResponses"
        style="margin-bottom: 15px"
      />
      <JobList
        v-if="!isAdmin"
        :jobs="jobs"
        :inquiries="inquiries"
        v-on:select-job="onSelectJob($event)"
      />

      <ModalJobCreate
        :job="selectedJob"
        :isShowing.sync="isCreateJobModalShowing"
      ></ModalJobCreate>
    </main>

    <TabBar
      :pendingJobs="pendingJobs"
      :pendingResponses="pendingResponses"
      :loading="loading"
      :syncing="syncing"
    />
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";

import NavBarJobs from "@/components/nav/NavBarJobs";
import ModalJobCreate from "@/components/modal/ModalJobCreate";
import TabBar from "@/components/TabBar";
import JobList from "@/components/JobList";
import JobListEmptyState from "@/components/JobListEmptyState";
import SyncButton from "@/components/SyncButton";

moment.locale("de");

export default {
  name: "JobsListView",
  components: {
    TabBar,
    ModalJobCreate,
    NavBarJobs,
    JobListEmptyState,
    JobList,
    SyncButton,
  },
  data() {
    return {
      admin: false,
      loading: false,
      error: null,
      selectedJob: null,
      isCreateJobModalShowing: false,
    };
  },
  created() {
    EventBus.$on("jobSelected", job => this.onSelectJob(job));
  },
  destroyed() {
    EventBus.$off("jobSelected");
  },
  computed: {
    ...mapGetters({
      jobs: "job/list",
      pendingJobs: "job/pending",
      inquiries: "inquiry/all",
      pendingResponses: "response/pending",
      activeUser: "user/current",
      isAdmin: "user/isAdmin",
      syncing: "sync/isSyncing",
    }),
  },
  methods: {
    handleSyncButton(event) {
      event.preventDefault();
      this.$store.dispatch("sync/upload");
    },
    async loadRemoteJobs() {
      if (this.loading === true || this.$store.syncing === true) {
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("sync/download")
        .then(() => this.$store.dispatch("sync/upload"))
        .catch(() => {
          Vue.toasted.show("Download von Aufträgen fehlgeschlagen", {
            theme: "primary",
            position: "bottom-left",
            duration: 2500,
            className: "toast-error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSelectJob(job) {
      if (job.editStatus === 0) {
        this.selectedJob = job;
        this.isCreateJobModalShowing = true;
      } else {
        this.showExistingJob(job);
      }
    },
    showExistingJob(job) {
      this.$router.push({
        path: `/job/${job.id.toString()}`,
      });
    },
  },
};
</script>

<style scoped>
.ui.top.attached.label + [class*="right floated"] + *,
.ui.top.attached.label:first-child + :not(.attached) {
  margin-top: 0 !important;
}

.content__status-sync img {
  width: 18px !important;
}
</style>
