/**
 * filters.js
 *
 * Filters can be used to apply common text formatting.
 *
 * @author Marcel Bender
 * @since 1.0.0
 */

/**
 * Returns a formated string from the incoming bytes value
 * @return {String} e.g. '3.12 MB'
 */
export function formatBytes(a) {
  if (a === 0) return "0 Bytes";
  const c = 1024;
  const e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const f = Math.floor(Math.log(a) / Math.log(c));
  return `${parseFloat((a / c ** f).toFixed(2))} ${e[f]}`;
}

/**
 * Returns the percentage of two input values
 * @param {*} current
 * @param {*} total
 * @return {String} Percentage value  without %
 */
export function formatPercentage(current, total) {
  const localCurrent = current || 0;
  const localTotal = total || 0;

  return `${parseFloat((localCurrent / localTotal) * 100).toFixed(2)}`;
}
