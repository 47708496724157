import { isOptionVisible } from "@/lib/FormUtil";
import store from "@/store/store";
import ResponseApiService from "../../services/ResponseApiService";
import * as types from "../mutation-types";

const md5 = require("md5");

export const EDIT_STATUS = Object.freeze({
  DRAFT: 0,
  COMPLETED: 1,
  SENT: 2,
});
export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

// Database Helpers
async function createNewResponseFromJob(rootState, job) {
  return Promise.resolve().then(async () => {
    console.log(
      "🕗[response.js, createNewResponseFromJob] Called with job",
      job
    );
    if (!job)
      throw new Error(
        "Creating response failed, no remote job or local job given"
      );

    const user = store.getters["user/current"];
    if (!user)
      throw new Error("Creating response failed, no current user available");

    // Check if job is from a remote job
    let isRemoteJob = false;
    if (job && job.job_id) {
      isRemoteJob = true;
    }

    // Get latest inquiry for given job
    const remoteInquiryId = isRemoteJob
      ? job.inquiry_id.toString()
      : job.inquiryId.toString();
    const inquiry = store.getters["inquiry/getLatestById"](remoteInquiryId);
    if (!inquiry) throw new Error("Creating job failed, inquiry not found");

    // Generate response from incoming job
    let newResponse = {};
    if (isRemoteJob) {
      newResponse = {
        id: job.id.toString(),
        userId: job.api_user_id.toString(),
        jobId: job.job_id.toString(),
        jobNumber: job.job_number.toString(),
        inquiryId: job.inquiry_id.toString(),
        inquiryVersion: inquiry.version,
        createdAt: job.created_at,
        updatedAt: job.updated_at,
        sentAt: job.sent_at,
        completedAt: job.completed_at,
        editStatus: job.edit_status,
        syncStatus: SYNC_STATUS.SYNCED,
        fields: job.fields,
      };
    } else {
      // Generate a unique ID for the new response
      const timestamp = new Date().getTime();
      const usernameHash = md5(timestamp);
      const uniqueId = `${usernameHash}${timestamp}`;

      newResponse = {
        id: uniqueId.toString(),
        userId: user.id.toString(),
        jobId: job.id.toString(),
        jobNumber: job.jobNumber.toString(),
        inquiryId: job.inquiryId.toString(),
        inquiryVersion: inquiry.version,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        sentAt: null,
        completedAt: null,
        editStatus: EDIT_STATUS.DRAFT,
        syncStatus: SYNC_STATUS.PENDING,
        fields: [],
      };
    }

    console.log(
      "✅[response.js, createNewResponseFromJob] Generated new job with success",
      job
    );
    return newResponse;
  });
}

async function findResponseInStateByProperty(state, { property, value }) {
  return state.all.find(response => response[`${property}`] == value);
}

async function handleApiResponse({ dispatch }, response) {
  return Promise.resolve()
    .then(() => {
      if (response.status != 200) throw new Error("Error loading responses");
      return response.data.data;
    })
    .then(data => {
      if (!data || data.length === 0) {
        return false;
      }
      return data;
    })
    .then(async data => {
      if (!data) {
        return null;
      }
      if (data && !data.length) {
        return await dispatch("insertOrUpdate", data);
      }
      return data.forEach(response => dispatch("insertOrUpdate", response));
    });
}

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  all: [],
};

/**
 * Getters
 */
export const getters = {
  all: state => state.all,
  pending: state =>
    state.all.filter(
      response => response && response.syncStatus < SYNC_STATUS.SYNCED
    ),
  getByJobId: state => id => {
    return state.all.find(response => response.jobId === id.toString());
  },
  getById: state => id => {
    return state.all.find(response => response.id === id.toString());
  },
};

/**
 * Actions
 */
export const actions = {
  async add({ commit, rootState }, job) {
    return Promise.resolve()
      .then(() => createNewResponseFromJob(rootState, job))
      .then(response => commit(types.ADD_RESPONSE, response))
      .catch(error => {
        console.error("Error while adding response", error.stack || error);
        throw error;
      });
  },

  async insertOrUpdate({ state, dispatch }, response) {
    let jobId;
    if (response.jobId) {
      jobId = response.jobId.toString();
    } else if (response.job_id) {
      jobId = response.job_id.toString();
    }
    return Promise.resolve()
      .then(() => state.all.find(item => item.jobId === jobId))
      .then(localResponse => {
        if (localResponse) {
          console.log("Response exists, aborting insert", localResponse);
          return null;
        }

        return dispatch("add", response);
      })
      .catch(error => {
        console.error(
          "Error while inserting or updating response",
          error.stack || error
        );
        throw error;
      });
  },

  async setFieldFileName({ commit, dispatch, state }, { id, field }) {
    console.log(
      `🕗[response.js, setFieldFileName] Called with id ${id} and field`,
      field
    );
    return Promise.resolve()
      .then(() =>
        findResponseInStateByProperty(state, {
          property: "id",
          value: id,
        })
      )
      .then(async response => {
        if (!response) {
          throw new Error("Response not found");
        }
        console.log(
          `🕗[response.js, setFieldFileName] Setting field at response`,
          response.jobNumber
        );
        await commit(types.SET_FIELD_FILENAME, {
          field,
          response,
        });
        return response;
      })
      .then(response =>
        dispatch(
          "job/setSyncStatus",
          {
            id: response.jobId,
            syncStatus: SYNC_STATUS.PENDING,
          },
          { root: true }
        )
      )
      .catch(error => {
        console.error(
          "Error while setting filename for response",
          error.stack || error
        );
        throw error;
      });
  },

  async resetFieldFileName({ commit, dispatch, state }, { id, field }) {
    return Promise.resolve()
      .then(() =>
        findResponseInStateByProperty(state, {
          property: "id",
          value: id,
        })
      )
      .then(response => {
        if (!response) {
          throw new Error("Response not found");
        }
        commit(types.RESET_FIELD_FILENAME, {
          field,
          response,
        });
        return response;
      })
      .then(response =>
        dispatch(
          "job/setSyncStatus",
          {
            id: response.jobId,
            syncStatus: SYNC_STATUS.PENDING,
          },
          { root: true }
        )
      )
      .catch(error => {
        console.error(
          "Error while resetting filename for response",
          error.stack || error
        );
        throw error;
      });
  },

  async setField({ commit, dispatch, state }, { id, field }) {
    console.log(
      `🕗[response.js, setField] Called with id ${id} and field`,
      field
    );
    return Promise.resolve()
      .then(() =>
        findResponseInStateByProperty(state, {
          property: "id",
          value: id,
        })
      )
      .then(async response => {
        if (!response) {
          throw new Error("Response not found");
        }
        console.log(
          `🕗[response.js, setField] Setting field at response`,
          response.jobNumber
        );
        await commit(types.SET_FIELD, {
          field,
          response,
        });
        return response;
      })
      .then(response =>
        dispatch(
          "job/setSyncStatus",
          {
            id: response.jobId,
            syncStatus: SYNC_STATUS.PENDING,
          },
          { root: true }
        )
      )
      .catch(error => {
        console.error(
          "Error while setting field for response",
          error.stack || error
        );
        throw error;
      });
  },

  async setEditStatus({ state, commit }, { id, editStatus }) {
    return Promise.resolve()
      .then(() =>
        findResponseInStateByProperty(state, {
          property: "id",
          value: id,
        })
      )
      .then(response => {
        if (!response) {
          throw new Error("Response not found");
        }
        // Update edit status and sync status for given response
        commit(types.UPDATE_RESPONSE, {
          id: response.id,
          payload: {
            editStatus,
            uploadedAt: new Date().toISOString(),
            syncStatus: SYNC_STATUS.PENDING,
          },
        });

        // If response is marked as completed, set the sent at timestamp
        if (editStatus === EDIT_STATUS.COMPLETED) {
          commit(types.UPDATE_RESPONSE, {
            id: response.id,
            payload: {
              sentAt: new Date().toISOString(),
              syncStatus: SYNC_STATUS.PENDING,
            },
          });
        }
      })
      .catch(error => {
        console.error(
          "Error while setting edit status for response",
          error.stack || error
        );
        throw error;
      });
  },

  async cleanCompletedResponse({ state, commit, dispatch }, { id }) {
    console.log("cleanCompletedResponse called with id", id);
    return Promise.resolve().then(async () => {
      // Get response from state
      const response = await findResponseInStateByProperty(state, {
        property: "id",
        value: id,
      });
      if (!response) {
        return Promise.reject();
      }
      // Get inquiry from state
      const inquiry = await dispatch(
        "inquiry/findByIdAndVersion",
        { id: response.inquiryId, version: response.inquiryVersion },
        { root: true }
      );
      if (!inquiry) {
        return Promise.reject();
      }
      // Remove field from response where visibleCondition from inquiry is not fulfilled
      let inquiryFieldIdsToRemove = [];
      inquiry.fields.map(field => {
        if (!isOptionVisible(field, response)) {
          inquiryFieldIdsToRemove.push(field.id.toString());
        }
      });
      if (!inquiryFieldIdsToRemove.length) {
        return Promise.reject();
      }

      // Remove fields from response
      let responseFieldIdsToRemove = [];
      response.fields.forEach(field => {
        if (inquiryFieldIdsToRemove.includes(field.id.toString())) {
          responseFieldIdsToRemove.push(field.id.toString());
        }
      });

      if (responseFieldIdsToRemove.length > 0) {
        commit(types.REMOVE_FIELDS, {
          fieldIds: responseFieldIdsToRemove,
          response,
        });
      }
    });
  },

  async updateImageUrlInResponse({ state, commit }, { image }) {
    return Promise.resolve().then(() =>
      findResponseInStateByProperty(state, {
        property: "id",
        value: image.responseId,
      }).then(response => {
        if (!response) {
          return Promise.reject();
        }

        const localField = response.fields.find(f => f.fileName === image.name);
        if (!localField) {
          // Skip error handling for user rrazoqi
          if (response.userId) {
            console.warn(
              "[ResponseUtil] 🔔 updateImageUrlInResponse · Skipped setting image url for response",
              response
            );
            return Promise.resolve();
          }
          return Promise.reject("Field with given ID not found");
        }

        commit(types.SET_FIELD, {
          field: {
            id: localField.id.toString(),
            attribute: "fileUrl",
            value: image.url,
          },
          response,
        });

        console.log(
          "[ResponseUtil] ✅ updateImageUrlInResponse with response",
          response
        );
      })
    );
  },

  async delete({ commit, state }, { id }) {
    return Promise.resolve().then(() => {
      const index = state.all.findIndex(element => element.id === id);
      if (index === -1)
        throw Error(`Response with id ${id} not found in store`);

      commit(types.DELETE_RESPONSE, index);
    });
  },

  fetchById(context, { id }) {
    let params = {};
    params.id = id;
    return ResponseApiService.downloadById(params).then(response =>
      handleApiResponse(context, response)
    );
  },

  async uploadById({ commit, state }, { id }) {
    console.log("[SyncUtil] ⌛️  uploadById", id);

    return Promise.resolve().then(() =>
      findResponseInStateByProperty(state, {
        property: "id",
        value: id,
      })
        .then(response => ResponseApiService.upload(response))
        .then(response => {
          if (!response || (response && response.status != 201)) {
            throw Error("Error while uploading response");
          }
          console.log("[SyncUtil] ⌛️  uploadById, API Response", response);
          return response;
        })
        .then(() => {
          findResponseInStateByProperty(state, {
            property: "id",
            value: id,
          }).then(response => {
            commit(types.UPDATE_RESPONSE, {
              id: response.id,
              payload: {
                editStatus:
                  response.editStatus === EDIT_STATUS.COMPLETED
                    ? EDIT_STATUS.SENT
                    : response.editStatus,
                uploadedAt: new Date().toISOString(),
                syncStatus: SYNC_STATUS.SYNCED,
              },
            });
          });
        })
        .catch(error => {
          findResponseInStateByProperty(state, {
            property: "id",
            value: id,
          }).then(response => {
            commit(types.UPDATE_RESPONSE, {
              id: response.id,
              payload: {
                syncStatus: SYNC_STATUS.PENDING,
              },
            });
          });
          console.error("Error posting response", error.stack || error);
          throw error;
        })
    );
  },

  /**
   * Sync
   */
  uploadPending({ getters, dispatch }) {
    return new Promise((resolve, reject) => {
      const responses = getters.pending;
      console.log("[SyncUtil] ⌛️  uploadResponses", responses);

      if (!responses || (responses && responses.length === 0)) {
        console.log("[SyncUtil] No pending responses found");
        resolve();
      } else {
        responses
          .reduce(
            (sequence, response) =>
              sequence.then(() => dispatch("upload", { response: response })),
            Promise.resolve()
          )
          .then(() => {
            console.log("[SyncUtil] Success in uploadResponses");
            resolve();
          })
          .catch(error => {
            console.error("[SyncUtil] 🛑  Error in uploadResponses", error);
            reject(error);
          });
      }
    });
  },
  upload({ commit, dispatch }, { response }) {
    return new Promise(async (resolve, reject) => {
      // Set sync status to pending for response
      console.log("[SyncUtil] ⌛️  uploadResponse", response);

      commit(types.UPDATE_RESPONSE, {
        id: response.id,
        payload: {
          syncStatus: SYNC_STATUS.SYNCING,
        },
      });

      dispatch(
        "image/uploadPendingImagesForResponse",
        { response: response },
        {
          root: true,
        }
      )
        .then(() =>
          dispatch("uploadById", {
            id: response.id,
          })
        )
        .then(response => resolve(response))
        .catch(async error => {
          commit(types.UPDATE_RESPONSE, {
            id: response.id,
            payload: {
              syncStatus: SYNC_STATUS.PENDING,
            },
          });
          return reject(error);
        });
    });
  },
  deleteCompleted({ state, dispatch }) {
    const responses = state.all.filter(
      r =>
        r.syncStatus === SYNC_STATUS.SYNCED && r.editStatus === EDIT_STATUS.SENT
    );
    console.log("[SyncUtil] ⌛️ deleteCompletedResponses", responses);

    if (!responses || responses.length === 0) {
      return Promise.resolve();
    }
    return Promise.all(
      responses.map(async response => {
        // Delete images for given response and the response itself
        if (!response || !response.id || response.id == 0) {
          console.warn("No valid response ID found, skipping deletion.");
          return Promise.resolve();
        }
        try {
          dispatch(
            "image/deleteByResponse",
            {
              responseId: response.id,
            },
            { root: true }
          ).then(() =>
            dispatch("delete", {
              id: response.id,
            }).then(() => Promise.resolve())
          );
        } catch (error) {
          return Promise.reject(error);
        }
      })
    );
  },

  reset({ commit }) {
    commit(types.RESET_RESPONSE);
  },
};

/**
 * Mutations
 */
function updateResponse(state, { id, payload }) {
  const index = state.all.findIndex(element => element.id === id);
  if (index === -1) throw Error(`Response with id ${id} not found in store`);

  let response = state.all.find(_job => _job.id === id);
  response = Object.assign(response, payload);

  state.all[index] = response;
}

export const mutations = {
  [types.ADD_RESPONSE](state, response) {
    state.all.push(response);
  },

  [types.UPDATE_RESPONSE](state, { id, payload }) {
    updateResponse(state, {
      id,
      payload,
    });
  },

  [types.SET_FIELD](state, { field, response }) {
    let localField = response.fields.find(f => f.id === field.id);

    // Create a new field if not already present
    if (!localField) {
      response.fields.push({
        id: field.id.toString(),
        choiceIds: [],
        text: null,
      });
    }

    response.fields = response.fields.filter(responseField => {
      if (responseField.id !== field.id) {
        return responseField;
      }

      const localField = responseField;

      if (field.attribute === "choiceIds") {
        // Set multiple choice value by adding choice to array
        // or removing choice if already present in array of IDs
        let choiceIds = localField[`${field.attribute}`];
        if (!choiceIds) {
          choiceIds = [];
        }

        // Check if a multiple or single choice should be set.
        // A single choice will overwrite the attribute value,
        // a multiple choice will append or remove the value.
        if (!field.multipleChoice) {
          choiceIds = [];
          choiceIds.push(field.value);
        } else if (field.multipleChoice && !choiceIds.includes(field.value)) {
          choiceIds.push(field.value);
        } else if (field.multipleChoice) {
          choiceIds = choiceIds.filter(item => item !== field.value);
        }
        localField[`${field.attribute}`] = choiceIds;
      } else if (field.attribute === "inputs") {
        localField[`${field.attribute}`] = field.inputs;
      } else {
        localField[`${field.attribute}`] = field.value;
      }
      return localField;
    });

    updateResponse(state, {
      id: response.id,
      payload: {
        updatedAt: new Date().toISOString(),
        syncStatus: SYNC_STATUS.PENDING,
        fields: response.fields,
      },
    });
  },

  [types.SET_FIELD_FILENAME](state, { field, response }) {
    const localField = response.fields.find(f => f.id === field.id);

    if (!localField && field.id && field.fileName) {
      // Create a new field with file parameters if not already present
      response.fields.push({
        id: field.id.toString(),
        fileName: field.fileName,
        fileUrl: null,
      });
    } else if (localField.id === field.id && field.fileName) {
      // If field is already present, update attribute to given value
      localField.fileName = field.fileName;

      // Update field in response
      response.fields = response.fields.filter(field => {
        if (field.id === localField.id) {
          return localField;
        }
        return field;
      });
    }

    updateResponse(state, {
      id: response.id,
      payload: {
        updatedAt: new Date().toISOString(),
        syncStatus: SYNC_STATUS.PENDING,
        fields: response.fields,
      },
    });
  },

  [types.REMOVE_FIELDS](state, { fieldIds, response }) {
    updateResponse(state, {
      id: response.id,
      payload: {
        updatedAt: new Date().toISOString(),
        fields: response.fields.filter(
          field => !fieldIds.includes(field.id.toString())
        ),
      },
    });
  },

  [types.RESET_FIELD_FILENAME](state, { field, response }) {
    // Find find in given response
    const localField = response.fields.find(f => f.id === field.id);

    if (!localField) {
      // Create a new field with empty file attributes if not already present
      response.fields.push({
        id: field.id.toString(),
        fileName: null,
        fileUrl: null,
      });
    } else if (localField) {
      // Update file properties for field in response
      response.fields = response.fields.filter(field => {
        if (field.id === localField.id) {
          field.fileName = null;
          field.fileUrl = null;
        }
        return field;
      });
    }

    updateResponse(state, {
      id: response.id,
      payload: {
        updatedAt: new Date().toISOString(),
        syncStatus: SYNC_STATUS.PENDING,
        fields: response.fields,
      },
    });
  },

  [types.DELETE_RESPONSE](state, index) {
    state.all.splice(index, 1);
  },

  [types.RESET_RESPONSE](state) {
    state.all = [];
  },
};
