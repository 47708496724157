<template>
  <modal black v-model="isDeleteSignatureModalShowing" modalVariation="basic">
    <div slot="header" class="center">
      <i class="trash icon"></i>
      Unterschrift löschen
    </div>
    <div class="content" slot="content">
      <p>Möchten Sie diese Unterschrift wirklich löschen?</p>
    </div>
    <template slot="actions">
      <div
        class="ui black deny inverted button"
        @click="cancelDeleteSignatureModal"
      >
        <i class="remove icon"></i> Nein
      </div>
      <div class="ui red right inverted button" @click="confirmModal">
        <i class="checkmark icon"></i> Ja
      </div>
    </template>
  </modal>
</template>

<script>
import { EventBus } from "@/event-bus";
import modal from "vue-semantic-modal";

export default {
  name: "ModalDeleteSignature",
  props: [
    "isDeleteSignatureModalShowing",
    "isDeleteSignatureModalConfirmed",
    "isUserAdmin",
  ],
  components: {
    modal,
  },
  data() {
    return {
      inquiry: null,
      field: null,
    };
  },
  created() {
    EventBus.$on("SHOW_DELETESIGNATURE_MODAL", context => {
      this.inquiry = context.inquiry;
      this.job = context.job;
      this.response = context.response;
      this.field = context.field;
      this.showDeleteSignatureModal();
    });
  },
  methods: {
    showDeleteSignatureModal() {
      this.$emit("update:isDeleteSignatureModalShowing", true);
      this.$emit("update:isDeleteSignatureModalConfirmed", false);
    },
    cancelDeleteSignatureModal() {
      this.$emit("update:isDeleteSignatureModalShowing", false);
      this.$emit("update:isDeleteSignatureModalConfirmed", true);
    },
    confirmModal() {
      this.cancelDeleteSignatureModal();
      this.$emit("update:isDeleteSignatureModalConfirmed", false);
      this.deleteImage();
    },
    deleteImage() {
      // Delete photo from data object
      const localField = this.response.fields.find(
        f => f.id === this.field.id.toString()
      );
      if (!localField) {
        throw Error("Cannot find field for photo");
      }

      if (this.isUserAdmin) {
        this.$store.dispatch("response/resetFieldFileName", {
          id: this.response.id,
          field: {
            id: localField.id,
          },
        });
      } else {
        this.$store
          .dispatch("image/delete", {
            fileName: localField.fileName,
          })
          .then(() =>
            this.$store.dispatch("response/resetFieldFileName", {
              id: this.response.id,
              field: {
                id: localField.id,
              },
            })
          );
      }
    },
  },
};
</script>
