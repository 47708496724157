import store from "@/store/store";
import HomeScreenManual from "@/views/HomeScreenManual";
import JobEditView from "@/views/JobEditView";
import JobsListView from "@/views/JobsListView";
import LoginView from "@/views/LoginView";
import SettingsDiagnosisView from "@/views/SettingsDiagnosisView";
import SettingsView from "@/views/SettingsView";
import SyncView from "@/views/SyncView";
import WelcomeView from "@/views/WelcomeView";
import LogRocket from "logrocket";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/**
 * Forwards the user to the inquiries page
 * if the user is already logged in.
 * @param {*} next
 */
async function forwardToJobs(to, from, next) {
  const user = store.getters["user/current"];
  if (user && user.auth) {
    next({
      path: "/jobs",
      query: {
        continue: to.path,
      },
    });
  } else {
    next();
  }
}

const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: WelcomeView,
      meta: {
        requiresAuth: false,
      },
      beforeEnter: (to, from, next) => forwardToJobs(to, from, next),
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: {
        requiresAuth: false,
      },
      beforeEnter: (to, from, next) => forwardToJobs(to, from, next),
    },
    {
      path: "/manuals/home-screen",
      name: "homeScreenManual",
      component: HomeScreenManual,
    },
    {
      path: "/jobs",
      name: "jobs",
      component: JobsListView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/job/:id",
      name: "jobEdit",
      component: JobEditView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/job/edit/:id",
      name: "jobEditAdmin",
      component: JobEditView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/sync",
      name: "sync",
      component: SyncView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: SettingsView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/settings/diagnosis",
      name: "diagnosis",
      component: SettingsDiagnosisView,
      meta: {
        requiresAuth: true,
      },
    },
  ],
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  // Hold the request, until the Storage is available
  // eslint-disable-next-line
  if (store._vm.$root.$data["vue-persist-patch-delay"]) {
    // eslint-disable-next-line
    store._vm.$root.$on("storageReady", () => {
      next({
        path: to.path,
        query: to.query,
        params: to.params,
      });
    });
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    // This route requires auth, check if the user is
    // logged in. If not, redirect to login page.
    const user = store.getters["user/current"];
    if (!user || (user && !user.auth)) {
      next({
        path: "/login",
      });
    } else {
      LogRocket.identify(user.id);
      next();
    }
  } else {
    next();
  }
});

export default router;
