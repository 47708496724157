import moment from "moment";
import groupBy from "lodash/groupBy";
import store from "@/store/store";
import JobApiService from "@/services/JobApiService";
import * as types from "../mutation-types";
moment.locale("de");

const _ = require("lodash");
const orderBy = require("lodash/orderBy");

export const EDIT_STATUS = Object.freeze({
  ASSIGNED: 0,
  EDITING: 1,
  COMPLETED: 2,
  SENT: 3,
});
export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

// Database Helpers
async function createNewJob(remoteJob) {
  return Promise.resolve().then(async () => {
    // console.log("[job.js] createNewJob called with job id", remoteJob.id);

    // Get current user
    const user = store.getters["user/current"];
    if (!user) throw new Error("Creating job failed, user not found");

    // Get latest inquiry for given job
    const inquiry = store.getters["inquiry/getLatestById"](
      remoteJob.inquiry_id.toString()
    );
    if (!inquiry) throw new Error("Creating job failed, inquiry not found");

    return {
      id: remoteJob.id.toString(),
      userId: remoteJob.api_user_id.toString(),
      inquiryId: remoteJob.inquiry_id.toString(),
      inquiryVersion: inquiry.version.toString(),
      jobNumber: remoteJob.job_number.toString(),
      createdAt: remoteJob.created_at || moment().format(remoteJob.created_at),
      updatedAt: remoteJob.updated_at || moment().format(remoteJob.updated_at),
      dueAt: remoteJob.due_at || moment().format(remoteJob.due_at),
      dueAtFormatted: moment(
        remoteJob.due_at || moment().format(remoteJob.due_at)
      ).format("YYYY-MM-DD"),
      customer: {
        name: remoteJob.customer_name,
        phone: remoteJob.customer_phone,
        mobile: remoteJob.customer_mobile,
        address: {
          street: remoteJob.customer_address,
          zip: remoteJob.customer_zip,
          city: remoteJob.customer_city,
        },
      },
      info: remoteJob.appointment_info,
      editStatus: remoteJob.status,
      syncStatus: SYNC_STATUS.SYNCED,
    };
  });
}

function getOpenJobs(jobs) {
  // Filter out jobs which are overdue and aren't editable
  let cleanedJobs = jobs.filter(job => {
    const localJob = job;

    // Check if dueAt is later than today and make sure to display editable
    // jobs which are not synced yet
    const diff = moment(job.dueAt).diff(moment(), "days");
    if (
      (diff < 0 && job.editStatus === 0) ||
      (job.editStatus > 2 && job.syncStatus >= 2)
    ) {
      return false;
    }

    // Format date for section headers (if data field is not yet available)
    if (!localJob.dueAtFormatted) {
      localJob.dueAtFormatted = moment(job.dueAt).format("YYYY-MM-DD");
    }
    return localJob;
  });

  return _.chain(cleanedJobs)
    .groupBy(cleanedJob => cleanedJob.dueAtFormatted)
    .map((jobs, dueAtFormatted) => ({
      jobs: _.orderBy(jobs, ["dueAt"], ["asc"]),
      dueAtFormatted,
    }))
    .orderBy(group => group.dueAtFormatted, ["asc"])
    .value();
}

async function findJobInStateById(state, { id }) {
  return Promise.resolve().then(async () => {
    const localJob = await state.all.find(job => job.id === id.toString());
    if (!localJob) {
      throw new Error(`Job with id ${id} not found`);
    }
    return localJob;
  });
}

async function handleApiResponse({ dispatch }, response) {
  return Promise.resolve()
    .then(() => {
      if (response.status != 200) throw new Error("Error loading jobs");
      return response.data.data;
    })
    .then(data => {
      if (!data || data.length === 0) {
        return false;
      }
      return data;
    })
    .then(async data => {
      if (!data) {
        return null;
      }
      if (data && !data.length) {
        return await dispatch("insertOrUpdate", data);
      }
      return data.forEach(job => dispatch("insertOrUpdate", job));
    });
}

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  all: [],
};

/**
 * Getters
 */
export const getters = {
  all: state => {
    return orderBy(state.all, ["dueAt"], ["asc"]);
  },
  list: state => getOpenJobs(state.all),
  pending: state =>
    state.all.filter(job => job.syncStatus < SYNC_STATUS.SYNCED),
  drafts: state =>
    state.all.filter(
      job =>
        job.editStatus > EDIT_STATUS.ASSIGNED &&
        job.editStatus < EDIT_STATUS.SENT
    ),
  getById: state => id =>
    state.all.find(job => job.id.toString() === id.toString()),
};

/**
 * Actions
 */
export const actions = {
  async addJob({ commit }, inquiryId) {
    return Promise.resolve()
      .then(() => createNewJob(inquiryId))
      .then(newJob => commit(types.ADD_JOB, newJob))
      .catch(error => {
        console.error("Error while adding job", error.stack || error);
        throw error;
      });
  },

  async insertOrUpdate({ state, dispatch, commit }, job) {
    return Promise.resolve().then(async () => {
      {
        // Skip already edited or completed jobs
        const isAdmin = store.getters["user/isAdmin"];
        if (job.status > 0 && !isAdmin) {
          return true;
        }

        // Check if job is already existing in vuex
        const localJob = state.all.find(
          _job => _job.id.toString() == job.id.toString()
        );

        // Insert as new job if not available
        // Replace existing job if user is not already editing it
        if (!localJob) {
          return dispatch("addJob", job);
        } else if (
          localJob &&
          localJob.editStatus === EDIT_STATUS.ASSIGNED &&
          job &&
          job.status === EDIT_STATUS.ASSIGNED
        ) {
          const replacementJob = await createNewJob(job);
          commit(types.UPDATE_JOB, {
            id: job.id.toString(),
            payload: replacementJob,
          });
        }

        return job;
      }
    });
  },

  async setEditStatus({ state, commit }, { id, editStatus }) {
    return Promise.resolve()
      .then(() => findJobInStateById(state, { id }))
      .then(job => {
        // Update edit status and sync status for given response
        commit(types.UPDATE_JOB, {
          id: job.id,
          payload: {
            editStatus,
            uploadedAt: new Date().toISOString(),
            syncStatus: SYNC_STATUS.PENDING,
          },
        });

        // If job is marked as completed, set the sent at timestamp
        if (editStatus === EDIT_STATUS.COMPLETED) {
          commit(types.UPDATE_JOB, {
            id: job.id,
            payload: {
              sentAt: new Date().toISOString(),
            },
          });
        }
      })
      .catch(error => {
        console.error(
          "Error while setting edit status for job",
          error.stack || error
        );
        throw error;
      });
  },

  async setSyncStatus({ state, commit }, { id, syncStatus }) {
    return Promise.resolve()
      .then(() => findJobInStateById(state, { id }))
      .then(job => {
        // Update edit status and sync status for given response
        commit(types.UPDATE_JOB, {
          id: job.id,
          payload: {
            syncStatus: syncStatus,
          },
        });
      })
      .catch(error => {
        console.error(
          "Error while setting sync status for job",
          error.stack || error
        );
        throw error;
      });
  },

  async deletePruned({ state, dispatch }, { remoteJobs }) {
    return Promise.resolve().then(() => {
      state.all.forEach(async dbJob => {
        const localJob = remoteJobs.find(job => job.id.toString() === dbJob.id);
        if (!localJob && dbJob.editStatus === EDIT_STATUS.ASSIGNED) {
          await dispatch("delete", {
            jobId: dbJob.id,
          });
        }
      });
    });
  },

  async delete({ state, commit }, { jobId }) {
    console.log("[job, delete] Delete called with jobId", jobId);
    return Promise.resolve().then(() => {
      const index = state.all.findIndex(element => element.id === jobId);
      if (index === -1) throw Error(`Job with id ${jobId} not found in store`);

      commit(types.DELETE_JOB, index);
    });
  },

  fetch(context) {
    return JobApiService.download().then(async response => {
      await handleApiResponse(context, response);
      if (response && response.data && response.data.data) {
        await context.dispatch("deletePruned", {
          remoteJobs: response.data.data,
        });
      }
    });
  },

  fetchById(context, { id }) {
    let params = {};
    params.id = id;
    return JobApiService.downloadById(params).then(response =>
      handleApiResponse(context, response)
    );
  },

  /**
   * Sync
   */
  async postById({ state, commit }, { id }) {
    console.log("[SyncUtil] ⌛️  postById", id);

    return Promise.resolve().then(() =>
      findJobInStateById(state, { id })
        .then(job => {
          commit(types.UPDATE_JOB, {
            id: id,
            payload: {
              syncStatus: SYNC_STATUS.SYNCING,
            },
          });
          return job;
        })
        .then(job => JobApiService.upload(job))
        .then(response => {
          if (!response || (response && response.status != 201)) {
            throw Error("Error while posting response");
          }
          console.log("[SyncUtil] ⌛️  postById, API Response", response);
          return response;
        })
        .then(() => {
          findJobInStateById(state, { id }).then(job => {
            commit(types.UPDATE_JOB, {
              id: job.id,
              payload: {
                editStatus:
                  job.editStatus === EDIT_STATUS.COMPLETED
                    ? EDIT_STATUS.SENT
                    : job.editStatus,
                uploadedAt: new Date().toISOString(),
                syncStatus: SYNC_STATUS.SYNCED,
              },
            });
          });
        })
        .catch(error => {
          findJobInStateById(state, { id }).then(response => {
            commit(types.UPDATE_JOB, {
              id: response.id,
              payload: {
                syncStatus: SYNC_STATUS.PENDING,
              },
            });
          });
          console.error("Error posting job", error.stack || error);
          throw error;
        })
    );
  },
  uploadPending({ getters, dispatch }) {
    return new Promise((resolve, reject) => {
      const jobs = getters.pending;
      console.log("[SyncUtil] ⌛️  uploadPendingJobs", jobs);

      if (!jobs || (jobs && jobs.length === 0)) {
        resolve();
      } else {
        jobs
          .reduce(
            (sequence, job) =>
              sequence.then(() => dispatch("postById", { id: job.id })),
            Promise.resolve()
          )
          .then(() => {
            console.log("[SyncUtil] Success in uploadPendingJobs");
            resolve();
          })
          .catch(error => {
            console.error("[SyncUtil] 🛑  Error in uploadPendingJobs", error);
            reject(error);
          });
      }
    });
  },
  deleteCompleted({ state, dispatch }) {
    const jobs = state.all.filter(
      job =>
        job.syncStatus === SYNC_STATUS.SYNCED &&
        job.editStatus === EDIT_STATUS.SENT
    );
    console.log("[SyncUtil] ⌛️  deleteCompletedJobs", jobs);

    if (!jobs || (jobs && jobs.length === 0)) {
      console.log("[SyncUtil] No completed jobs found");
      return Promise.resolve();
    }

    return Promise.all(
      jobs.map(async job => {
        try {
          return dispatch("delete", {
            jobId: job.id,
          }).then(() => Promise.resolve());
        } catch (error) {
          return Promise.reject(error);
        }
      })
    );
  },

  reset({ commit }) {
    commit(types.RESET_JOB);
  },
};

/**
 * Mutations
 */
export const mutations = {
  [types.ADD_JOB](state, job) {
    state.all.push(job);
  },

  [types.UPDATE_JOB](state, { id, payload }) {
    const index = state.all.findIndex(element => element.id === id);
    if (index === -1) throw Error(`Job with id ${id} not found in store`);

    let job = state.all.find(_job => _job.id === id);
    job = Object.assign(job, payload);

    state.all[index] = job;
  },

  [types.DELETE_JOB](state, index) {
    state.all.splice(index, 1);
  },

  [types.RESET_JOB](state) {
    state.all = [];
  },
};
