<template>
  <div
    class="ui grouped fields form__block"
    v-bind:class="{
      disabled: response.editStatus > 0 && !userIsAdmin,
      required: isFieldRequired(field),
    }"
  >
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">{{ field.sectionDescription }}</p>
    <label v-if="field.headingTitle">{{ field.headingTitle }}</label>
    <p v-if="field.headingDescription">{{ field.headingDescription }}</p>

    <div class="radio__group" v-if="field.choices">
      <template v-for="choice in field.choices">
        <div
          class="field"
          v-bind="choice"
          v-bind:key="choice.id"
          :required="isFieldRequired(field)"
        >
          <div
            class="ui radio checkbox"
            @click="setCheckbox(field, choice, $event)"
          >
            <input
              type="radio"
              :name="field.id"
              tabindex="0"
              class="hidden"
              :checked="isCheckboxChecked(field, choice)"
              :required="isFieldRequired(field)"
            />
            <label :id="choice.id">{{ choice.text }}</label>
          </div>
        </div>
      </template>
    </div>

    <div
      class="ui two column grid container"
      style="margin-left: 0 !important; margin-bottom: 10px"
    >
      <div
        class="ui column field"
        style="padding-left: 0 !important"
        v-if="field.headingImage && previewImageSrc"
      >
        <label class="label-small" style="margin-top: -5px; padding-top: 10px"
          >Beispiel-Foto</label
        >
        <div class="ui segment">
          <img
            :name="field.id"
            class="ui small rounded bordered image loading"
            :src="previewImageSrc"
            :required="isFieldRequired(field)"
            :style="{ cursor: isPhotoAvailable ? 'pointer' : '' }"
            @click="openImagePreview(previewImageSrc)"
            style="margin-top: -5px"
          />
        </div>
      </div>
      <div
        class="ui column field"
        v-bind:class="{ required: isFieldRequired(field) }"
        style="padding-left: 0 !important; padding-bottom: 1rem"
      >
        <label class="label-small" style="margin-top: -5px; padding-top: 10px"
          >Foto-Vorschau</label
        >

        <div class="ui segment">
          <div :name="field.id" class="ui dimmer inverted">
            <div class="ui indeterminate text loader">Lade …</div>
          </div>
          <div class="file-upload__file-container"></div>
          <img
            :name="field.id"
            class="ui small rounded bordered image loading signature__image-preview"
            :src="imageSrc"
            :required="isFieldRequired(field)"
            :style="{ cursor: isPhotoAvailable ? 'pointer' : '' }"
            @click="openImagePreview(imageSrc)"
            style="margin-top: -5px"
          />
        </div>
      </div>
      <div class="ui message error" v-if="imageError" style="display: block">
        <div class="header">Fehler bei Foto-Verarbeitung</div>
        <p>
          Das Foto konnte nicht erfolgreich gespeichert werden. Bitte probieren
          Sie es erneut! Sollte der Fehler dauerhaft auftreten, kontaktieren Sie
          bitte den Support.
        </p>
      </div>
    </div>
    <div
      class="ui field bottom aligned photo-preview__buttons"
      v-if="
        (!isPhotoAvailable && response.editStatus === 0) ||
        (!isPhotoAvailable && userIsAdmin)
      "
    >
      <input
        :name="field.id"
        type="file"
        class="ui file-upload__file"
        accept="image/*"
        @change="processImage(field)"
        :required="isFileRequired(field)"
        capture
      />
      <div
        :name="field.id"
        class="ui primary button bottom fluid photo-preview btn-photo-capture wide"
      >
        <i class="paint brush icon"></i>
        Foto aufnehmen
      </div>
    </div>
    <div
      class="ui field bottom aligned aligned photo-preview__buttons"
      v-if="
        (isPhotoAvailable && response.editStatus === 0) ||
        (isPhotoAvailable && userIsAdmin)
      "
    >
      <div
        class="ui red button fluid btn-photo-remove"
        @click="showDeletePhotoModal(field)"
      >
        <i class="trash icon"></i>
        Foto löschen
      </div>
    </div>

    <div
      class="ui field"
      :class="{
        disabled: response.editStatus > 0 && !userIsAdmin,
        required: isDescriptionRequired(field),
      }"
      :required="isDescriptionRequired(field)"
      v-if="field.descriptionVisible"
    >
      <label>Bemerkung</label>
      <textarea
        class="multi"
        type="text"
        name="description"
        placeholder="Bemerkung hinzufügen…"
        spellcheck="true"
        autocorrect="on"
        rows="3"
        :class="{
          disabled: response.editStatus > 0 && !userIsAdmin,
          required: isDescriptionRequired(field),
        }"
        :value="descriptionValue"
        :required="isDescriptionRequired(field)"
        @blur="setDescriptionText(field, $event)"
      ></textarea>
    </div>
  </div>
</template>

<script>
import loadImage from "blueimp-load-image";
import { EventBus } from "@/event-bus";
import {
  isFieldRequired,
  isDescriptionRequired,
  isCheckboxChecked,
  setCheckbox,
  setDescriptionText,
} from "@/lib/FormUtil";

const placeholderImage = require("@/assets/white-image.png");

export default {
  name: "FormFileCaptureImage",
  props: [
    "field",
    "response",
    "job",
    "isDeletePhotoModalShowing",
    "isDeletePhotoModalConfirmed",
    "userIsAdmin",
  ],
  data() {
    return {
      isPreviewImageUpdatedNeeded: true,
      imageError: null,
    };
  },
  asyncComputed: {
    /* eslint-disable */
    previewImageSrc: {
      get() {
        if (this.field) {
          return this.loadPreviewImage(this.field);
        }
        return placeholderImage;
      },
      watch() {
        this.response;
      },
    },
    imageSrc: {
      get() {
        return this.loadImage(this.field);
      },
      watch() {
        this.response;
      },
    },
    descriptionValue: {
      get() {
        const field = this.response.fields.find(f => f.id === this.field.id);
        if (!field) {
          return null;
        }

        return field.text;
      },
      watch() {
        this.response;
      },
    },
    isPhotoAvailable: {
      get() {
        const localField = this.response.fields.find(
          f => f.id === this.field.id
        );
        if (!localField || !localField.fileName) {
          return false;
        }
        return true;
      },
      watch() {
        this.response;
      },
    },
    /* eslint-enable */
  },
  methods: {
    showDeletePhotoModal(field) {
      EventBus.$emit("SHOW_DELETEPHOTO_MODAL", field);
    },
    isCheckboxChecked(field, choice) {
      return isCheckboxChecked(field, this.response, choice);
    },
    isFieldRequired(field) {
      return isFieldRequired(field);
    },
    isFileRequired(field) {
      return (
        (field.required && !field.fileName) ||
        (!field.required && !field.fileName && field.description)
      );
    },
    isDescriptionRequired(field) {
      return isDescriptionRequired(field, this.response);
    },
    setCheckbox(field, choice, event) {
      this.currentChoice = choice;
      setCheckbox(field.id, this.response, event.target.id);
    },
    setDescriptionText(field, event) {
      setDescriptionText(field.id, this.response, event.target.value);
    },
    /* eslint-disable consistent-return */
    loadPreviewImage(field) {
      if (!field.headingImage) {
        return placeholderImage;
      }

      // Show image from remote if available
      if (field.headingImage.imageUrl) {
        return field.headingImage.imageUrl;
      }

      // Show remote image if available and user online
      if (field.headingImage.mime && field.headingImage.data) {
        return (
          "data:" +
          field.headingImage.mime +
          ";base64," +
          field.headingImage.data
        );
      }

      return placeholderImage;
    },
    loadImage(field) {
      const localField = this.response.fields.find(f => f.id === field.id);
      if (!localField) {
        return placeholderImage;
      }

      // Show local image if available
      const localImage = this.$store.getters["image/getByFileName"](
        localField.fileName
      );
      if (localImage) {
        return localImage.src;
      }

      // Show remote image if available and user online
      if (localField && localField.fileUrl && window.navigator.onLine) {
        return localField.fileUrl;
      }

      return placeholderImage;
    },
    toggleImageLoadingSpinner(isLoading) {
      return Promise.resolve().then(() => {
        const loadingSpinner = document.querySelector(
          `.inverted[name='${this.field.id}']`
        );
        if (isLoading) {
          loadingSpinner.classList = "ui dimmer inverted active";
        } else {
          loadingSpinner.classList = "ui dimmer inverted";
        }
        return true;
      });
    },
    generateResizedImage(file) {
      console.log("generateResizedImage with file", file);
      return new Promise(resolve =>
        loadImage(
          file,
          img => {
            if (img.type === "error") {
              console.error(`Error loading image`, file);
              throw new Error("Error generating resized image");
            } else {
              return resolve(img);
            }
          },
          {
            maxWidth: 1000,
            maxHeight: 1000,
            orientation: true,
            canvas: true,
          }
        )
      );
    },
    convertImageToDataUrl(file) {
      return Promise.resolve()
        .then(() => {
          try {
            let dataUrl;

            // Shrink size of file on Android
            const isIosDevice = /(iPad|iPhone|iPod)/i.test(navigator.userAgent);
            if (isIosDevice) {
              try {
                dataUrl = file.toDataURL("image/jpeg", 0.7);
              } catch (error) {
                dataUrl = file.toDataURL(file.type);
                console.error(
                  "Error compressing image on iOS, using default size"
                );
              }
            } else {
              dataUrl = file.toDataURL("image/jpeg", 0.7);
            }
            if (!dataUrl) {
              throw Error(
                "Transforming image failed: No image source available"
              );
            }
            return dataUrl;
          } catch (error) {
            console.error(error.stack || error);
            throw error;
          }
        })
        .catch(error => {
          console.error(error.stack || error);
          throw error;
        });
    },
    async saveImage(imageBase64) {
      const fileName = await this.$store.dispatch("image/generateId", {
        contractId: this.job.jobNumber,
      });

      return this.$store
        .dispatch("image/add", {
          image: imageBase64,
          responseId: this.response.id,
          fileName,
        })
        .then(() =>
          this.$store.dispatch("response/setFieldFileName", {
            id: this.response.id,
            field: {
              id: this.field.id,
              fileName,
            },
          })
        );
    },
    showErrorMessage(error) {
      this.toggleImageLoadingSpinner(this.field, false);
      this.imageError = error;
    },
    processImage(field) {
      // Generate unique filename for given image
      const imageFile = document.querySelector(`input[name='${field.id}']`)
        .files[0];
      if (!imageFile) {
        return this.showErrorMessage("Bild konnte nicht gefunden werden");
      }

      // Load and scale given image
      this.toggleImageLoadingSpinner(true)
        .then(() => this.generateResizedImage(imageFile))
        .then(resizedImage => this.convertImageToDataUrl(resizedImage))
        .then(imageBase64 => this.saveImage(imageBase64))
        .catch(error => {
          console.error(error.stack || error);
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.toggleImageLoadingSpinner(false);
        });
    },
    openImagePreview(imageSrc) {
      if (!this.isPhotoAvailable && !this.field.headingImage) {
        return;
      }
      EventBus.$emit("SHOW_VIEWPHOTO_MODAL", imageSrc);
    },
  },
};
</script>

<style scoped>
.btn-photo-remove {
  margin-top: 16px !important;
}

input[type="file"] {
  position: absolute;
  overflow: hidden;
  opacity: 0.01;
  background-color: red;
  max-width: 100%;
  max-height: 36px;
}

.photo-preview__buttons {
  padding-bottom: 10px !important;
}

.ui.segment {
  padding: 0;
  box-shadow: none;
  border: none;
}

.ui.segment > .ui.dimmer {
  z-index: 1;
}
</style>
