<template>
  <div class="ui grouped fields">
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">
      {{ field.sectionDescription }}
    </p>
    <label v-if="field.headingTitle">
      {{ field.headingTitle }}
    </label>
    <span
      class="ui field span_pre"
      v-if="field.headingDescription"
      v-html="field.headingDescription"
    >
    </span>
    <div v-if="field.headingImage" class="ui segment">
      <img
        :name="field.id"
        class="ui small rounded bordered image loading"
        :src="previewImageSrc"
        @click="openImagePreview(previewImageSrc)"
        style="cursor: pointer;margin-top: -5px"
      />
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

const placeholderImage = require("@/assets/white-image.png");

export default {
  name: "FormPresentationDescriptiveText",
  props: ["field"],
  asyncComputed: {
    /* eslint-disable */
    previewImageSrc: {
      get() {
        if (this.field) {
          return this.loadPreviewImage(this.field);
        }
        return placeholderImage;
      },
      watch() {
        this.response;
      },
    },
  },
  methods: {
    loadPreviewImage(field) {
      if (!field.headingImage) {
        return placeholderImage;
      }

      // Show image from remote if available
      if (field.headingImage.imageUrl) {
        return field.headingImage.imageUrl;
      }

      // Show remote image if available and user online
      if (field.headingImage.mime && field.headingImage.data) {
        return (
          "data:" +
          field.headingImage.mime +
          ";base64," +
          field.headingImage.data
        );
      }

      return placeholderImage;
    },
    openImagePreview(imageSrc) {
      if (!this.field.headingImage) {
        return;
      }
      EventBus.$emit("SHOW_VIEWPHOTO_MODAL", imageSrc);
    },
  }
};
</script>
