var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui grouped fields",class:{
  disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
  required: _vm.isFieldRequired(_vm.field),
}},[(_vm.field.sectionTitle)?_c('h4',{staticClass:"ui dividing header"},[_vm._v(" "+_vm._s(_vm.field.sectionTitle)+" ")]):_vm._e(),(_vm.field.sectionDescription)?_c('p',[_vm._v(" "+_vm._s(_vm.field.sectionDescription)+" ")]):_vm._e(),_c('div',{staticClass:"ui field grouped fields",class:{
    disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
    required: _vm.isFieldRequired(_vm.field),
  },attrs:{"required":_vm.isFieldRequired(_vm.field)}},[(_vm.field.headingTitle)?_c('label',[_vm._v(" "+_vm._s(_vm.field.headingTitle)+" ")]):_vm._e(),(_vm.field.headingDescription)?_c('p',[_vm._v(" "+_vm._s(_vm.field.headingDescription)+" ")]):_vm._e(),_c('div',{staticClass:"ui form"},[_vm._l((_vm.field.formula.fields),function(subfield){return _c('div',{key:subfield.fieldId,staticClass:"field",class:{
        disabled: (_vm.response.editStatus > 0 && !_vm.userIsAdmin) || _vm.field.readonly,
        required: _vm.isFieldRequired(_vm.field),
      },attrs:{"required":_vm.isFieldRequired(_vm.field)}},[(subfield.label)?_c('label',[_vm._v(" "+_vm._s(subfield.label)+" ")]):_vm._e(),(_vm.field.minValue || _vm.field.maxValue)?_c('input',{attrs:{"type":"number","min":_vm.field.minValue,"max":_vm.field.maxValue,"name":"description","placeholder":"Numerischen Wert eintragen…","required":_vm.isFieldRequired(_vm.field)},domProps:{"value":_vm.subfieldInputValue(subfield)},on:{"blur":function($event){return _vm.setCalculatedSubfieldText(subfield, $event)}}}):_c('input',{attrs:{"type":"text","pattern":_vm.setRegExPattern(_vm.field),"name":"description","placeholder":"Numerischen Wert eintragen…","required":_vm.isFieldRequired(_vm.field)},domProps:{"value":_vm.subfieldInputValue(subfield)},on:{"blur":function($event){return _vm.setCalculatedSubfieldText(subfield, $event)}}})])}),_c('div',{staticClass:"field"},[_c('label',[_vm._v("= Berechnetes Ergebnis")]),_c('input',{staticStyle:{"opacity":".6"},attrs:{"type":"text","readonly":"","disabled":""},domProps:{"value":_vm.descriptionValue}})])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }