<template>
  <div class="ui container">
    <button
      class="fluid ui left labeled icon blue big button"
      v-bind:class="{ disabled: syncing, loading: syncing }"
      style="padding-left: 3.5em !important; margin-bottom: 1em"
      v-if="!syncing"
      @click="handleSyncButton($event)"
    >
      <i class="left cloud upload icon"></i>{{ pendingJobs.length }}
      {{ pendingJobs.length == 1 ? "Auftrag" : "Aufträge" }} übertragen
    </button>
    <div
      v-if="!loading && syncing"
      class="ui segment"
      style="margin: 0; padding: 0"
    >
      <div class="ui icon small message blue">
        <i class="notched circle loading icon"></i>
        <div class="content">
          <div class="header">Übertragung läuft …</div>
          <p>Die Warteschlange wird übertragen.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SyncButton",
  props: {
    pendingJobs: {
      required: true,
      type: Array,
    },
    pendingResponses: {
      required: true,
      type: Array,
    },
    syncing: {
      required: true,
      type: Boolean,
    },
    loading: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    handleSyncButton(event) {
      event.preventDefault();
      this.$store.dispatch("sync/upload");
    },
  },
};
</script>
