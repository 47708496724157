<template>
  <modal black v-model="isShowing" modalVariation="basic">
    <div slot="header" class="center">
      <i class="checkmark sign icon"></i>
      Auftrag {{ job ? job.jobNumber : "" }} starten
    </div>
    <div class="content" slot="content">
      <p>
        <strong>
          Möchten Sie die Bearbeitung vom
          <br />
          Auftrag {{ job ? job.jobNumber : "" }} wirklich starten?
        </strong>
      </p>
      <p>
        Der Auftrag wird nach Ihrer Bestätigung für spätere Änderungen durch den
        Disponenten gesperrt.
      </p>
    </div>
    <template slot="actions">
      <div class="ui black deny inverted button" @click="cancel">
        <i class="remove icon"></i> Nein
      </div>
      <div class="ui green right inverted button" @click="confirm">
        <i class="checkmark icon"></i> Ja
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "vue-semantic-modal";

import { EDIT_STATUS } from "../JobForm.vue";

export default {
  name: "ModalJobCreate",
  props: ["job", "isShowing"],
  components: {
    modal,
  },
  methods: {
    cancel() {
      this.$emit("update:isShowing", false);
    },
    async confirm() {
      this.cancel();

      this.$store
        .dispatch("response/add", this.job)
        .then(() =>
          this.$store.dispatch("job/setEditStatus", {
            id: this.job.id,
            editStatus: EDIT_STATUS.EDITING,
          })
        )
        .then(() =>
          this.$router.push({
            name: "jobEdit",
            params: { id: this.job.id.toString() },
          })
        )
        .then(() => {
          this.$emit("update:isShowing", false);
          this.$store.dispatch("sync/upload");
        });
    },
  },
};
</script>
