import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import Toasted from "vue-toasted";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/store";
import * as filters from "./util/filters";

const moment = require("moment");
require("moment/locale/de");

Vue.config.productionTip = false;

// Register global modules
Vue.use(Toasted);
Vue.use(AsyncComputed);
Vue.use(require("vue-moment"), {
  moment,
});
Vue.prototype.$navigator = window.navigator;

// Register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

// Register LogRocket and Sentry
if (process.env.NODE_ENV === "production") {
  LogRocket.init("wxhjza/survello-app", {
    release: process.env.VUE_APP_RELEASE,
  });

  Sentry.init({
    dsn: "https://4bd5c67b3bda42d08dfad75642e55167@sentry.io/1364530",
    release: process.env.VUE_APP_RELEASE,
    environment:
      process.env.NODE_ENV !== "production" ? "development" : "production",
  });

  Sentry.configureScope(scope => {
    scope.addEventProcessor(async event => {
      if (LogRocket && LogRocket.sessionURL && event && event.extra) {
        event.extra.sessionURL = LogRocket.sessionURL;
      }
      return event;
    });
  });
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
