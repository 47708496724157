export const ADD_USER = "ADD_USER";
export const SET_USER = "SET_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const RESET_USER = "RESET_USER";

export const ADD_INQUIRY = "ADD_INQUIRY";
export const RESET_INQUIRY = "RESET_INQUIRY";

export const ADD_JOB = "ADD_JOB";
export const UPDATE_JOB = "UPDATE_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const RESET_JOB = "RESET_JOB";

export const ADD_RESPONSE = "ADD_RESPONSE";
export const UPDATE_RESPONSE = "UPDATE_RESPONSE";
export const UPDATE_RESPONSE_FIELD = "UPDATE_RESPONSE_FIELD";
export const SET_FIELD = "SET_FIELD";
export const SET_FIELD_FILENAME = "SET_FIELD_FILENAME";
export const REMOVE_FIELDS = "REMOVE_FIELDS";
export const RESET_FIELD_FILENAME = "RESET_FIELD_FILENAME";
export const DELETE_RESPONSE = "DELETE_RESPONSE";
export const RESET_RESPONSE = "RESET_RESPONSE";

export const ADD_IMAGE = "ADD_IMAGE";
export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const RESET_IMAGE = "RESET_IMAGE";

export const ADD_LOG = "ADD_LOG";

export const SET_IS_SYNCING = "SET_IS_SYNCING";
