<template>
  <div
    class="ui grouped fields"
    :class="{
      disabled: response.editStatus > 0 && !userIsAdmin,
      required: isFieldRequired(field),
    }"
  >
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">{{ field.sectionDescription }}</p>
    <div
      class="ui field grouped fields"
      :class="{
        disabled: response.editStatus > 0 && !userIsAdmin,
        required: isFieldRequired(field),
      }"
    >
      <label v-if="field.headingTitle">{{ field.headingTitle }}</label>
      <p v-if="field.headingDescription">{{ field.headingDescription }}</p>

      <div
        class="ui fluid search selection dropdown"
        :class="{
          disabled: (response.editStatus > 0 && !userIsAdmin) || field.readonly,
          required: isFieldRequired(field),
        }"
        :name="field.id"
      >
        <input type="hidden" :name="field.id" />
        <i class="dropdown icon"></i>
        <div class="default text">Option auswählen …</div>
        <div class="menu">
          <template v-for="choice in field.choices">
            <div
              class="item"
              :data-value="choice.text"
              :v-bind="choice"
              :key="choice.id"
              :id="choice.id"
            >
              {{ choice.text }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  setCheckbox,
  setSelectionCondition,
  isFieldRequired,
  isDescriptionRequired,
  setDescriptionText,
} from "@/lib/FormUtil";

export default {
  name: "FormDropdown",
  props: ["field", "inquiry", "job", "response", "userIsAdmin"],
  data() {
    return {
      currentChoice: null,
    };
  },
  mounted() {
    this.initDropdown();
  },
  asyncComputed: {
    /* eslint-disable */
    descriptionValue: {
      get() {
        const field = this.response.fields.find(f => f.id === this.field.id);
        if (!field) {
          return null;
        }

        return field.text;
      },
      watch() {
        this.response;
      }
    }
    /* eslint-enable */
  },
  methods: {
    isFieldRequired(field) {
      return isFieldRequired(field);
    },
    isDescriptionRequired(field) {
      return isDescriptionRequired(field);
    },
    setDescriptionText(field, event) {
      setDescriptionText(field.id, this.response, event.target.value);
    },
    initDropdown() {
      $(`.ui.dropdown[name=${this.field.id}]`)
        .dropdown({
          onChange: async (value, text, $selectedItem) => {
            const selectedChoiceId = $selectedItem[0].id;
            const selectedChoice = this.field.choices.find(
              choice => choice.id === selectedChoiceId
            );

            // Only save changes when input value has been changed
            if (this.preselectDropdownValue() !== text) {
              this.setSelectedOption(this.field, selectedChoice, text);
            }
            return text;
          },
          message: {
            noResults: "Kein passender Eintrag.",
          },
        })
        .dropdown("set selected", this.preselectDropdownValue());
    },
    preselectDropdownValue() {
      const field = this.response.fields.find(f => f.id === this.field.id);
      if (field && field.choiceIds && field.choiceIds.length > 0) {
        return field.choiceIds[0].toString();
      }
      return null;
    },
    setSelectedOption(field, choice, value) {
      this.currentChoice = choice;
      setCheckbox(field.id, this.response, value);

      if (choice.selectionConditions && choice.selectionConditions.length > 0) {
        choice.selectionConditions.forEach(condition => {
          setSelectionCondition(
            condition.fieldId,
            this.response,
            condition.comparator,
            condition.value
          );
        });
      }
    },
  },
};
</script>
