<template>
  <div>
    <div
      v-if="loading && (!jobs || Object.keys(jobs).length === 0)"
      class="ui segment middle aligned"
    >
      <div class="ui active centered loader inline text">
        <div class="ui loader text">Suche Aufträge…</div>
        <div class="ui" style="color: gray">Suche nach neuen Aufträgen …</div>
      </div>
    </div>
    <div v-if="isAdmin" class="ui container center aligned container-empty">
      <div>
        <i class="shield alternate massive disabled icon"></i>
      </div>
      <h3 class="deactive">Admin-Modus aktiv</h3>
      <p class="deactive">
        Sie sind nun erfolgreich als Admin angemeldet.
        <br />Wählen Sie nun den gewünschten Auftrag im Survello-Cockpit aus, um
        mit der Bearbeitung zu starten.
      </p>
    </div>
    <div
      v-else-if="!loading && (!jobs || Object.keys(jobs).length === 0)"
      class="ui container center aligned container-empty"
    >
      <EmptyListIcon />
      <h3 class="deactive">Keine offenen Aufträge</h3>
      <p class="deactive">
        Sobald offene Aufträge für Sie zur Verfügung stehen, werden diese hier
        angezeigt.
      </p>
      <button
        class="ui right button"
        :class="{ loading: loading }"
        :disabled="loading"
        @click="$emit('load')"
      >
        Aufträge abrufen
      </button>
    </div>
  </div>
</template>

<script>
import EmptyListIcon from "@/assets/icon-inquiries-empty.svg";

export default {
  name: "JobListEmptyState",
  props: ["loading", "isAdmin", "jobs"],
  components: { EmptyListIcon },
};
</script>
