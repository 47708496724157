import { apiClient } from "./ApiClient";

export default {
  downloadById(params) {
    return apiClient.sharedInstance().get(`responses/${params.id}`);
  },
  upload(params) {
    return apiClient.sharedInstance().post("responses", params);
  },
};
