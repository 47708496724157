<template>
  <modal black v-model="isCompleteJobModalShowing" modalVariation="basic">
    <div v-if="userIsAdmin" slot="header" class="center">
      <i class="checkmark icon"></i>
      Auftrag speichern
    </div>
    <div v-else slot="header" class="center">
      <i class="checkmark icon"></i>
      Auftrag abschließen
    </div>
    <div v-if="userIsAdmin" class="content" slot="content">
      <p>
        <strong>
          Bitte prüfen Sie vor dem Speichern der Änderungen Ihre Angaben zur
          Sicherheit erneut.
        </strong>
      </p>
      <p>
        <strong>Möchten Sie dieses Auftrag wirklich speichern?</strong>
      </p>
      <p>
        Die Änderung der Angaben können nach dem Speichern nicht mehr rückgängig
        gemacht werden.
      </p>
    </div>
    <div v-else class="content" slot="content">
      <p>
        <strong>
          Bitte prüfen Sie vor dem Abschluss eines Auftrages Ihres Angaben zur
          Sicherheit erneut.
        </strong>
      </p>
      <p>
        <strong>Möchten Sie dieses Auftrag wirklich abschließen?</strong>
      </p>
      <p>
        Eine Änderung der Angaben ist nach dem Abschluss nicht mehr möglich.
      </p>
    </div>
    <template slot="actions">
      <div
        class="ui black deny inverted button"
        @click="cancelConfirmInquiryModal"
      >
        <i class="remove icon"></i> Nein
      </div>
      <div class="ui green right inverted button" @click="confirmJobModal">
        <i class="checkmark icon"></i> Ja
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "vue-semantic-modal";

export default {
  name: "ModalJobComplete",
  props: [
    "inquiry",
    "isCompleteJobModalShowing",
    "isCompleteJobModalConfirmed",
    "response",
    "job",
    "userIsAdmin",
  ],
  components: {
    modal,
  },
  methods: {
    cancelConfirmInquiryModal() {
      this.$emit("update:isCompleteJobModalShowing", false);
      this.$emit("update:isCompleteJobModalConfirmed", true);
    },
    confirmJobModal() {
      console.log("confirmJobModal called");
      const submitBtn = document.querySelector(".btn-submit");
      submitBtn.className += " loading";

      // Allow leaving the view
      this.cancelConfirmInquiryModal();

      this.$store
        .dispatch("response/setEditStatus", {
          id: this.response.id,
          editStatus: 1,
        })
        .then(() =>
          this.$store.dispatch("job/setEditStatus", {
            id: this.job.id,
            editStatus: 2,
          })
        )
        .then(() =>
          this.$store.dispatch("response/cleanCompletedResponse", {
            id: this.response.id,
          })
        )
        .then(() => {
          this.$router.push({
            path: "/jobs",
          });
          this.$emit("update:isCompleteJobModalConfirmed", false);

          // Delay start of upload sync by one second to leave edit view without loop
          setTimeout(() => this.$store.dispatch("sync/upload"), 1000);
        })
        .catch(error => {
          console.error(
            "Error updating job after submitting form",
            error.stack || error
          );

          submitBtn.className -= "loading";
        });
    },
  },
};
</script>
