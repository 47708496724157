<template>
  <div
    class="ui grouped fields"
    :class="{
      disabled: response.editStatus > 0 && !userIsAdmin,
      required: isFieldRequired(field),
    }"
  >
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">
      {{ field.sectionDescription }}
    </p>
    <div
      class="ui field grouped fields"
      :class="{
        disabled: response.editStatus > 0 && !userIsAdmin,
        required: isFieldRequired(field),
      }"
      :required="isFieldRequired(field)"
    >
      <label v-if="field.headingTitle">
        {{ field.headingTitle }}
      </label>
      <p v-if="field.headingDescription">
        {{ field.headingDescription }}
      </p>
      <div
        class="ui input"
        :class="{
          disabled: (response.editStatus > 0 && !userIsAdmin) || field.readonly,
          required: isFieldRequired(field),
        }"
        :required="isFieldRequired(field)"
      >
        <textarea
          class="multi"
          type="text"
          name="description"
          placeholder="Bemerkung hinzufügen…"
          spellcheck="true"
          autocorrect="on"
          rows="3"
          :value="descriptionValue"
          :required="isFieldRequired(field)"
          @blur="setDescriptionText(field, $event)"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isFieldRequired,
  isDescriptionRequired,
  setDescriptionText,
} from "@/lib/FormUtil";

export default {
  name: "FormOpenEndedMulti",
  props: ["field", "inquiry", "job", "response", "userIsAdmin"],
  asyncComputed: {
    /* eslint-disable */
    descriptionValue: {
      get() {
        const field = this.response.fields.find(f => f.id === this.field.id);
        if (!field) {
          return null;
        }

        return field.text;
      },
      watch() {
        this.response;
      },
    },
    /* eslint-enable */
  },
  methods: {
    isFieldRequired(field) {
      return isFieldRequired(field);
    },
    isDescriptionRequired(field) {
      return isDescriptionRequired(field);
    },
    setDescriptionText(field, event) {
      setDescriptionText(field.id, this.response, event.target.value);
    },
  },
};
</script>
