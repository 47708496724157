var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.job.syncStatus >= 0 && _vm.job.syncStatus < 2)?_c('div',_vm._b({key:_vm.job.id,staticClass:"ui fluid card"},'div',_vm.job,false),[_c('div',{staticClass:"content"},[_c('div',{staticClass:"content__status-edit"},[_c('div',{staticClass:"ui top right attached label tiny",class:{
          yellow: _vm.job.editStatus === 1,
          green: _vm.job.editStatus === 2,
        }},[_vm._v(" "+_vm._s(_vm.job.editStatus === 1 ? "IN BEARBEITUNG" : _vm.job.editStatus === 2 ? "ABGESCHLOSSEN" : "")+" ")])]),_c('div',{staticClass:"header"},[_vm._v(_vm._s(_vm.job.jobNumber))]),_c('div',{staticClass:"extra content"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('br'),_c('div',{staticClass:"description deactive"},[_c('span',{staticClass:"ui inline loader tiny",class:{ active: _vm.job.syncStatus === 1 }}),_vm._v(" "+_vm._s(_vm.job.syncStatus === 0 ? "0 % übertragen …" : _vm.job.syncStatus === 1 ? "wird übertragen …" : _vm.job.syncStatus === 2 ? "100 % übertragen" : "")+" ")])])]),_vm._l((_vm.responses),function(response){return _c('div',{key:response.id,staticClass:"ui list",attrs:{"bind":response}},[(response.jobNumber === _vm.job.jobNumber)?_c('div',[_c('div',{staticClass:"item"},[_c('div',{staticClass:"content"},[_vm._m(1,true),_c('br'),_c('div',{staticClass:"description deactive"},[_c('span',{staticClass:"ui inline loader tiny",class:{ active: response.syncStatus === 1 }}),_vm._v(" "+_vm._s(response.syncStatus === 0 ? "0 % übertragen …" : response.syncStatus === 1 ? "wird übertragen …" : response.syncStatus === 2 ? "100 % übertragen" : "")+" ")])])]),_vm._l((_vm.images),function(image){return _c('div',{key:image.id,staticClass:"item",attrs:{"bind":image}},[(image.responseId === response.id)?[_c('div',{staticClass:"content"},[_c('p',[_vm._m(2,true),_c('br'),_c('span',{staticClass:"description deactive"},[_c('span',{staticClass:"ui inline loader tiny",class:{ active: image.syncStatus === 1 }}),_vm._v(" "+_vm._s(_vm.getUploadProgressForFile(image))+" ")])])])]:_vm._e()],2)})],2):_vm._e()])})],2)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"header deactive"},[_c('strong',[_vm._v("Auftrag")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"header deactive"},[_c('strong',[_vm._v("Rückmeldung zum Auftrag")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"header deactive"},[_c('strong',[_vm._v("Foto")])])
}]

export { render, staticRenderFns }