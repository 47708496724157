var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui grouped fields form__block",class:{
    disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
    required: _vm.isFieldRequired(_vm.field),
  }},[(_vm.field.sectionTitle)?_c('h4',{staticClass:"ui dividing header"},[_vm._v(" "+_vm._s(_vm.field.sectionTitle)+" ")]):_vm._e(),(_vm.field.sectionDescription)?_c('p',[_vm._v(_vm._s(_vm.field.sectionDescription))]):_vm._e(),(_vm.field.headingTitle)?_c('label',[_vm._v(_vm._s(_vm.field.headingTitle))]):_vm._e(),(_vm.field.headingDescription)?_c('p',[_vm._v(_vm._s(_vm.field.headingDescription))]):_vm._e(),(_vm.field.choices)?_c('div',{staticClass:"radio__group"},[_vm._l((_vm.field.choices),function(choice){return [_c('div',_vm._b({key:choice.id,staticClass:"field",attrs:{"required":_vm.isFieldRequired(_vm.field)}},'div',choice,false),[_c('div',{staticClass:"ui radio checkbox",on:{"click":function($event){return _vm.setCheckbox(_vm.field, choice, $event)}}},[_c('input',{staticClass:"hidden",attrs:{"type":"radio","name":_vm.field.id,"tabindex":"0","required":_vm.isFieldRequired(_vm.field)},domProps:{"checked":_vm.isCheckboxChecked(_vm.field, choice)}}),_c('label',{attrs:{"id":choice.id}},[_vm._v(_vm._s(choice.text))])])])]})],2):_vm._e(),_c('div',[_c('div',{staticClass:"ui field",staticStyle:{"padding-bottom":"1em"}},[_c('div',{staticClass:"ui"},[_c('div',{staticClass:"ui dimmer inverted",attrs:{"name":_vm.field.id}},[_c('div',{staticClass:"ui indeterminate text loader"},[_vm._v("Lade …")])]),_c('img',{staticClass:"ui small rounded bordered image loading signature__image-preview",attrs:{"name":_vm.field.id,"src":_vm.imageSrc,"required":_vm.isFieldRequired(_vm.field)}}),_c('input',{staticStyle:{"opacity":"0","width":"1px","height":"1px","margin-bottom":"0","padding-bottom":"0"},attrs:{"type":"file","accept":".jpg, .jpeg, .png","required":!_vm.isPhotoAvailable}})])]),(
        (!_vm.isPhotoAvailable && _vm.response.editStatus === 0) ||
        (!_vm.isPhotoAvailable && _vm.userIsAdmin)
      )?_c('div',{staticClass:"ui field bottom aligned photo-preview__buttons",staticStyle:{"margin-top":"-20px"}},[_c('div',{staticClass:"ui primary button bottom fluid photo-preview btn-photo-capture wide",attrs:{"name":_vm.field.id},on:{"click":function($event){return _vm.showSignatureModal(_vm.field)}}},[_c('i',{staticClass:"paint brush icon"}),_vm._v(" Unterschrift erfassen ")])]):_vm._e(),_c('div',{staticClass:"ui field bottom aligned aligned photo-preview__buttons",staticStyle:{"margin-bottom":"15px"}},[(
          (_vm.isPhotoAvailable && _vm.response.editStatus === 0) ||
          (_vm.isPhotoAvailable && _vm.userIsAdmin)
        )?_c('div',{staticClass:"ui red button fluid btn-photo-remove",on:{"click":function($event){return _vm.showDeleteSignatureModal(_vm.field)}}},[_c('i',{staticClass:"trash icon"}),_vm._v(" Unterschrift löschen ")]):_vm._e()])]),(_vm.isDescriptionVisible(_vm.field))?_c('div',{staticClass:"ui field",class:{
      disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
      required: _vm.isDescriptionRequired(_vm.field),
    },attrs:{"required":_vm.isDescriptionRequired(_vm.field)}},[_c('label',[_vm._v("Bemerkung")]),_c('textarea',{staticClass:"multi",class:{
        disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
        required: _vm.isDescriptionRequired(_vm.field),
      },attrs:{"type":"text","name":"description","placeholder":"Bemerkung hinzufügen…","spellcheck":"true","autocorrect":"on","rows":"3","required":_vm.isDescriptionRequired(_vm.field)},domProps:{"value":_vm.descriptionValue},on:{"blur":function($event){return _vm.setDescriptionText(_vm.field, $event)}}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }