<template>
  <div
    class="ui grouped fields form__block"
    :class="{
      disabled: response.editStatus > 0 && !userIsAdmin,
      required: isFieldRequired(field),
    }"
  >
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">
      {{ field.sectionDescription }}
    </p>
    <label v-if="field.headingTitle">
      {{ field.headingTitle }}
    </label>
    <p v-if="field.headingDescription">
      {{ field.headingDescription }}
    </p>
    <div v-if="field.headingImage" class="ui segment">
      <img
        :name="field.id"
        class="ui small rounded bordered image loading"
        :src="previewImageSrc"
        @click="openImagePreview(previewImageSrc)"
        style="cursor: pointer;margin-top: -5px"
      />
    </div>

    <template v-for="choice in field.choices">
      <div
        class="field"
        v-bind="choice"
        v-bind:key="choice.id"
        :required="isFieldRequired(field)"
        v-if="isOptionVisible(choice, response)"
      >
        <div
          class="ui radio checkbox"
          :required="isFieldRequired(field)"
          @click="setCheckbox(field, choice, $event)"
        >
          <input
            type="radio"
            tabindex="0"
            class="hidden"
            :name="field.id"
            :checked="isCheckboxChecked(field, choice)"
            :required="isFieldRequired(field)"
          />
          <label :id="choice.id">{{ choice.text }}</label>
        </div>
      </div>
    </template>

    <div
      class="ui field"
      v-if="isDescriptionVisible(field)"
      :class="{
        disabled: response.editStatus > 0 && !userIsAdmin,
        required: isDescriptionRequired(field),
      }"
      :required="isDescriptionRequired(field)"
    >
      <label class="label-small">Bemerkung</label>
      <div
        class="ui input"
        v-if="currentChoice && currentChoice.descriptionRequiredMessage"
        v-bind="currentChoice"
        v-bind:key="currentChoice.id"
        :required="isDescriptionRequired(field)"
      >
        <textarea
          type="text"
          name="description"
          spellcheck="true"
          autocorrect="on"
          rows="3"
          :class="{
            disabled: response.editStatus > 0 && !userIsAdmin,
            required: isDescriptionRequired(currentChoice),
          }"
          :placeholder="currentChoice.descriptionRequiredMessage"
          :value="descriptionValue"
          :required="isDescriptionRequired(currentChoice)"
          @blur="setDescriptionText(field, $event)"
        >
        </textarea>
      </div>
      <div class="ui input" :required="isDescriptionRequired(field)" v-else>
        <textarea
          type="text"
          name="description"
          placeholder="Bemerkung hinzufügen…"
          spellcheck="true"
          autocorrect="on"
          rows="3"
          :class="{
            disabled: response.editStatus > 0 && !userIsAdmin,
            required: isDescriptionRequired(field),
          }"
          :value="descriptionValue"
          :required="isDescriptionRequired(field)"
          @blur="setDescriptionText(field, $event)"
        >
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import {
  isFieldRequired,
  isDescriptionRequired,
  isDescriptionVisible,
  isCheckboxChecked,
  isOptionVisible,
  setCheckbox,
  setSelectionCondition,
  setDescriptionText,
} from "@/lib/FormUtil";

const placeholderImage = require("@/assets/white-image.png");

export default {
  name: "FormSingleValueVertical",
  props: ["field", "inquiry", "job", "response", "userIsAdmin"],
  data() {
    return {
      currentChoice: null,
    };
  },
  asyncComputed: {
    /* eslint-disable */
    descriptionValue: {
      get() {
        const field = this.response.fields.find(f => f.id === this.field.id);
        if (!field) {
          return null;
        }
        return field.text;
      },
      watch() {
        this.response;
      },
    },
    previewImageSrc: {
      get() {
        if (this.field) {
          return this.loadPreviewImage(this.field);
        }
        return placeholderImage;
      },
      watch() {
        this.response;
      },
    },
    /* eslint-enable */
  },
  methods: {
    isFieldRequired(field) {
      return isFieldRequired(field);
    },
    isOptionVisible(field) {
      return isOptionVisible(field, this.response);
    },
    isDescriptionVisible(field) {
      return isDescriptionVisible(field, this.response);
    },
    isDescriptionRequired(field) {
      return isDescriptionRequired(field, this.response);
    },
    isCheckboxChecked(field, choice) {
      return isCheckboxChecked(field.id, this.response, choice);
    },
    setCheckbox(field, choice, event) {
      this.currentChoice = choice;
      setCheckbox(field.id, this.response, event.target.id);

      if (choice.selectionConditions && choice.selectionConditions.length > 0) {
        choice.selectionConditions.forEach(condition => {
          setSelectionCondition(
            condition.fieldId,
            this.response,
            condition.comparator,
            condition.value
          );
        });
      }
    },
    setDescriptionText(field, event) {
      setDescriptionText(field.id, this.response, event.target.value);
    },
    loadPreviewImage(field) {
      if (!field.headingImage) {
        return placeholderImage;
      }

      // Show image from remote if available
      if (field.headingImage.imageUrl) {
        return field.headingImage.imageUrl;
      }

      // Show remote image if available and user online
      if (field.headingImage.mime && field.headingImage.data) {
        return (
          "data:" +
          field.headingImage.mime +
          ";base64," +
          field.headingImage.data
        );
      }

      return placeholderImage;
    },
    openImagePreview(imageSrc) {
      if (!this.field.headingImage) {
        return;
      }
      EventBus.$emit("SHOW_VIEWPHOTO_MODAL", imageSrc);
    },
  },
};
</script>
