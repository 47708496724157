var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('main',{staticClass:"ui vertical-center"},[(_vm.loading)?_c('div',{staticClass:"ui segment"},[_vm._m(1)]):_vm._e(),(
        (_vm.pendingJobs && _vm.pendingJobs.length > 0) ||
        (_vm.pendingResponses && _vm.pendingResponses.length > 0)
      )?_c('div',[_c('div',{staticClass:"ui container"},[(!_vm.syncing)?_c('button',{staticClass:"fluid ui left labeled icon blue big button",class:{ disabled: _vm.syncing, loading: _vm.syncing },staticStyle:{"padding-left":"3.5em !important","margin-bottom":"1em"},on:{"click":function($event){return _vm.handleSyncButton($event)}}},[_c('i',{staticClass:"left cloud upload icon"}),_vm._v(_vm._s(_vm.pendingJobs.length)+" "+_vm._s(_vm.pendingJobs.length == 1 ? "Auftrag" : "Aufträge")+" übertragen ")]):_vm._e(),(!_vm.loading && _vm.syncing)?_c('div',{staticClass:"ui segment",staticStyle:{"margin":"0","padding":"0"},on:{"click":function($event){return _vm.stopSync($event)}}},[_vm._m(2)]):_vm._e()]),_c('SyncList',{attrs:{"jobs":_vm.pendingJobs,"responses":_vm.pendingResponses,"images":_vm.images}})],1):_c('div',{staticClass:"ui container center aligned container-empty"},[_c('EmptyListIcon'),_c('h3',{staticClass:"deactive"},[_vm._v("Warteschlange ist leer")]),_vm._m(3)],1)]),_c('tab-bar',{attrs:{"pendingJobs":_vm.pendingJobs,"pendingResponses":_vm.pendingResponses}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"ui top fixed menu two column grid wide"},[_c('div',{staticClass:"column"},[_c('span',[_vm._v("Warteschlange")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui active centered inline loader text"},[_c('div',{staticClass:"ui text loader"},[_vm._v("Lade …")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui icon small message blue"},[_c('i',{staticClass:"notched circle loading icon"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"header"},[_vm._v("Übertragung läuft …")]),_c('p',[_vm._v("Die Warteschlange wird übertragen.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"deactive"},[_vm._v(" Zu übertragende Aufträge erscheinen "),_c('br'),_vm._v("in dieser Liste. ")])
}]

export { render, staticRenderFns }