/**
 * helper.js
 *
 * @author Marcel Bender
 * @since 1.0.0
 */

import { formatBytes, formatPercentage } from "../util/filters";

/**
 * Reads the current version from the manifest
 * @returns {String} Version
 */
export function getVersionFromManifest() {
  if (process.env.NODE_ENV === "development") {
    return null;
  }

  return fetch("./manifest.json")
    .then(response => response.json())
    .then(json => json.version);
}

export function getCurrentDataUsage() {
  return new Promise(resolve => {
    if ("storage" in navigator && "estimate" in navigator.storage) {
      // Get current storage values
      navigator.storage.estimate().then(estimate => {
        if (!estimate) {
          return resolve();
        }
        const storageUsage = formatBytes(estimate.usage);
        const storageQuota = formatBytes(estimate.quota);
        console.log("[STORAGE] Using", storageUsage);
        console.log("[STORAGE] Quota", storageQuota);
        const percent = formatPercentage(estimate.usage, estimate.quota);
        console.log(`[STORAGE] Percent ${percent} %`);
        return resolve();
      });
    }
    return resolve();
  });
}
