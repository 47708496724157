<template>
  <div
    class="ui grouped fields"
    :class="{
      disabled: response.editStatus > 0 && !userIsAdmin,
      required: isFieldRequired(field),
    }"
  >
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">
      {{ field.sectionDescription }}
    </p>
    <div
      class="ui field grouped fields"
      :class="{
        disabled: response.editStatus > 0 && !userIsAdmin,
        required: isFieldRequired(field),
      }"
      :required="isFieldRequired(field)"
    >
      <label v-if="field.headingTitle">
        {{ field.headingTitle }}
      </label>
      <p v-if="field.headingDescription">
        {{ field.headingDescription }}
      </p>
      <div v-if="field.headingImage" class="ui segment">
      <img
        :name="field.id"
        class="ui small rounded bordered image loading"
        :src="previewImageSrc"
        @click="openImagePreview(previewImageSrc)"
        style="cursor: pointer;margin-top: -5px"
      />
    </div>
      <div
        class="ui input"
        :class="{
          disabled: (response.editStatus > 0 && !userIsAdmin) || field.readonly,
          required: isFieldRequired(field),
        }"
        :required="isFieldRequired(field)"
      >
        <input
          v-if="field.minValue || field.maxValue"
          type="number"
          :min="field.minValue"
          :max="field.maxValue"
          name="description"
          placeholder="Numerischen Wert eintragen…"
          :value="descriptionValue"
          :required="isFieldRequired(field)"
          @blur="setDescriptionText(field, $event)"
        />
        <input
          v-else
          type="text"
          :pattern="setRegExPattern(field)"
          name="description"
          placeholder="Numerischen Wert eintragen…"
          :value="descriptionValue"
          :required="isFieldRequired(field)"
          @blur="setDescriptionText(field, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import {
  isDescriptionRequired,
  isFieldRequired,
  setDescriptionText,
} from "@/lib/FormUtil";

const placeholderImage = require("@/assets/white-image.png");

export default {
  name: "FormOpenEndedNumerical",
  props: ["field", "inquiry", "job", "response", "userIsAdmin"],
  asyncComputed: {
    /* eslint-disable */
    descriptionValue: {
      get() {
        const field = this.response.fields.find(f => f.id === this.field.id);
        if (!field) {
          return null;
        }

        return field.text;
      },
      watch() {
        this.response;
      },
    },
    previewImageSrc: {
      get() {
        if (this.field) {
          return this.loadPreviewImage(this.field);
        }
        return placeholderImage;
      },
      watch() {
        this.response;
      },
    },
    /* eslint-enable */
  },
  methods: {
    isFieldRequired(field) {
      return isFieldRequired(field);
    },
    isDescriptionRequired(field) {
      return isDescriptionRequired(field);
    },
    setDescriptionText(field, event) {
      setDescriptionText(field.id, this.response, event.target.value);
    },
    setRegExPattern(field) {
      if (field.minLength && field.maxLength) {
        return `[0-9,-]{${field.minLength},${field.maxLength}}([-,\.][0-9]+)?`;
      } else if (field.minLength && !field.maxLength) {
        return `[0-9,-]{${field.minLength},}([-,\.][0-9]+)?`;
      } else if (!field.minLength && field.maxLength) {
        return `[0-9,-]{0,${field.maxLength}}([-,\.][0-9]+)?`;
      } else {
        return "[0-9,-]+([-,\.][0-9]+)?";
      }
    },
    loadPreviewImage(field) {
      if (!field.headingImage) {
        return placeholderImage;
      }

      // Show image from remote if available
      if (field.headingImage.imageUrl) {
        return field.headingImage.imageUrl;
      }

      // Show remote image if available and user online
      if (field.headingImage.mime && field.headingImage.data) {
        return (
          "data:" +
          field.headingImage.mime +
          ";base64," +
          field.headingImage.data
        );
      }

      return placeholderImage;
    },
    openImagePreview(imageSrc) {
      if (!this.field.headingImage) {
        return;
      }
      EventBus.$emit("SHOW_VIEWPHOTO_MODAL", imageSrc);
    },
  },
};
</script>
