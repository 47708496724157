<template>
  <div>
    <nav class="ui top fixed menu two column grid wide">
      <div class="column">
        <span>Aufträge</span>
      </div>
      <div class="column nav--sync">
        <div
          class="ui button inverted icon white button--sync"
          v-if="!isAdmin"
          :class="{ loading }"
          :disabled="loading"
          @click="$emit('load')"
        >
          <i class="refresh icon"></i>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBarJobs",
  props: ["loading", "isAdmin"],
};
</script>

<style scoped>
nav span {
  padding-top: 2px;
}

.nav--sync {
  padding-top: 0.7rem !important;
  padding-right: 1rem;
  text-align: right;
}

.button--sync {
  margin-right: 0;
}
</style>
