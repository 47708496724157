<template>
  <div v-if="time" class="meta">
    <span class="floated time">
      <i class="clock outline icon" style="margin-left: -2px"></i>
      {{ time }} Uhr
    </span>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("de");

export default {
  name: "JobCellDueTime",
  props: ["dueAt"],
  computed: {
    time: function () {
      return moment(this.dueAt).format("LT");
    },
  },
};
</script>
