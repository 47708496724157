<template>
  <div>
    <div
      class="ui red message"
      v-if="!browser.isSupported"
      style="margin-bottom: 15px"
    >
      <div class="header">Nicht unterstützter Browser erkannt</div>
      <div v-if="browser.isIos">
        <p>
          Damit die Survello-App zuverlässig funktioniert, muss auf iOS-Geräten
          zwingend der Safari-Browser verwendet werden.
        </p>
        <a
          href="https://app.survello.de"
          target="_blank"
          class="ui blue fluid button"
          >App im Safari öffnen</a
        >
      </div>
      <div v-else-if="browser.isAndroid">
        <p>
          Damit die Survello-App zuverlässig funktioniert, muss auf
          Android-Geräten zwingend der Chrome-Browser verwendet werden.
        </p>
        <a
          href="googlechrome://navigate?url=app.survello.de/"
          target="_blank"
          class="ui blue fluid button"
          >App im Chrome öffnen</a
        >
      </div>
    </div>
    <div
      class="ui yellow message"
      v-else-if="!browser.isStandalone"
      style="margin-bottom: 15px"
    >
      <div class="header">App zum Home-Bildschirm hinzufügen</div>
      <p>
        Damit die Survello-App zuverlässig funktioniert, fügen Sie diese Seite
        den Home-Bildschirm hinzu.
      </p>
      <router-link :to="{ name: 'homeScreenManual' }" class="ui yellow button"
        >Anleitung öffnen</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "UnsupportedDevice",
  props: ["browser"],
};
</script>
