<template>
  <modal
    black
    v-model="isConfirmLeavingViewModalShowing"
    modalVariation="basic"
  >
    <div slot="header" class="center">
      <i class="warning sign icon"></i>
      Auftrag nicht abgeschlossen
    </div>
    <div class="content" slot="content">
      <p>
        <strong>
          Möchten Sie die Bearbeitung wirklich abbrechen ohne den Auftrag
          abzuschließen?
        </strong>
      </p>
      <p>
        Die bisherigen Angaben wurden automatisch gespeichert und können später
        erneut aufgerufen werden.
      </p>
    </div>
    <template slot="actions">
      <div
        class="ui black deny inverted button"
        @click="cancelLeavingViewModal"
      >
        <i class="remove icon"></i> Nein
      </div>
      <div
        class="ui green right inverted button"
        @click="confirmLeavingViewModal"
      >
        <i class="checkmark icon"></i> Ja
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "vue-semantic-modal";

export default {
  name: "ModalJobClose",
  props: [
    "isConfirmLeavingViewModalShowing",
    "isConfirmLeavingViewModalConfirmed",
  ],
  components: {
    modal,
  },
  methods: {
    showLeavingViewModal() {
      this.$emit("update:isConfirmLeavingViewModalShowing", true);
      this.$emit("update:isConfirmLeavingViewModalConfirmed", false);
    },
    cancelLeavingViewModal() {
      this.$emit("update:isConfirmLeavingViewModalShowing", false);
      this.$emit("update:isConfirmLeavingViewModalConfirmed", true);
    },
    confirmLeavingViewModal() {
      this.cancelLeavingViewModal();

      this.$router
        .replace({
          name: "jobs",
        })
        .then(() => {
          this.$store.dispatch("sync/upload");
        });
    },
  },
};
</script>
