<template>
  <div
    class="ui grouped fields form__block"
    v-bind:class="{
      disabled: response.editStatus > 0 && !userIsAdmin,
      required: isFieldRequired(field),
    }"
  >
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">{{ field.sectionDescription }}</p>
    <label v-if="field.headingTitle">{{ field.headingTitle }}</label>
    <p v-if="field.headingDescription">{{ field.headingDescription }}</p>

    <div class="radio__group" v-if="field.choices">
      <template v-for="choice in field.choices">
        <div
          class="field"
          v-bind="choice"
          v-bind:key="choice.id"
          :required="isFieldRequired(field)"
        >
          <div
            class="ui radio checkbox"
            @click="setCheckbox(field, choice, $event)"
          >
            <input
              type="radio"
              :name="field.id"
              tabindex="0"
              class="hidden"
              :checked="isCheckboxChecked(field, choice)"
              :required="isFieldRequired(field)"
            />
            <label :id="choice.id">{{ choice.text }}</label>
          </div>
        </div>
      </template>
    </div>

    <!-- Show buttons only if current choice matches the condition -->
    <div>
      <div class="ui field" style="padding-bottom: 1em">
        <div class="ui">
          <div :name="field.id" class="ui dimmer inverted">
            <div class="ui indeterminate text loader">Lade …</div>
          </div>
          <img
            :name="field.id"
            class="
              ui
              small
              rounded
              bordered
              image
              loading
              signature__image-preview
            "
            :src="imageSrc"
            :required="isFieldRequired(field)"
          />
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            style="
              opacity: 0;
              width: 1px;
              height: 1px;
              margin-bottom: 0;
              padding-bottom: 0;
            "
            :required="!isPhotoAvailable"
          />
        </div>
      </div>
      <div
        class="ui field bottom aligned photo-preview__buttons"
        style="margin-top: -20px"
        v-if="
          (!isPhotoAvailable && response.editStatus === 0) ||
          (!isPhotoAvailable && userIsAdmin)
        "
      >
        <div
          :name="field.id"
          class="
            ui
            primary
            button
            bottom
            fluid
            photo-preview
            btn-photo-capture
            wide
          "
          @click="showSignatureModal(field)"
        >
          <i class="paint brush icon"></i>
          Unterschrift erfassen
        </div>
      </div>
      <div
        class="ui field bottom aligned aligned photo-preview__buttons"
        style="margin-bottom: 15px"
      >
        <div
          class="ui red button fluid btn-photo-remove"
          v-if="
            (isPhotoAvailable && response.editStatus === 0) ||
            (isPhotoAvailable && userIsAdmin)
          "
          @click="showDeleteSignatureModal(field)"
        >
          <i class="trash icon"></i>
          Unterschrift löschen
        </div>
      </div>
    </div>

    <div
      class="ui field"
      :class="{
        disabled: response.editStatus > 0 && !userIsAdmin,
        required: isDescriptionRequired(field),
      }"
      :required="isDescriptionRequired(field)"
      v-if="isDescriptionVisible(field)"
    >
      <label>Bemerkung</label>
      <textarea
        class="multi"
        type="text"
        name="description"
        placeholder="Bemerkung hinzufügen…"
        spellcheck="true"
        autocorrect="on"
        rows="3"
        :class="{
          disabled: response.editStatus > 0 && !userIsAdmin,
          required: isDescriptionRequired(field),
        }"
        :value="descriptionValue"
        :required="isDescriptionRequired(field)"
        @blur="setDescriptionText(field, $event)"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import {
  isFieldRequired,
  isDescriptionRequired,
  isDescriptionVisible,
  isCheckboxChecked,
  setCheckbox,
  setDescriptionText,
} from "@/lib/FormUtil";

const placeholderImage = require("@/assets/white-image.png");

export default {
  name: "FormFileSignature",
  props: [
    "response",
    "job",
    "field",
    "isDeleteSignatureModalShowing",
    "isDeleteSignatureModalConfirmed",
    "userIsAdmin",
  ],
  data() {
    return {
      isPreviewImageUpdatedNeeded: true,
    };
  },
  asyncComputed: {
    /* eslint-disable */
    imageSrc: {
      get() {
        return this.loadImage(this.field);
      },
      watch() {
        this.response;
      },
    },
    descriptionValue: {
      get() {
        if (!this.response) {
          return null;
        }
        const field = this.response.fields.find(f => f.id === this.field.id);
        if (!field) {
          return null;
        }

        return field.text;
      },
      watch() {
        this.response;
      },
    },
    isPhotoAvailable: {
      get() {
        if (!this.response) {
          return null;
        }

        const localField = this.response.fields.find(
          f => f.id === this.field.id
        );
        if (!localField || !localField.fileName) {
          return false;
        }
        return true;
      },
      watch() {
        this.response;
      },
    },
    responseField: {
      get() {
        if (!this.response) {
          return null;
        }
        const localResponse = this.response.fields.find(
          f => f.id === this.field.id
        );
        return localResponse || null;
      },
    },
    /* eslint-enable */
  },
  methods: {
    showSignatureModal(field) {
      EventBus.$emit("SHOW_SIGNATUREPAD_MODAL", {
        field,
        inquiry: this.inquiry,
        response: this.response,
        job: this.job,
      });
    },
    showDeleteSignatureModal(field) {
      EventBus.$emit("SHOW_DELETESIGNATURE_MODAL", {
        field,
        inquiry: this.inquiry,
        response: this.response,
        job: this.job,
      });
    },
    isFieldRequired(field) {
      return isFieldRequired(field);
    },
    isDescriptionRequired(field) {
      return isDescriptionRequired(field, this.response);
    },
    isDescriptionVisible(field) {
      return isDescriptionVisible(field, this.response);
    },
    isCheckboxChecked(field, choice) {
      return isCheckboxChecked(field.id, this.response, choice);
    },
    setCheckbox(field, choice, event) {
      this.currentChoice = choice;
      setCheckbox(field.id, this.response, event.target.id);
    },
    setDescriptionText(field, event) {
      setDescriptionText(field.id, this.response, event.target.value);
    },
    /* eslint-disable consistent-return */
    loadImage(field) {
      return new Promise(resolve => {
        // Display image from remote url if available
        const localField = this.response.fields.find(f => f.id === field.id);
        if (localField && localField.fileUrl && window.navigator.onLine) {
          resolve(localField.fileUrl);
        }

        // Show placeholder image if no remote file is present
        if (!localField || (!localField.fileName && !localField.fileUrl)) {
          resolve(placeholderImage);
        }

        // Find local in vuex and show placeholder if not present
        const localImage = this.$store.getters["image/getByFileName"](
          localField.fileName
        );
        if (!localImage) {
          resolve(placeholderImage);
        } else {
          resolve(localImage.src);
        }
      });
    },
  },
};
</script>
