/**
 * DatabaseExportUtil.js
 *
 * @author Marcel Bender
 * @since 1.0.0
 */
import store from "@/store/store";

const uploadType = "resumable";
const key = process.env.VUE_APP_GCS_KEY;
const bucketName = process.env.VUE_APP_GCS_BUCKET;

export async function getFileNameForDbExport() {
  const timestamp = new Date().toISOString();
  const user = store.getters["user/current"];
  if (user) {
    return `${user.id}-${timestamp}`;
  }
  return `unknown-${timestamp}`;
}

export function requestUploadId(file) {
  const localFile = file;
  const filename = `exports/${localFile.name}.json`;

  const url = `https://www.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=${uploadType}&key=${key}&name=${filename}`;
  const header = {
    "Accept-Encoding": "application/json",
    "Content-Type": "application/json; charset=UTF-8",
    "Content-Length": file.contentSize,
    "X-Upload-Content-Length": file.size,
    "X-Upload-Content-Type": file.type,
  };

  return fetch(url, {
    method: "post",
    headers: header,
  })
    .then(response => {
      if (!response.ok) {
        throw Error("Error getting response. Status: ", response.status);
      }

      if (!response.headers.get("Location")) {
        console.error(
          "Error in requestUploadId while getting location header from response",
          response.status
        );
        throw Error("Error getting upload id from request");
      }

      // Split upload id from Google response
      const locationHeader = response.headers.get("Location");
      const responseSplit = locationHeader.split("&");
      const uploadId = responseSplit[3].substring(10);
      return uploadId;
    })
    .then(uploadId => {
      localFile.uploadId = uploadId;
      return localFile;
    });
}

export async function uploadFilePart(
  file,
  isResuming = false,
  chunkSize = 262144
) {
  return new Promise(async (resolve, reject) => {
    let localFile = file;
    if (!localFile.uploadId) {
      localFile.uploadCurrentRange = null;
      localFile.uploadId = null;
      localFile = await requestUploadId(localFile);
    }

    const url = `https://www.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=${uploadType}&key=${key}&upload_id=${localFile.uploadId}`;

    // Init the current upload content range if the image has not been uploaded before
    if (!localFile.uploadCurrentRange) {
      localFile.uploadCurrentRange = 0;
    }

    // Set the lower and upper value of the range, e.g. 0/2612144 for the first chunk
    let localChunkSize = chunkSize;
    const currentContentRange = localFile.uploadCurrentRange;
    let totalContentRange = currentContentRange + localChunkSize;

    // Check if we're going to upload the last chunk of the current image.
    // If adding the default chunk size of 256 KB exceeds the total image size,
    // we detected the last part. Instead of the default 256 KB chunk size
    // we go ahead and calculcate the diff between the current range and the total image size.
    if (totalContentRange > localFile.size) {
      localChunkSize = localFile.size - currentContentRange;
      totalContentRange = localFile.size - 1;
    }

    // Set the content-range header value. If the previous upload failed, we use a
    // wildcard for the current chunk size. Google will then respond with the current
    // content range.
    const localRangeHeader = `bytes ${currentContentRange}-${totalContentRange}/${localFile.size}`;
    const unknownRangeHeader = `bytes */${localFile.size}`;

    console.log(
      `[DBExport, uploadFilePart] Reading bytes: ${localFile.uploadCurrentRange}/${totalContentRange} of ${localFile.size} byte`
    );

    // Get the blob for the content range by slicing the current image
    let blob = null;

    try {
      blob = localFile.slice(
        localFile.uploadCurrentRange,
        totalContentRange + 1,
        localFile.type
      );
    } catch (e) {
      console.error(`[DBExport, uploadFilePart] Error slicing file`, e);
    }

    const request = {
      method: "put",
      headers: {
        "Accept-Encoding": "application/json",
        "Content-Type": localFile.type,
        "Content-Length": isResuming ? localChunkSize : 0,
        "Content-Range": isResuming ? unknownRangeHeader : localRangeHeader,
      },
      body: isResuming ? null : blob,
    };

    fetch(url, request)
      .then(async response => {
        blob = null;

        if (response.status === 308) {
          // Chunk has been uploaded, object not fully uploaded yet
          console.log(`[DBExport, uploadFilePart] Success with code 308`);

          // Save the received content-range to make sure that the upload
          // of the next chunk starts at the correct bytes
          const currentRangeHeader = response.headers.get("Range");
          if (currentRangeHeader) {
            const responseSplit = currentRangeHeader.split("-");
            localFile.uploadCurrentRange = parseInt(responseSplit[1], 10);
          }

          resolve(uploadFilePart(localFile, false));
        } else if (response.status === 400) {
          // The uploaded chunk was not correct. Reset the content range and try again.
          console.log(`[DBExport, uploadFilePart] Success with code 400`);

          localFile.uploadCurrentRange = null;
          localFile.uploadId = null;

          localFile = await requestUploadId(localFile);
          resolve(uploadFilePart(localFile, false));
        } else if (response.status === 404) {
          // Image with upload ID has not been found. Reset and try again.
          console.log(`[DBExport, uploadFilePart] Success with code 404`);

          localFile.uploadCurrentRange = null;
          localFile.uploadId = null;
          localFile = await requestUploadId(localFile);

          resolve(uploadFilePart(localFile, false));
        } else if (response.status === 200 || response.status === 201) {
          // Chunk has been uploaded and the image has been fully uploaded
          console.log(`[DBExport, uploadFilePart] Success with code 200`);

          // Save the uploaded file size to display the correct status
          localFile.uploadCurrentRange = localFile.size;
          resolve();
        }
      })
      .catch(error => {
        console.log(
          `[DBExport, uploadFilePart] Error while uploading file`,
          error.message
        );
        console.error(error);

        reject(error);
      });
  });
}
