import moment from "moment";
moment.locale("de");

import * as types from "../mutation-types";
import InquiryApiService from "@/services/InquiryApiService";

const orderBy = require("lodash/orderBy");

export const EDIT_STATUS = Object.freeze({
  DRAFT: 0,
  COMPLETED: 1,
  SENT: 2,
});
export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

// Database Helpers
async function createNewInquiry(remoteObject) {
  if (!remoteObject) {
    throw new Error("Creating inquiry failed, no input object available");
  }

  return Promise.resolve().then(async () => {
    // console.log(
    //   "[inquiry.js] createNewInquiry called with remote inquiry",
    //   remoteObject
    // );

    return {
      id: remoteObject.id.toString(),
      campaignId: remoteObject.campaign.id.toString(),
      campaignName: remoteObject.campaign.name.toString(),
      version: remoteObject.version.toString(),
      name: remoteObject.name.toString(),
      createdAt:
        remoteObject.created_at || moment().format(remoteObject.created_at),
      updatedAt:
        remoteObject.updated_at || moment().format(remoteObject.updated_at),
      fields: remoteObject.fields,
    };
  });
}

async function handleApiResponse({ dispatch }, response) {
  return Promise.resolve()
    .then(() => {
      if (response.status != 200) throw new Error("Error loading inquiries");
      return response.data.data;
    })
    .then(data => {
      if (!data || data.length === 0) {
        return false;
      }
      return data;
    })
    .then(async data => {
      if (!data) {
        return null;
      }
      if (data && !data.length) {
        return await dispatch("insertOrUpdate", data);
      }
      return data.forEach(inquiry => dispatch("insertOrUpdate", inquiry));
    });
}

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  all: [],
};

/**
 * Getters
 */
export const getters = {
  // Get all inquiries from state
  all: state =>
    orderBy(state.all, ["editStatus", "updatedAt"], ["asc", "desc"]),
  // Get inquired by id from state
  getById: state => id => {
    return state.all.find(inquiry => inquiry.id === id.toString());
  },
  // Get inquired by id from state
  getLatestById: state => id => {
    let inquiries = orderBy(state.all, ["version"], ["desc"]);
    inquiries = inquiries.filter(inquiry => inquiry.id === id.toString());
    if (inquiries.length) return inquiries[0];
    return null;
  },
  // Get inquiry by id and version from state
  getByIdAndVersion: state => (id, version) => {
    const inquiries = orderBy(state.all, ["version"], ["desc"]);
    return inquiries.find(inq => inq.id === id && inq.version === version);
  },
};

/**
 * Actions
 */
export const actions = {
  async add({ commit }, inquiry) {
    return Promise.resolve()
      .then(() => createNewInquiry(inquiry))
      .then(newInquiry => commit(types.ADD_INQUIRY, newInquiry))
      .catch(error => {
        console.error("Error while adding inquiry", error.stack || error);
        throw error;
      });
  },

  async insertOrUpdate({ dispatch }, inquiry) {
    return Promise.resolve()
      .then(() =>
        dispatch("findByIdAndVersion", {
          id: inquiry.id.toString(),
          version: inquiry.version.toString(),
        })
      )
      .then(localInquiry => {
        // Add new inquiry if not available locally
        if (!localInquiry) {
          return dispatch("add", inquiry);
        }
        return localInquiry;
      })
      .catch(error => {
        console.error(
          "Error while inserting/updating inquiry",
          error.stack || error
        );
        throw error;
      });
  },

  async findByIdAndVersion({ state }, { id, version }) {
    return Promise.resolve()
      .then(() => orderBy(state.all, ["version"], ["desc"]))
      .then(inquiries =>
        inquiries.filter(
          inquiry => inquiry.id === id && inquiry.version === version
        )
      )
      .then(inquiries => {
        if (inquiries.length === 1) return inquiries[0];
        return null;
      });
  },

  async getInquiryById({ state }, { id }) {
    return state.all.find(inquiry => inquiry.id === id);
  },

  async getInquiryByContractId({ state }, { contractId }) {
    return state.all.find(inquiry => inquiry.contractId === contractId);
  },

  fetch(context) {
    return InquiryApiService.download().then(response =>
      handleApiResponse(context, response)
    );
  },

  fetchByIdAndVersion(context, { id, version }) {
    const params = {
      id: id,
      version: version,
    };
    console.log(params);
    return InquiryApiService.downloadByIdAndVersion(params).then(response =>
      handleApiResponse(context, response)
    );
  },

  reset({ commit }) {
    commit(types.RESET_INQUIRY);
  },
};

/**
 * Mutations
 */
export const mutations = {
  [types.ADD_INQUIRY](state, inquiry) {
    state.all.push(inquiry);
  },

  [types.RESET_INQUIRY](state) {
    state.all = [];
  },
};
