import { apiClient } from "./ApiClient";

export default {
  login(credentialsParams) {
    return apiClient.sharedInstance().post("login", credentialsParams);
  },
  logout(userParams) {
    return apiClient.sharedInstance().post("logout", null, {
      params: userParams,
    });
  },
};
