import { apiClient } from "./ApiClient";

export default {
  download() {
    return apiClient.sharedInstance().get("inquiries");
  },
  downloadByIdAndVersion(params) {
    return apiClient
      .sharedInstance()
      .get(`inquiries/${params.id}/${params.version}`);
  },
};
