/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import ImageApiService from "@/services/ImageApiService";
import store from "@/store/store";
import LogRocket from "logrocket";
import * as types from "../mutation-types";

const md5 = require("md5");
const orderBy = require("lodash/orderBy");

export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

// Image Helpers
function b64toBlob(b64Data, contentType, sliceSize) {
  const localContentType = contentType || "";
  const localSliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (
    let offset = 0;
    offset < byteCharacters.length;
    offset += localSliceSize
  ) {
    const slice = byteCharacters.slice(offset, offset + localSliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {
    type: localContentType,
  });
  return blob;
}

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  const dataView = new DataView(arrayBuffer);
  const blob = new Blob([dataView], {
    type: mimeString,
  });
  return blob;
}

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  all: [],
};

/*
 * Getters
 */
export const getters = {
  all: state => orderBy(state.all, ["syncStatus"], ["asc"]),
  pending: state =>
    state.all.filter(image => image.syncStatus !== SYNC_STATUS.SYNCED),
  getByFileName: state => fileName => {
    return state.all.find(image => image.name === fileName);
  },
};

/**
 * Actions
 */
export const actions = {
  /**
   *
   * @param {*} _
   * @param {*} param1
   */
  async generateId(_, { contractId }) {
    return Promise.resolve().then(() => {
      // Get current user
      const user = store.getters["user/current"];
      if (!user) throw new Error("Creating response failed, user not found");

      const timestamp = new Date().getTime();
      const usernameHash = md5(user.username);

      return `${contractId}-${usernameHash.substring(0, 10)}-${timestamp}`;
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  async add({ commit }, { image, responseId, fileName }) {
    console.log("[image.js] Adding image to database …");
    return Promise.resolve()
      .then(async () => {
        console.log(`[image.js] ADD_IMAGE called for responseId ${responseId}`);
        const user = store.getters["user/current"];
        if (!user) throw new Error("Creating inquiry failed, user not found");

        // Extract image type and size
        const block = image.split(";");
        const contentType = block[0].split(":")[1];
        const realData = block[1].split(",")[1];
        const blob = b64toBlob(realData, contentType);

        // Generate a unique ID for the new image
        const timestamp = new Date().getTime();
        const usernameHash = md5(timestamp);
        const uniqueId = `${usernameHash.substring(0, 10)}-${timestamp}`;

        const newImage = {
          id: uniqueId,
          userId: user.id.toString(),
          responseId,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          uploadedAt: null,
          syncStatus: SYNC_STATUS.PENDING,
          name: fileName,
          src: image,
          size: blob.size,
          uploadId: null,
          uploadCurrentRange: null,
          url: null,
        };

        console.log("[image.js] ADD_IMAGE generate image", newImage);

        return newImage;
      })
      .then(newImage => commit(types.ADD_IMAGE, newImage))
      .then(() => {
        console.log("[image.js] Added image to database and vuex");
      });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  async set({ commit }, { image }) {
    return Promise.resolve().then(() => {
      commit(types.UPDATE_IMAGE, {
        id: image.id,
        payload: {
          uploadedAt: new Date().toISOString(),
        },
      });
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  async setImageUrl({ commit, dispatch }, { image }) {
    console.log("[ImageUtil] 🚗 Updating image url in image… ", image);
    const url = `https://storage.googleapis.com/${process.env.VUE_APP_GCS_BUCKET}/uploads/${image.name}`;

    commit(types.UPDATE_IMAGE, {
      id: image.id,
      payload: {
        uploadedAt: new Date().toISOString(),
        syncStatus: SYNC_STATUS.SYNCED,
        url: url,
      },
    });

    await dispatch(
      "response/updateImageUrlInResponse",
      { image: image },
      { root: true }
    );
    console.log("[ImageUtil] 🚗 Updated image url in image", image);
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  setSyncState({ commit }, { object, syncStatus }) {
    commit(types.UPDATE_IMAGE, {
      id: object.id,
      payload: {
        syncStatus: syncStatus,
        updatedAt: new Date().toISOString(),
      },
    });

    if (syncStatus === SYNC_STATUS.SYNCED) {
      commit(types.UPDATE_IMAGE, {
        id: object.id,
        payload: {
          uploadedAt: new Date().toISOString(),
        },
      });
    }
  },
  /**
   *
   */
  async delete({ commit, state }, { fileName }) {
    return Promise.resolve().then(() => {
      const index = state.all.findIndex(element => element.name === fileName);
      if (index === -1)
        throw Error(`Image with fileName ${fileName} not found in store`);

      commit(types.DELETE_IMAGE, index);
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  async deleteByResponse({ state, dispatch }, { responseId }) {
    if (!responseId || responseId == 0) {
      console.warn("Response ID must not be empty or 0, aborting deletion.");
      return Promise.resolve();
    }
    return Promise.resolve().then(() => {
      const images = state.all.filter(
        i => i.responseId === responseId && i.syncStatus === SYNC_STATUS.SYNCED
      );
      console.log(
        `[image.js] ⌛️ deleteByResponse with responseId ${responseId}`,
        images
      );

      if (!images || images.length === 0) {
        return Promise.resolve();
      }

      Promise.all(
        images.map(async image => {
          try {
            dispatch("delete", {
              fileName: image.name,
            }).then(() => Promise.resolve());
          } catch (error) {
            return Promise.reject(error);
          }
        })
      );
    });
  },

  /**
   * API
   */
  downloadUploadId({ commit }, { image }) {
    const localImage = image;

    // Check if local image is Blob or DataURI
    let localSource;
    if (localImage.src instanceof Blob) {
      localSource = localImage.src;
    } else {
      localSource = dataURItoBlob(localImage.src);
    }

    const params = {
      contentSize: localImage.contentSize,
      imageSize: localSource.size,
      imageType: localSource.type,
      fileName: localImage.name,
    };

    return ImageApiService.downloadUploadId(params)
      .then(response => {
        if (!response.ok) {
          throw Error("Error getting response. Status: ", response.status);
        }

        if (!response.headers.get("Location")) {
          throw Error("Error getting upload id from request");
        }

        // Split upload id from Google response
        const locationHeader = response.headers.get("Location");
        const responseSplit = locationHeader.split("&");
        const uploadId = responseSplit[3].substring(10);
        return uploadId;
      })
      .then(uploadId => {
        // Save upload id in corresponding image image
        commit(types.UPDATE_IMAGE, {
          id: localImage.id,
          payload: {
            uploadId: uploadId,
          },
        });
        return uploadId;
      });
  },
  uploadImagePart(
    { commit, dispatch },
    { image, isResuming = false, chunkSize = 262144 }
  ) {
    return new Promise((resolve, reject) => {
      let localImage = image;
      console.log("[ImageUtil] Uploading image part…", localImage);

      // Check if local image is Blob or DataURI
      let localSource;
      if (!localImage.src) {
        throw Error("No source for image found");
      } else if (localImage.src instanceof Blob) {
        localSource = localImage.src;
      } else {
        localSource = dataURItoBlob(localImage.src);
      }

      // Init the current upload content range if the image has not been uploaded before
      if (!localImage.uploadCurrentRange) {
        commit(types.UPDATE_IMAGE, {
          id: localImage.id,
          payload: {
            uploadCurrentRange: 0,
          },
        });
      }

      // Set the lower and upper value of the range, e.g. 0/2612144 for the first chunk
      let localChunkSize = chunkSize;
      const currentContentRange = localImage.uploadCurrentRange;
      let totalContentRange = currentContentRange + localChunkSize;

      // Check if we're going to upload the last chunk of the current image.
      // If adding the default chunk size of 256 KB exceeds the total image size,
      // we detected the last part. Instead of the default 256 KB chunk size
      // we go ahead and calculcate the diff between the current range and the total image size.
      if (totalContentRange > localSource.size) {
        localChunkSize = localSource.size - currentContentRange;
        totalContentRange = localSource.size - 1;
      }
      // Save size of image to show progress in sync view
      localImage.size = localSource.size;

      // Set the content-range header value. If the previous upload failed, we use a
      // wildcard for the current chunk size. Google will then respond with the current
      // content range.
      const localRangeHeader = `bytes ${currentContentRange}-${totalContentRange}/${localSource.size}`;
      const unknownRangeHeader = `bytes */${localSource.size}`;

      console.log(
        `Read bytes: ${localImage.uploadCurrentRange}/${totalContentRange} of ${localSource.size} byte image`
      );

      // Get the blob for the content range by slicing the current image
      let blob = null;

      try {
        blob = localSource.slice(
          localImage.uploadCurrentRange,
          totalContentRange + 1,
          localSource.type
        );
      } catch (e) {
        console.error("Error slicing image`, error.stack || error");
        throw e;
      }

      const params = {
        imageType: localSource.type,
        isResuming,
        chunkSize: localChunkSize,
        unknownRangeHeader,
        rangeHeader: localRangeHeader,
        imageBlob: blob,
        uploadId: localImage.uploadId,
      };

      ImageApiService.uploadImageChunk(params)
        .then(async response => {
          blob = null;

          if (response.status === 308) {
            // Chunk has been uploaded, object not fully uploaded yet
            console.log("Code 308 received");

            // Save the received content-range to make sure that the upload
            // of the next chunk starts at the correct bytes
            const currentRangeHeader = response.headers.get("Range");
            let uploadCurrentRange = localImage.uploadCurrentRange;
            if (currentRangeHeader) {
              const responseSplit = currentRangeHeader.split("-");
              uploadCurrentRange = parseInt(responseSplit[1], 10);
            }

            commit(types.UPDATE_IMAGE, {
              id: localImage.id,
              payload: {
                uploadCurrentRange: uploadCurrentRange,
              },
            });

            resolve(
              dispatch("uploadImagePart", {
                image: localImage,
                isResuming: false,
              })
            );
          } else if (
            response.status === 400 ||
            response.status === 404 ||
            response.status === 410 ||
            response.status === 503
          ) {
            // The uploaded chunk was not correct. Reset the content range and try again.
            console.log(`Code ${response.status} received`);

            commit(types.UPDATE_IMAGE, {
              id: localImage.id,
              payload: {
                uploadCurrentRange: null,
                uploadId: null,
              },
            });

            localImage = await dispatch("downloadUploadId", {
              image: localImage,
            });
            resolve(
              dispatch("uploadImagePart", {
                image: localImage,
                isResuming: false,
              })
            );
          } else if (response.status === 200 || response.status === 201) {
            // Chunk has been uploaded and the image has been fully uploaded
            console.log("Code 200 received, finished uploading image parts");

            // Save the uploaded file size to display the correct status
            commit(types.UPDATE_IMAGE, {
              id: localImage.id,
              payload: {
                uploadCurrentRange: localSource.size,
              },
            });

            await dispatch("setImageUrl", {
              image: localImage,
            });
            resolve();
          }
        })
        .catch(error => {
          console.error(
            "Error while uploading image part",
            (error && error.stack) || error
          );
          console.error("Error name", error.name);

          if (error.response) {
            // client received an error response (5xx, 4xx)
            console.error(
              "Client received an error response from the server",
              error.response.status ? error.response.status : error.response
            );
          } else if (error.request) {
            // client never received a response, or request never left
            // e.g. spotty network, back end server down, CORS error
            console.error(
              "Client never received a response, or request never left"
            );
          } else {
            // anything else
            console.error("Client ran into an unknown error");
          }

          // Reset the content range and upload id of the image and try again
          if (!error.response) {
            commit(types.UPDATE_IMAGE, {
              id: localImage.id,
              payload: {
                uploadCurrentRange: null,
                uploadId: null,
              },
            });
          }
          LogRocket.captureException(error);
          reject(error);
        });
    });
  },

  /**
   * Sync
   */
  uploadPendingImages({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      console.log("[ImageUtil] ⌛️  uploadPendingImages");
      const images = state.all.filter(
        image => image.syncStatus !== SYNC_STATUS.SYNCED
      );

      new Promise(resolve => resolve(images))
        .then(async images => {
          console.log("[ImageUtil] ⌛️  uploadPendingImages, images", images);
          return images.reduce(
            (sequence, image) =>
              sequence.then(() =>
                dispatch("performUploadForImage", { image: image })
              ),
            Promise.resolve()
          );
        })
        .then(() => {
          console.log("[ImageUtil] Success in uploadPendingImages");
          resolve();
        })
        .catch(error => {
          console.error("[ImageUtil] 🛑  Error in uploadPendingImages", error);
          reject(error);
        });
    });
  },
  performUploadForImage({ commit, dispatch }, { image }) {
    return new Promise((resolve, reject) => {
      console.log("[SyncUtil] ⌛️  uploadImage", image);
      new Promise(resolve => {
        commit(types.UPDATE_IMAGE, {
          id: image.id,
          payload: {
            syncStatus: SYNC_STATUS.SYNCING,
          },
        });
        resolve(image);
      })
        .then(async image => {
          // Request upload id before uploading image parts
          if (image.uploadId === null) {
            await dispatch("downloadUploadId", { image });
          }
          return image;
        })
        .then(image =>
          dispatch("uploadImagePart", {
            image: image,
            isResuming: false,
          })
        )
        .then(() =>
          commit(types.UPDATE_IMAGE, {
            id: image.id,
            payload: {
              syncStatus: image.url ? SYNC_STATUS.SYNCED : SYNC_STATUS.PENDING,
            },
          })
        )
        .then(localImage => resolve(localImage))
        .catch(async error => {
          // Reset sync state
          commit(types.UPDATE_IMAGE, {
            id: image.id,
            payload: {
              syncStatus: SYNC_STATUS.PENDING,
            },
          });
          reject(error);
        });
    });
  },
  uploadPendingImagesForResponse({ state, dispatch }, { response }) {
    return new Promise((resolve, reject) => {
      console.log("[ImageUtil] ⌛️  uploadPendingImagesForResponse", response);
      const images = state.all.filter(
        image =>
          image.responseId === response.id &&
          image.syncStatus !== SYNC_STATUS.SYNCED
      );
      return new Promise(resolve => resolve(images))
        .then(async images => {
          console.log(
            "[ImageUtil] ⌛️  uploadPendingImagesForResponse, images",
            images
          );
          if (!images || (images && images.length === 0)) {
            console.log("[ImageUtil] No pending images for response found");
            Promise.resolve();
          }
          return images.reduce(
            (sequence, image) =>
              sequence.then(() => dispatch("performUploadForImage", { image })),
            Promise.resolve()
          );
        })
        .then(() => {
          console.log("[ImageUtil] Success in uploadPendingImagesForResponse");
          resolve();
        })
        .catch(error => {
          console.error(
            "[ImageUtil] 🛑  Error in uploadPendingImagesForResponse",
            error
          );
          reject(error);
        });
    });
  },

  reset({ commit }) {
    commit(types.RESET_IMAGE);
  },
};

/**
 * Mutations
 */
export const mutations = {
  [types.ADD_IMAGE](state, image) {
    state.all.push(image);
  },

  [types.UPDATE_IMAGE](state, { id, payload }) {
    const index = state.all.findIndex(element => element.id === id);
    if (index === -1) throw Error(`Response with id ${id} not found in store`);

    let image = state.all.find(_job => _job.id === id);
    image = Object.assign(image, payload);

    state.all[index] = image;
  },

  [types.DELETE_IMAGE](state, index) {
    state.all.splice(index, 1);
  },

  [types.RESET_IMAGE](state) {
    state.all = [];
  },
};
