import UserApiService from "@/services/UserApiService.js";
import * as types from "../mutation-types";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  // Current logged in user
  current: null,
};

/**
 * Getters
 */
export const getters = {
  current: state => state.current,
  isAdmin: state =>
    state.current &&
    state.current.username === process.env.VUE_APP_ADMIN_USERNAME.toString(),
};

/**
 * Mutations
 */
export const mutations = {
  /**
   * Set the current user
   */
  [types.SET_USER](state, user) {
    if (user) {
      state.current = {
        createdAt: new Date().toISOString(),
        auth: user.api_token,
        id: user.id,
        username: user.name,
      };
    } else {
      state.current = null;
    }
  },
  /**
   * Reset the current user
   */
  [types.RESET_USER](state) {
    state.current = null;
  },
};

/**
 * Actions
 */
export const actions = {
  /**
   * Fetch user from "user/login" API endpoint and set current user
   */
  login({ commit }, { username, password }) {
    return UserApiService.login({
      name: username,
      password,
    })
      .then(response => {
        if (!response || !response.data || !response.data.data) {
          throw new Error("Username and password did not match");
        }
        return response;
      })
      .then(response => {
        const user = response.data.data;
        commit(types.SET_USER, user);
      })
      .catch(error => {
        throw error;
      });
  },
  /**
   * Log current user out and wipe all data
   */
  async logout({ commit, dispatch }) {
    return Promise.resolve()
      .then(() =>
        dispatch("inquiry/reset", null, {
          root: true,
        })
      )
      .then(() =>
        dispatch("job/reset", null, {
          root: true,
        })
      )
      .then(() =>
        dispatch("response/reset", null, {
          root: true,
        })
      )
      .then(() =>
        dispatch("image/reset", null, {
          root: true,
        })
      )
      .then(() => {
        commit(types.RESET_USER);
      });
  },
  /**
   * Log current user out after session become invalid
   * without wiping all data
   */
  async logoutWithoutReset({ commit }) {
    return Promise.resolve().then(() => {
      commit(types.SET_USER, null);
    });
  },
};
