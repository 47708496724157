<template>
  <div
    class="ui grouped fields"
    :class="{
      disabled: response.editStatus > 0 && !userIsAdmin,
      required: isFieldRequired(field),
    }"
  >
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">{{ field.sectionDescription }}</p>
    <div
      class="ui field grouped fields"
      :class="{
        disabled: response.editStatus > 0 && !userIsAdmin,
        required: isFieldRequired(field),
      }"
      :required="isFieldRequired(field)"
    >
      <label v-if="field.headingTitle">{{ field.headingTitle }}</label>
      <p v-if="field.headingDescription">{{ field.headingDescription }}</p>
      <div
        class="ui input"
        :class="{
          disabled:
            (response.editStatus > 0 && !userIsAdmin) ||
            field.readonly === true,
          required: isFieldRequired(field),
        }"
        :required="isFieldRequired(field)"
      >
        <input
          type="text"
          :pattern="setRegExPattern(field)"
          name="description"
          placeholder="Text eintragen…"
          :value="descriptionValue"
          :required="isFieldRequired(field)"
          @blur="setDescriptionText(field, $event)"
          :readonly="field.readonly"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  isDescriptionRequired,
  isFieldRequired,
  setDescriptionText,
} from "@/lib/FormUtil";

export default {
  name: "FormOpenEndedSingle",
  props: ["field", "inquiry", "job", "response", "userIsAdmin"],
  asyncComputed: {
    /* eslint-disable */
    descriptionValue: {
      get() {
        const field = this.response.fields.find(f => f.id === this.field.id);
        if (!field) {
          return null;
        }

        return field.text;
      },
      watch() {
        this.response;
      },
    },
    /* eslint-enable */
  },
  methods: {
    isFieldRequired(field) {
      return isFieldRequired(field);
    },
    isDescriptionRequired(field) {
      return isDescriptionRequired(field);
    },
    setDescriptionText(field, event) {
      setDescriptionText(field.id, this.response, event.target.value);
    },
    setRegExPattern(field) {
      if (field.minLength && field.maxLength) {
        return `^.{${field.minLength},${field.maxLength}}$.?`;
      } else if (field.minLength && !field.maxLength) {
        return `^.{${field.minLength},}$.?`;
      } else if (!field.minLength && field.maxLength) {
        return `^.{0,${field.maxLength}}$.?`;
      } else {
        return "^.+$";
      }
    },
  },
};
</script>
