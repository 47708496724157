<template>
  <main class="ui container">
    <div class="ui active inverted dimmer" v-if="loading">
      <div class="ui active text loader">Lade Auftrag …</div>
    </div>
    <div v-if="userIsAdmin" class="ui yellow message">
      <div class="header">Änderungen im Admin-Modus</div>
      <p>
        Jede Änderung überschreibt die bisherigen Angaben mit sofortiger Wirkung
        und kann nicht rückgängig gemacht werden.
      </p>
      <p>
        Bitte bestätigen Sie Ihre Änderungen im Anschluss mit „Änderungen
        speichern”.
      </p>
    </div>
    <div v-else-if="response.editStatus === 0">
      <JobEditHeader :job="job" />

      <div class="ui blue message" style="margin-bottom: 25px;">
        <p>
        Bitte erfassen Sie alle erforderlichen Felder und bestätigen Ihre
        Angaben mit „Auftrag abschließen”.
        </p>
      </div>
    </div>
    <div v-else-if="response.editStatus === 1" class="ui green message">
      <div class="header">Auftrag ist bereits abgeschlossen</div>
      <p>
        Nach dem Abschluss eines Auftrages ist keine weitere Bearbeitung mehr
        möglich.
      </p>
    </div>

    <form
      class="ui form"
      v-on:submit.prevent="showConfirmResponseModal"
      v-bind:class="{ disabled: response.editStatus > 0 && !userIsAdmin }"
    >
      <template v-for="field in inquiry.fields">
        <div v-bind="field" v-bind:key="field.id">
          <!-- Type: single_value // Subtype: vertical -->
          <FormSingleValueVertical
            v-if="
              field.family === 'single_value' &&
              field.subtype === 'vertical' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormSingleValueVertical>

          <!-- Type: single_value // Subtype: yes_no_comment -->
          <FormSingleValueYesNoComment
            v-else-if="
              field.family === 'single_value' &&
              field.subtype === 'yes_no_comment' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormSingleValueYesNoComment>

          <!-- Type: single_value // Subtype: yes_no_empty -->
          <FormSingleValueYesNoEmpty
            v-else-if="
              field.family === 'single_value' &&
              field.subtype === 'yes_no_empty' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormSingleValueYesNoEmpty>

          <!-- Type: single_value // Subtype: dropdown -->
          <FormDropdown
            v-else-if="
              field.family === 'single_value' &&
              field.subtype === 'dropdown' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormDropdown>

          <!-- Type: multiple_value // Subtype: vertical -->
          <FormMultipleValueVertical
            v-else-if="
              field.family === 'multiple_value' &&
              field.subtype === 'vertical' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormMultipleValueVertical>

          <!-- Type: open_ended // Subtype: single -->
          <FormOpenEndedSingle
            v-else-if="
              field.family === 'open_ended' &&
              field.subtype === 'single' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormOpenEndedSingle>

          <!-- Type: open_ended // Subtype: numerical -->
          <FormOpenEndedNumerical
            v-else-if="
              field.family === 'open_ended' &&
              field.subtype === 'numerical' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormOpenEndedNumerical>

          <!-- Type: open_ended // Subtype: calculated -->
          <FormOpenEndedCalculated
            v-else-if="
              field.family === 'open_ended' &&
              field.subtype === 'calculated' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormOpenEndedCalculated>

          <!-- Type: open_ended // Subtype: multi -->
          <FormOpenEndedMulti
            v-else-if="
              field.family === 'open_ended' &&
              field.subtype === 'multi' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormOpenEndedMulti>

          <!-- Type: presentation // Subtype: descriptive_text -->
          <FormPresentationDescriptiveText
            v-else-if="
              field.family === 'presentation' &&
              field.subtype === 'descriptive_text' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormPresentationDescriptiveText>

          <!-- Type: presentation // Subtype: alert -->
          <FormPresentationAlert
            v-else-if="
              field.family === 'presentation' &&
              field.subtype === 'warning' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormPresentationAlert>

          <!-- Type: file // Subtype: signature -->
          <FormFileSignature
            v-else-if="
              field.family === 'file' &&
              field.subtype === 'signature' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormFileSignature>

          <!-- Type: file // Subtype: capture -->
          <FormFileCaptureImage
            v-else-if="
              field.family === 'file' &&
              field.subtype === 'capture_image' &&
              isOptionVisible(field)
            "
            :field="field"
            :inquiry="inquiry"
            :job="job"
            :response="response"
            :userIsAdmin="userIsAdmin"
          ></FormFileCaptureImage>
        </div>
      </template>

      <button
        v-if="userIsAdmin"
        type="submit"
        class="fluid ui left labeled icon green button huge btn-submit"
        style="padding-left: 3.5em !important"
      >
        <i class="left save icon"></i>Änderungen speichern
      </button>
      <button
        v-else
        type="submit"
        class="fluid ui left labeled icon green button huge btn-submit"
        v-bind:class="{ disabled: response.editStatus > 0 }"
        style="padding-left: 3.5em !important"
      >
        <i class="left checkmark icon"></i>Auftrag abschließen
      </button>
    </form>

    <ModalFileSignaturePad :response="response" />

    <ModalImageCaptureDelete
      :inquiry="inquiry"
      :job="job"
      :response="response"
      :userIsAdmin="userIsAdmin"
      :isDeletePhotoModalShowing.sync="isDeletePhotoModalShowing"
      :isDeletePhotoModalConfirmed.sync="isDeletePhotoModalConfirmed"
      :photoToDeleteField="photoToDeleteField"
    />

    <ModalImageViewer
      :isViewPhotoModalShowing.sync="isViewPhotoModalShowing"
      :isViewPhotoModalConfirmed.sync="isViewPhotoModalConfirmed"
      :imageSrc="photoToViewSrc"
    />

    <ModalJobComplete
      :inquiry="inquiry"
      :job="job"
      :response="response"
      :userIsAdmin="userIsAdmin"
      :isCompleteJobModalShowing.sync="isCompleteJobModalShowing"
      :isCompleteJobModalConfirmed.sync="isCompleteJobModalConfirmed"
    />
  </main>
</template>

<script>
import { isOptionVisible } from "@/lib/FormUtil";
import { EventBus } from "@/event-bus";

import JobListItem from "./../components/JobListItem";
import ModalJobComplete from "./../components/modal/ModalJobComplete";
import ModalImageCaptureDelete from "./../components/modal/ModalImageCaptureDelete";
import ModalImageViewer from "./../components/modal/ModalImageViewer";
import ModalFileSignaturePad from "./../components/modal/ModalFileSignaturePad";
import FormDropdown from "./../components/form/FormDropdown";
import FormSingleValueVertical from "./../components/form/FormSingleValueVertical";
import FormSingleValueYesNoComment from "./../components/form/FormSingleValueYesNoComment";
import FormSingleValueYesNoEmpty from "./../components/form/FormSingleValueYesNoEmpty";
import FormMultipleValueVertical from "./../components/form/FormMultipleValueVertical";
import FormOpenEndedNumerical from "./../components/form/FormOpenEndedNumerical";
import FormOpenEndedCalculated from "./../components/form/FormOpenEndedCalculated";
import FormOpenEndedSingle from "./../components/form/FormOpenEndedSingle";
import FormOpenEndedMulti from "./../components/form/FormOpenEndedMulti";
import FormPresentationDescriptiveText from "./../components/form/FormPresentationDescriptiveText";
import FormPresentationAlert from "./../components/form/FormPresentationAlert";
import FormFileSignature from "./../components/form/FormFileSignature";
import FormFileCaptureImage from "./../components/form/FormFileCaptureImage";
import JobEditHeader from "./../components/JobEditHeader";

export const EDIT_STATUS = Object.freeze({
  ASSIGNED: 0,
  EDITING: 1,
  COMPLETED: 2,
  SENT: 3,
});
export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

export default {
  name: "JobForm",
  props: ["job", "inquiry", "response", "userIsAdmin"],
  components: {
    ModalJobComplete,
    ModalImageCaptureDelete,
    ModalImageViewer,
    ModalFileSignaturePad,
    FormDropdown,
    FormSingleValueVertical,
    FormSingleValueYesNoComment,
    FormSingleValueYesNoEmpty,
    FormMultipleValueVertical,
    FormOpenEndedSingle,
    FormOpenEndedNumerical,
    FormOpenEndedCalculated,
    FormOpenEndedMulti,
    FormPresentationDescriptiveText,
    FormPresentationAlert,
    FormFileSignature,
    FormFileCaptureImage,
    JobEditHeader
},
  data() {
    return {
      loading: false,
      error: null,
      photoToDeleteField: null,
      photoToViewSrc: null,
      isDeletePhotoModalShowing: false,
      isDeletePhotoModalConfirmed: true,
      isViewPhotoModalShowing: false,
      isViewPhotoModalConfirmed: true,
      isCompleteJobModalShowing: false,
      isCompleteJobModalConfirmed: true,
      isConfirmLeavingViewModalShowing: false,
      isConfirmLeavingViewModalConfirmed: true,
    };
  },
  created() {
    this.loading = true;
  },
  mounted() {
    this.loading = false;

    EventBus.$on("SHOW_DELETEPHOTO_MODAL", field => {
      this.isDeletePhotoModalShowing = true;
      this.isDeletePhotoModalConfirmed = false;
      this.photoToDeleteField = field;
    });

    EventBus.$on("SHOW_VIEWPHOTO_MODAL", imageSrc => {
      this.isViewPhotoModalShowing = true;
      this.isViewPhotoModalConfirmed = false;
      this.photoToViewSrc = imageSrc;
    });
  },
  methods: {
    isOptionVisible(field) {
      return isOptionVisible(field, this.response);
    },
    showConfirmResponseModal() {
      this.isCompleteJobModalShowing = true;
      this.isCompleteJobModalConfirmed = false;
    },
  },
};
</script>

<style scoped>
/*
 * Form
 **********************/

.ui.form .grouped.fields > label {
  font-size: 1.175rem;
}

h4.ui.header {
  font-size: 1.5rem !important;
}

.span_pre {
  white-space: normal;
  word-wrap: break-word;
  font-family: inherit;
}

.btn-submit {
  margin-top: 2em !important;
}
</style>

<style>
.ui.header,
.ui.header:first-child {
  margin-top: 1.5em !important;
  font-size: 1.5rem !important;
}

.ui.form {
  margin-bottom: 1.5em !important;
}

.ui.form .grouped.fields {
  margin-bottom: 1em !important;
}

.ui.form .grouped.fields > label {
  font-size: 1.175rem;
}

.radio__group {
  margin-bottom: 1em !important;
}
</style>
