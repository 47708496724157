<template>
  <div class="modal__signature">
    <div class="modal__signature-content">
      <div
        class="ui container container__orientation"
        style="text-align: center; padding-top: 5rem"
      >
        <h4>Smartphone für Unterschrift drehen</h4>
        <p>
          Bitte drehen Sie das Smartphone jetzt in das Querformat, um mit der
          Erfassung einer Unterschrift zu beginnen.
        </p>
      </div>
      <div class="ui container segment container__signature">
        <h4>Unterschrift erfassen</h4>
        <p>Zeichen Sie die Unterschrift und bestätigen über „Speichern”.</p>
        <!-- <div
          class="ui group fields"
          style="width: 100%; margin: 0; height: 44px"
        >
          <div class="ui left floated grey button" @click="hideSignatureModal">
            <i class="close icon"></i>
            Abbrechen
          </div>
          <div
            class="ui left floated secondary button basic"
            @click="clearSignaturCanvas"
          >
            <i class="erase icon"></i>
            Leeren
          </div>
        </div> -->
        <div class="ui fields">
          <div class="modal__signature-pad">
            <canvas width="450" height="180"></canvas>
          </div>
        </div>

        <div class="ui group fields" style="width: 100%; margin-top: 15px">
          <div class="ui left floated primary button" @click="saveSignatur">
            <i class="save icon"></i>
            Speichern
          </div>
          <div class="ui left floated grey button" @click="hideSignatureModal">
            <i class="close icon"></i>
            Abbrechen
          </div>
          <div
            class="ui left floated secondary icon button basic"
            @click="clearSignaturCanvas"
          >
            <i class="erase icon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

import SignaturePad from "signature_pad";

let modalSignature = null;
let signatureCanvas = null;
let signaturePad = null;

export default {
  name: "ModalFileSignaturePad",
  props: ["isDeleteSignatureModalShowing", "isDeleteSignatureModalConfirmed"],
  data() {
    return { field: null, inquiry: null };
  },
  created() {
    EventBus.$on("SHOW_SIGNATUREPAD_MODAL", context =>
      this.showSignatureModal(context)
    );
  },
  mounted() {
    modalSignature = document.querySelector(`.modal__signature`);
    signatureCanvas = document.querySelector(`canvas`);

    signaturePad = new SignaturePad(signatureCanvas, {
      backgroundColor: "rgb(255, 255, 255)",
    });
    signaturePad.clear();
  },
  destroyed() {
    document.body.classList.remove("modal-active");
  },
  methods: {
    showSignatureModal(context) {
      this.field = context.field;
      this.response = context.response;
      this.job = context.job;

      modalSignature.style.width = "100%";
      signaturePad.clear();
      document.body.classList.add("modal-active");
    },
    hideSignatureModal() {
      modalSignature.style.width = "0%";
      document.body.classList.remove("modal-active");
    },
    clearSignaturCanvas() {
      signaturePad.clear();
    },
    toggleImageLoadingSpinner(isLoading) {
      return Promise.resolve().then(() => {
        const container = document.querySelector(`.container__signature`);
        if (isLoading) {
          container.classList += " loading";
        } else {
          container.classList = "ui container segment container__signature";
        }
        return true;
      });
    },
    convertSignatureImageToDataUrl() {
      return Promise.resolve()
        .then(() => {
          const dataUrl = signaturePad.toDataURL();
          if (!dataUrl) {
            throw Error("Transforming image failed: No image source available");
          }
          return dataUrl;
        })
        .catch(error => {
          console.error(error.stack || error);
          throw error;
        });
    },
    async saveSignatureImage(imageBase64) {
      const fileName = await this.$store.dispatch("image/generateId", {
        contractId: this.job.jobNumber,
      });

      return this.$store
        .dispatch("image/add", {
          image: imageBase64,
          responseId: this.response.id,
          fileName,
        })
        .then(() =>
          this.$store.dispatch("response/setFieldFileName", {
            id: this.response.id,
            field: {
              id: this.field.id,
              fileName,
            },
          })
        );
    },
    showErrorMessage(error) {
      this.toggleImageLoadingSpinner(this.field, false);
      this.imageError = error;
    },
    async saveSignatur() {
      this.toggleImageLoadingSpinner(true)
        .then(() => this.convertSignatureImageToDataUrl())
        .then(imageBase64 => this.saveSignatureImage(imageBase64))
        .then(() => this.hideSignatureModal())
        .catch(error => {
          console.error(error.stack || error);
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.toggleImageLoadingSpinner(false);
        });
    },
  },
};
</script>

<style scoped>
.modal__signature {
  position: fixed;
  margin: 0 auto !important;
  top: 0;
  left: 0;
  height: 100vh;
  width: 0%;
  z-index: 102;
  transition: 0.3s;
  background-color: #f6f9fc;
  overflow: hidden;
}
.container__signature {
  width: 100vw;
  max-width: 450px !important;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
}

.modal__signature-content {
  padding-top: 15px;
  padding-bottom: 20px;
  height: 100vh;
}
.modal__signature-content h4 {
  margin-bottom: 5px;
}

.modal__signature-pad {
  width: 90vw;
  max-width: 450px;
  height: 180px;
  margin-top: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem !important;
  overflow: hidden;
}
.modal__signature-pad canvas {
  width: 90vw;
  max-width: 450px;
  height: 180px;
}

.segment {
  padding: 0;
  margin: 0;
}

@media screen and (orientation: portrait) {
  .container__orientation {
    display: none !important;
  }
  .container__signature {
    display: block !important;
  }
}

@media screen and (orientation: landscape) {
  .container__orientation {
    display: none !important;
  }
  .container__signature {
    display: block !important;
  }
}
</style>
