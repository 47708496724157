<template>
  <div class="ui container container--list">
    <template v-for="section in jobs">
      <h5 class="ui gray header--section" :key="section.id">
        {{ $moment(section.dueAtFormatted).format("dddd, Do MMMM YYYY") }}
      </h5>
      <template v-for="job in section.jobs">
        <JobListItem :job="job" :inquiries="inquiries" :key="job.id" />
      </template>
    </template>
  </div>
</template>

<script>
import JobListItem from "@/components/JobListItem";

export default {
  name: "JobList",
  props: ["jobs", "inquiries"],
  components: {
    JobListItem,
  },
};
</script>

<style>
.container--list {
  padding-bottom: 50px;
}

.header--section {
  color: gray;
}

.ui.card:first-child {
  margin-top: 1em;
}
</style>
