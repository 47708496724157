<template>
  <div class="container__edit">
    <nav class="ui top fixed menu two column grid wide">
      <div class="column menu-title__column">
        <span class="menu-title" style="width: 230px">
          Anleitung
          <br />
          <small class="menu-subtitle">App dem Home-Screen hinzufügen</small>
        </span>
      </div>
      <div class="column menu__add" @click="closeManual">
        <button
          class="ui icon button menu__close-button right floated inverted"
        >
          <i class="remove icon"></i>
        </button>
      </div>
    </nav>

    <div
      class="ui container"
      style="padding-top: 2em; padding-bottom: 2em; background: transparent"
    >
      <div v-if="browser.isIos">
        <h3>App dem Home-Screen hinzufügen</h3>
        <div style="padding-bottom: 25px">
          <p>
            1. Wählen Sie die <strong>„Teilen”-Option</strong> im Safari-Browser
            aus
          </p>
          <img class="ui image" src="@/assets/manual/ios-home-01.jpg" />
        </div>
        <div style="padding-bottom: 25px">
          <p>
            2. Wählen Sie die Option <strong>„Zum Home-Bildschirm”</strong> aus
          </p>
          <img class="ui image" src="@/assets/manual/ios-home-02.jpg" />
        </div>
        <div style="padding-bottom: 25px">
          <p>3. Wählen Sie <strong>„Hinzufügen”</strong> aus</p>
          <img class="ui image" src="@/assets/manual/ios-home-03.jpg" />
        </div>
        <div>
          <p>
            4. Öffnen Sie die <strong>Survello-App</strong> vom Home-Bildschirm
            aus
          </p>
          <img class="ui image" src="@/assets/manual/ios-home-04.jpg" />
        </div>
      </div>
      <div v-else>
        <h3>App dem Home-Screen hinzufügen</h3>
        <div style="padding-bottom: 25px">
          <p>
            1. Öffnen Sie das <strong>Menü über die drei Punkte</strong> im
            Browser aus
          </p>
          <img class="ui image" src="@/assets/manual/android-home-01.jpg" />
        </div>
        <div style="padding-bottom: 25px">
          <p>
            2. Wählen Sie die Option <strong>„App installieren”</strong> aus
          </p>
          <img class="ui image" src="@/assets/manual/android-home-02.jpg" />
        </div>
        <div style="padding-bottom: 25px">
          <p>
            3. Wählen Sie <strong>„Installieren”</strong> aus. Die Installation
            kann je nach Internetverbindung einen Moment dauern und wird über
            eine Benachrichtigung bestätigt.
          </p>
          <img class="ui image" src="@/assets/manual/android-home-03.jpg" />
        </div>
        <div>
          <p>
            4. Öffnen Sie die <strong>Survello-App</strong> vom Home-Bildschirm
            aus
          </p>
          <img class="ui image" src="@/assets/manual/android-home-04.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const browserDetection = require("@braintree/browser-detection");

export default {
  name: "HomeScreenManual",
  data() {
    return {
      browser: {
        isIos: false,
        isAndroid: false,
      },
    };
  },
  mounted() {
    this.browser.isIos = browserDetection.isIos();
    this.browser.isAndroid = browserDetection.isAndroid();
  },
  methods: {
    closeManual() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.container__edit {
  background-color: #fff;
}

/*
 * Navigation
 **********************/

nav span {
  padding-top: 9px;
  line-height: 1.4rem;
}

.menu-title {
  width: 100%;
}

.ui.grid > .column:not(.row):not(.menu-title__column) {
  padding-top: 10px !important;
}

.menu-title__column {
  padding-top: 0px !important;
}

.menu-subtitle {
  font-weight: 700;
  font-size: 0.65em !important;
  opacity: 0.54;
}

.menu__close-button {
  background: #ffffff;
}

/*
 * Form
 **********************/

.ui.form .field > label {
  font-size: 1.175rem;
}

.label-small {
  font-size: 0.92857143em !important;
}

.btn-submit {
  margin-top: 32px !important;
}

.btn-photo-remove {
  margin-top: 16px !important;
}

input[type="file"] {
  position: absolute;
  overflow: hidden;
  opacity: 0.01;
  background-color: red;
  max-width: 152px;
  max-height: 36px;
}

.photo-preview__buttons {
  padding-bottom: 29px !important;
}

.ui.segment {
  padding: 0;
  box-shadow: none;
  border: none;
}

.ui.segment > .ui.dimmer {
  z-index: 1;
}
</style>
