<template>
  <div class="container__edit">
    <div class="ui active inverted dimmer" v-if="loading">
      <div class="ui active text loader">Lade Auftrag …</div>
    </div>
    <nav class="ui top fixed menu two column grid wide">
      <div class="column menu-title__column">
        <span class="menu-title">
          Auftrag
          <br />
          <small class="menu-subtitle" v-if="job"
            >Auftrag {{ job.jobNumber }}</small
          >
          <small class="menu-subtitle" v-else-if="response"
            >Auftrag {{ response.jobNumber }}</small
          >
        </span>
      </div>
      <div class="column menu__add" @click="closeJobEditView">
        <button
          class="ui icon button menu__close-button right floated inverted"
        >
          <i class="remove icon"></i>
        </button>
      </div>
    </nav>

    <div
      v-if="error"
      class="ui container"
      style="margin-top: 2em; padding-top: 1em; padding-bottom: 1em"
    >
      <div class="ui red message">
        <div class="header">Fehler beim Laden des Auftrags</div>
        <p>{{ error }}</p>
      </div>

      <button
        class="ui labeled primary icon fluid button"
        @click="closeJobEditView"
      >
        <i class="left arrow icon"></i>
        Zurück zur Auftragsliste
      </button>

      <div
        class="ui message"
        style="margin-top: 2em"
        v-if="this.job && this.job.editStatus > 0 && !this.response"
      >
        <div class="header">Auftrag entfernen</div>
        <p>
          Falls der Auftrag bereits vollständig erfasst und übertragen wurde,
          kann dieser vom Gerät entfernt werden.
        </p>
        <button class="ui labeled icon fluid button" @click="removeJob">
          <i class="trash icon"></i>
          Auftrag entfernen
        </button>
      </div>
    </div>

    <JobForm
      v-if="inquiry && job && response && !loading && !error"
      :inquiry="inquiry"
      :job="job"
      :response="response"
      :userIsAdmin="isUserAdmin"
      :isDeleteSignatureModalShowing.sync="isDeleteSignatureModalShowing"
      :isDeleteSignatureModalConfirmed.sync="isDeleteSignatureModalConfirmed"
    ></JobForm>

    <modal black v-model="isDeletePhotoModalShowing" modalVariation="basic">
      <div slot="header" class="center">
        <i class="trash icon"></i>
        Foto löschen
      </div>
      <div class="content" slot="content">
        <p>Möchten Sie dieses Foto wirklich löschen?</p>
      </div>
      <template slot="actions">
        <div
          class="ui black deny inverted button"
          @click="isDeletePhotoModalShowing = false"
        >
          <i class="remove icon"></i> Nein
        </div>
        <div
          class="ui red right inverted button"
          @click="confirmDeletePhotoModal(this)"
        >
          <i class="checkmark icon"></i> Ja
        </div>
      </template>
    </modal>

    <ModalDeleteSignature
      :inquiry="inquiry"
      :job="job"
      :response="response"
      :isUserAdmin="isUserAdmin"
      :isDeleteSignatureModalShowing.sync="isDeleteSignatureModalShowing"
      :isDeleteSignatureModalConfirmed.sync="isDeleteSignatureModalConfirmed"
    ></ModalDeleteSignature>

    <ModalJobComplete
      :inquiry="inquiry"
      :job="job"
      :response="response"
      :isUserAdmin="isUserAdmin"
      :isCompleteJobModalShowing.sync="isCompleteJobModalShowing"
      :isCompleteJobModalConfirmed.sync="isCompleteJobModalConfirmed"
    ></ModalJobComplete>

    <ModalJobClose
      :isConfirmLeavingViewModalShowing.sync="isConfirmLeavingViewModalShowing"
      :isConfirmLeavingViewModalConfirmed.sync="
        isConfirmLeavingViewModalConfirmed
      "
    ></ModalJobClose>
  </div>
</template>

<script>
import modal from "vue-semantic-modal";
import { mapGetters } from "vuex";
import ModalJobClose from "./../components/modal/ModalJobClose";
import ModalJobComplete from "./../components/modal/ModalJobComplete";
import ModalDeleteSignature from "./../components/modal/ModalDeleteSignature";
import JobForm from "./../components/JobForm";

export default {
  name: "inquiriesEdit",
  components: {
    modal,
    ModalJobClose,
    ModalJobComplete,
    ModalDeleteSignature,
    JobForm,
  },
  data() {
    return {
      loading: true,
      error: null,
      job: null,
      inquiry: null,
      response: null,
      isDeletePhotoModalShowing: false,
      isDeletePhotoModalConfirmed: true,
      isDeleteSignatureModalShowing: false,
      isDeleteSignatureModalConfirmed: true,
      isCompleteJobModalShowing: false,
      isCompleteJobModalConfirmed: false,
      isConfirmLeavingViewModalShowing: false,
      isConfirmLeavingViewModalConfirmed: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    // Show confirmation alert when user taps on the back button
    if (
      this.isConfirmLeavingViewModalConfirmed ||
      (this.response && this.response.editStatus > 0) ||
      this.isUserAdmin ||
      this.error
    ) {
      next();
    } else {
      this.closeJobEditView();
      next(false);
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  computed: {
    ...mapGetters({
      isUserAdmin: "user/isAdmin",
    }),
  },
  methods: {
    fetchData() {
      console.log("fetchData() after route change");
      // Reset existing data
      this.error = null;
      this.job = null;
      this.inquiry = null;
      this.response = null;

      if (this.$route.name === "jobEditAdmin") {
        this.loadDataForAdmin({ id: this.$route.params.id });
      } else {
        this.loadDataForUser(this.$route.params.id);
      }
    },
    async loadDataForAdmin({ id }) {
      try {
        this.loading = true;

        // Download response and assign active response
        await this.$store.dispatch("response/fetchById", { id });
        this.response = this.$store.getters["response/getById"](id);
        if (!this.response)
          throw new Error("Response wurde nicht im lokalen Speicher gefunden");

        // Download inquiry for given job
        await this.$store.dispatch("inquiry/fetchByIdAndVersion", {
          id: this.response.inquiryId,
          version: this.response.inquiryVersion,
        });
        this.inquiry = this.$store.getters["inquiry/getByIdAndVersion"](
          this.response.inquiryId,
          this.response.inquiryVersion
        );
        if (!this.inquiry)
          throw new Error(
            "Inquiry konnte nicht erfolgreich heruntergeladen werden"
          );

        // Download job and assign active job
        await this.$store.dispatch("job/fetchById", {
          id: this.response.jobId,
        });

        this.job = this.$store.getters["job/getById"](this.response.jobId);
        if (!this.job) throw new Error(`Job with id ${id} not found`);

        this.loading = false;

        console.log("[JobEditView] Job", this.job);
        console.log("[JobEditView] Response", this.response);
        console.log("[JobEditView] Inquiry", this.inquiry);
      } catch (error) {
        console.error(error);
        if (error.message && error.message === "Failed to fetch") {
          this.error = `Der Auftrag mit der ID ${this.$route.params.id} konnte nicht gefunden werden bzw. beim Laden des Auftrages ist ein Fehler aufgetreten.`;
        } else {
          this.error = error.message;
        }
        this.loading = false;
      }
    },
    loadDataForUser(id) {
      return Promise.resolve()
        .then(async () => {
          this.loading = true;

          // Load job by id
          this.job = this.$store.getters["job/getById"](id);
          if (!this.job) throw new Error(`Job with id ${id} not found`);

          // Load response by job.idx
          this.response = this.$store.getters["response/getByJobId"](
            this.$route.params.id
          );
          if (!this.response)
            throw new Error(
              `Die Response für den Auftrag mit der internen ID ${id} konnte nicht gefunden werden.`
            );

          // Load inquiry by job.inquiryId
          this.inquiry = this.$store.getters["inquiry/getByIdAndVersion"](
            this.response.inquiryId,
            this.response.inquiryVersion
          );

          if (!this.inquiry) throw new Error(`Inquiry with id ${id} not found`);

          console.log("[JobEditView] Job", this.job);
          console.log("[JobEditView] Response", this.response);
          console.log("[JobEditView] Inquiry", this.inquiry);
        })
        .catch(error => {
          console.log("[JobEditView] Error in loadDataForUser", this.error);
          if (error.message && error.message === "Failed to fetch") {
            this.error = `Der Auftrag mit der ID ${this.$route.params.id} konnte nicht gefunden werden bzw. beim Laden des Auftrages ist ein Fehler aufgetreten.`;
          } else {
            this.error = error.message;
          }
          if (error)
            console.error(
              "[JobEditView - loadDataForUser] Error loading for user:",
              this.error
            );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeJobEditView() {
      if (
        (this.response && this.response.editStatus > 0) ||
        this.error ||
        (this.isUserAdmin && this.response.syncStatus === 2)
      ) {
        this.$router.push({
          name: "jobs",
        });
      } else {
        this.isConfirmLeavingViewModalShowing = true;
        this.isConfirmLeavingViewModalConfirmed = false;
      }
    },
    removeJob() {
      this.loading = true;

      console.log("removeJob with id", this.job.id);

      // Download response and assign active response
      this.$store.dispatch("job/delete", { jobId: this.job.id }).then(() => {
        this.$router.push({
          name: "jobs",
        });
      });
    },
  },
};
</script>

<style scoped>
.container__edit {
  background-color: #fff;
}

/*
 * Navigation
 **********************/

nav span {
  padding-top: 9px;
  line-height: 1.4rem;
}

.menu-title {
  width: 100%;
}

.ui.grid > .column:not(.row):not(.menu-title__column) {
  padding-top: 10px !important;
}

.menu-title__column {
  padding-top: 0px !important;
}

.menu-subtitle {
  font-weight: 700;
  font-size: 0.65em !important;
  opacity: 0.54;
}

.menu__close-button {
  background: #ffffff;
}

/*
 * Form
 **********************/

.ui.form .field > label {
  font-size: 1.175rem;
}

.label-small {
  font-size: 0.92857143em !important;
}

.btn-submit {
  margin-top: 32px !important;
}

.btn-photo-remove {
  margin-top: 16px !important;
}

input[type="file"] {
  position: absolute;
  overflow: hidden;
  opacity: 0.01;
  background-color: red;
  max-width: 152px;
  max-height: 36px;
}

.photo-preview__buttons {
  padding-bottom: 29px !important;
}

.ui.segment {
  padding: 0;
  box-shadow: none;
  border: none;
}

.ui.segment > .ui.dimmer {
  z-index: 1;
}
</style>
