<template>
  <div>
    <nav class="ui top fixed menu">
      <span>Survello</span>
    </nav>
    <main class="ui">
      <div class="ui container">
        <h3 class="header">
          Mobile Auftragsbearbeitung für mehr Transparenz und Sicherheit
        </h3>
        <img class="ui image rounded" src="@/assets/header.jpg" />
        <router-link to="/login">
          <button
            class="fluid ui right labeled icon primary button large btn-login"
          >
            <i class="right arrow icon"></i>Anmelden
          </button>
        </router-link>
      </div>
    </main>
    <FooterVersionInfo />
  </div>
</template>

<script>
import FooterVersionInfo from "@/components/FooterVersionInfo";

export default {
  name: "welcome",
  components: {
    FooterVersionInfo,
  },
  data() {
    return {
      currentVersion: null,
    };
  },
  created() {
    this.currentVersion = process.env.VUE_APP_RELEASE;
  },
};
</script>

<style scoped>
.btn-login {
  margin-top: 16px !important;
}
</style>
