<template>
  <footer class="ui container">
    <p>
      &copy; 2022 Powered by Survello
      <span v-if="currentVersion">&nbsp;· Version {{ currentVersion }}</span>
    </p>
  </footer>
</template>

<script>
export default {
  name: "versionInfo",
  data() {
    return {
      currentVersion: "",
    };
  },
  created() {
    this.currentVersion = process.env.VUE_APP_RELEASE;
  },
};
</script>

<style>
footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 30px;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 11px;
}
</style>
