<template>
  <div class="tabbar-container ui three column grid">
    <div class="row">
      <div class="column tabbar-item">
        <router-link to="/jobs">
          <div>
            <ListIcon />
            <p>Aufträge</p>
          </div>
        </router-link>
      </div>
      <div class="column tabbar-item">
        <router-link to="/sync">
          <div>
            <QueueIcon />
            <p>Warteschlange</p>
            <div class="floating ui red label" v-if="pendingItemsCount > 0">
              {{ pendingItemsCount }}
            </div>
          </div>
        </router-link>
      </div>
      <div class="column tabbar-item">
        <router-link to="/settings">
          <div>
            <SettingsIcon />
            <p>Einstellungen</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ListIcon from "@/assets/tab-inquiries.svg";
import QueueIcon from "@/assets/tab-sync.svg";
import SettingsIcon from "@/assets/tab-settings.svg";

export default {
  name: "TabBar",
  props: ["pendingJobs", "pendingResponses"],
  components: {
    ListIcon,
    QueueIcon,
    SettingsIcon,
  },
  computed: {
    pendingItemsCount() {
      if (!this.pendingResponses) {
        return 0;
      }
      const responses = this.pendingResponses.filter(
        response =>
          response.syncStatus < 2 || this.hasResponsePendingJob(response.jobId)
      );

      // If no respones are pending, show count pending jobs
      if (!responses || responses.length === 0) {
        const jobs = this.pendingJobs.filter(job => job.syncStatus < 2);
        return jobs.length > 0 ? jobs.length : 0;
      }

      return responses.length > 0 ? responses.length : 0;
    },
  },
  methods: {
    hasResponsePendingJob(jobId) {
      const jobs = this.pendingJobs.filter(
        job => job.jobId === jobId && job.syncStatus < 2
      );

      return jobs.length > 0;
    },
  },
};
</script>

<style>
.tabbar-container {
  position: fixed;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 50px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background: #e1e4e6;
}

@media only screen and (min-device-width: 375px) and (min-device-height: 799px) {
  .tabbar-container {
    height: 70px;
  }
}
</style>

<style scoped>
.grid {
  margin: 0;
}

.grid .row {
  padding-top: 5px;
}

.column {
  text-align: center;
}

.tabbar-item a,
.tabbar-item img {
  color: rgba(0, 0, 0, 0.54);
  opacity: 0.54;
}

.tabbar-item .active img {
  opacity: 0.9;
}

.tabbar-item p {
  font-size: 11px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
  line-height: 0.9rem;
}

.tabbar-item .active,
.tabbar-item .active p {
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.9;
}

.tabbar-item .ui.label {
  top: -4px !important;
  padding: 3px 5px !important;
  border-radius: 4px !important;
}

.tabbar-item .ui.floating.label {
  left: 65% !important;
}
</style>
