<template>
  <div class="ui grouped fields">
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">
      {{ field.sectionDescription }}
    </p>
    <div class="ui message yellow">
      <label v-if="field.headingTitle" class="header">
        {{ field.headingTitle }}
      </label>
      <span
        class="ui field span_pre"
        v-if="field.headingDescription"
        v-html="field.headingDescription"
      >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormPresentationAlert",
  props: ["field"],
};
</script>
