<template>
  <div>
    <a
      v-bind="job"
      v-bind:key="job.id"
      v-if="job.editStatus !== 3"
      @click="handleJobSelection"
      class="ui fluid card"
    >
      <JobListItemInquiryName :job="job" :inquiries="inquiries" />
      <div class="content">
        <div class="content__status-edit">
          <div
            class="ui top right attached label tiny"
            :class="{
              blue: job.editStatus === 0,
              yellow: job.editStatus === 1,
              green: job.editStatus === 2,
            }"
          >
            {{
              job.editStatus === 0
                ? "NEU"
                : job.editStatus === 1
                ? "IN BEARBEITUNG"
                : job.editStatus === 2
                ? "ABGESCHLOSSEN"
                : ""
            }}
          </div>
        </div>
        <div class="header">{{ job.jobNumber }}</div>
        <div class="description">
          <JobListItemDueTime :dueAt="job.dueAt" v-if="job.dueAt" />
          <div class="ui grid">
            <div class="seven wide column">
              <p>
                {{ job.customer.name }}
                <br />
                {{ job.customer.phone }} <br />
                {{ job.customer.mobile }}
              </p>
            </div>
            <div class="nine wide column">
              <p>
                {{ job.customer.address.street }}
                <br />
                {{ job.customer.address.zip }}
                {{ job.customer.address.city }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="job.info" class="extra content" v-html="job.info" />
    </a>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import JobListItemInquiryName from "@/components/JobListItemInquiryName";
import JobListItemDueTime from "@/components/JobListItemDueTime";

export default {
  name: "JobListItem",
  props: ["job", "inquiries"],
  components: {
    JobListItemInquiryName,
    JobListItemDueTime,
  },
  methods: {
    handleJobSelection() {
      EventBus.$emit("jobSelected", this.job);
    },
  },
};
</script>
