<template>
  <div
    class="ui grouped fields form__block"
    v-bind:class="{
      disabled: response.editStatus > 0 && !userIsAdmin,
      required: isFieldRequired(field),
    }"
  >
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">
      {{ field.sectionDescription }}
    </p>
    <label v-if="field.headingTitle">
      {{ field.headingTitle }}
    </label>
    <p v-if="field.headingDescription">
      {{ field.headingDescription }}
    </p>

    <div
      class="radio__group"
      v-if="field.choices"
      :required="isFieldRequired(field)"
    >
      <template v-for="choice in field.choices">
        <div
          class="field"
          v-bind="choice"
          v-bind:key="choice.id"
          :required="isFieldRequired(field)"
        >
          <div
            class="ui radio checkbox"
            :required="isFieldRequired(field)"
            @click="setCheckbox(field, choice, $event)"
          >
            <input
              type="radio"
              tabindex="0"
              class="hidden"
              :name="field.id"
              :checked="isCheckboxChecked(field, choice)"
              :required="isFieldRequired(field)"
            />
            <label :id="choice.id">{{ choice.text }}</label>
          </div>
        </div>
      </template>
    </div>

    <div
      class="ui field"
      :class="{
        disabled: response.editStatus > 0 && !userIsAdmin,
        required: isDescriptionRequired(field),
      }"
      :required="isDescriptionRequired(field)"
    >
      <label class="label-small">Bemerkung</label>
      <div class="ui input">
        <textarea
          type="text"
          name="description"
          placeholder="Bemerkung hinzufügen…"
          spellcheck="true"
          autocorrect="on"
          rows="1"
          :class="{
            disabled: response.editStatus > 0 && !userIsAdmin,
            required: isDescriptionRequired(field),
          }"
          :value="descriptionValue"
          :required="isDescriptionRequired(field)"
          @blur="setDescriptionText(field, $event)"
        >
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isFieldRequired,
  isDescriptionRequired,
  isCheckboxChecked,
  setCheckbox,
  setDescriptionText,
} from "@/lib/FormUtil";

export default {
  name: "FormSingleValueYesNoComment",
  props: ["field", "inquiry", "job", "response", "userIsAdmin"],
  data() {
    return {
      currentChoice: null,
    };
  },
  asyncComputed: {
    /* eslint-disable */
    descriptionValue: {
      get() {
        const field = this.response.fields.find(f => f.id === this.field.id);
        if (!field) {
          return null;
        }

        return field.text;
      },
      watch() {
        this.response;
      },
    },
    /* eslint-enable */
  },
  mounted() {},
  methods: {
    isFieldRequired(field) {
      return isFieldRequired(field);
    },
    isDescriptionRequired(field) {
      return isDescriptionRequired(field, this.response);
    },
    isCheckboxChecked(field, choice) {
      return isCheckboxChecked(field.id, this.response, choice);
    },
    setCheckbox(field, choice, event) {
      this.currentChoice = choice;
      setCheckbox(field.id, this.response, event.target.id);
    },
    setDescriptionText(field, event) {
      setDescriptionText(field.id, this.response, event.target.value);
    },
  },
};
</script>
