var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui grouped fields form__block",class:{
    disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
    required: _vm.isFieldRequired(_vm.field),
  }},[(_vm.field.sectionTitle)?_c('h4',{staticClass:"ui dividing header"},[_vm._v(" "+_vm._s(_vm.field.sectionTitle)+" ")]):_vm._e(),(_vm.field.sectionDescription)?_c('p',[_vm._v(" "+_vm._s(_vm.field.sectionDescription)+" ")]):_vm._e(),(_vm.field.headingTitle)?_c('label',[_vm._v(" "+_vm._s(_vm.field.headingTitle)+" ")]):_vm._e(),(_vm.field.headingDescription)?_c('p',[_vm._v(" "+_vm._s(_vm.field.headingDescription)+" ")]):_vm._e(),(_vm.field.choices)?_c('div',{staticClass:"radio__group",attrs:{"required":_vm.isFieldRequired(_vm.field)}},[_vm._l((_vm.field.choices),function(choice){return [_c('div',_vm._b({key:choice.id,staticClass:"field",attrs:{"required":_vm.isFieldRequired(_vm.field)}},'div',choice,false),[_c('div',{staticClass:"ui radio checkbox",attrs:{"required":_vm.isFieldRequired(_vm.field)},on:{"click":function($event){return _vm.setCheckbox(_vm.field, choice, $event)}}},[_c('input',{staticClass:"hidden",attrs:{"type":"radio","tabindex":"0","name":_vm.field.id,"required":_vm.isFieldRequired(_vm.field)},domProps:{"checked":_vm.isCheckboxChecked(_vm.field, choice)}}),_c('label',{attrs:{"id":choice.id}},[_vm._v(_vm._s(choice.text))])])])]})],2):_vm._e(),_c('div',{staticClass:"ui field",class:{
      disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
      required: _vm.isDescriptionRequired(_vm.field),
    },attrs:{"required":_vm.isDescriptionRequired(_vm.field)}},[_c('label',{staticClass:"label-small"},[_vm._v("Bemerkung")]),_c('div',{staticClass:"ui input"},[_c('textarea',{class:{
          disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
          required: _vm.isDescriptionRequired(_vm.field),
        },attrs:{"type":"text","name":"description","placeholder":"Bemerkung hinzufügen…","spellcheck":"true","autocorrect":"on","rows":"1","required":_vm.isDescriptionRequired(_vm.field)},domProps:{"value":_vm.descriptionValue},on:{"blur":function($event){return _vm.setDescriptionText(_vm.field, $event)}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }