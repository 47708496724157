<template>
  <div>
    <nav class="ui top fixed menu two column grid wide">
      <div class="column">
        <span>Warteschlange</span>
      </div>
    </nav>
    <main class="ui vertical-center">
      <div v-if="loading" class="ui segment">
        <div class="ui active centered inline loader text">
          <div class="ui text loader">Lade …</div>
        </div>
      </div>
      <div
        v-if="
          (pendingJobs && pendingJobs.length > 0) ||
          (pendingResponses && pendingResponses.length > 0)
        "
      >
        <div class="ui container">
          <button
            class="fluid ui left labeled icon blue big button"
            v-bind:class="{ disabled: syncing, loading: syncing }"
            style="padding-left: 3.5em !important; margin-bottom: 1em"
            v-if="!syncing"
            @click="handleSyncButton($event)"
          >
            <i class="left cloud upload icon"></i>{{ pendingJobs.length }}
            {{ pendingJobs.length == 1 ? "Auftrag" : "Aufträge" }} übertragen
          </button>
          <div
            v-if="!loading && syncing"
            class="ui segment"
            style="margin: 0; padding: 0"
            @click="stopSync($event)"
          >
            <div class="ui icon small message blue">
              <i class="notched circle loading icon"></i>
              <div class="content">
                <div class="header">Übertragung läuft …</div>
                <p>Die Warteschlange wird übertragen.</p>
              </div>
            </div>
          </div>
        </div>

        <SyncList
          :jobs="pendingJobs"
          :responses="pendingResponses"
          :images="images"
        />
      </div>
      <div v-else class="ui container center aligned container-empty">
        <EmptyListIcon />
        <h3 class="deactive">Warteschlange ist leer</h3>
        <p class="deactive">
          Zu übertragende Aufträge erscheinen
          <br />in dieser Liste.
        </p>
      </div>
    </main>
    <tab-bar
      :pendingJobs="pendingJobs"
      :pendingResponses="pendingResponses"
    ></tab-bar>
  </div>
</template>

<script>
import store from "@/store/store";
import { mapGetters } from "vuex";
import moment from "moment";
import TabBar from "./../components/TabBar";
import SyncList from "./../components/SyncList";
import EmptyListIcon from "@/assets/icon-sync-empty.svg";

moment.locale("de");

export default {
  name: "sync",
  components: {
    SyncList,
    TabBar,
    EmptyListIcon,
  },
  data() {
    return {
      loading: false,
      online: false,
      error: null,
    };
  },
  created() {
    this.online = window.navigator.onLine;
  },
  mounted() {
    console.log("[SyncView.vue] Store Object", this.$store);
    console.log("[SyncView.vue] Pending Responses", this.pendingResponses);
    console.log("[SyncView.vue] Pending Jobs", this.pendingJobs);
  },
  computed: {
    ...mapGetters({
      pendingResponses: "response/pending",
      pendingJobs: "job/pending",
      images: "image/pending",
      syncing: "sync/isSyncing",
    }),
  },
  methods: {
    handleSyncButton(event) {
      event.preventDefault();

      this.online = window.navigator.onLine;

      store.dispatch("sync/upload");
    },
    stopSync() {
      store.dispatch("sync/setIsSyncing", false);
    },
  },
};
</script>

<style scoped>
nav span {
  padding-top: 2px;
}

.container__list {
  padding-bottom: 50px;
}

.menu__sync {
  padding-top: 0.7rem !important;
  padding-right: 1rem;
  text-align: right;
}

.menu__sync-button {
  margin-right: 0;
}

.ui.top.attached.label + [class*="right floated"] + *,
.ui.top.attached.label:first-child + :not(.attached) {
  margin-top: 0 !important;
}

.ui.card > .content > .meta,
.ui.cards > .card > .content > .meta {
  padding-bottom: 16px !important;
}

.container__list {
  padding-bottom: 50px;
}

.container__list h3 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.card__img {
  margin-top: 4px !important;
}

@media only screen and (max-width: 320px) {
  .image__inquiry-icon,
  .image__photo-icon {
    display: none !important;
  }
  .ui.list > .item > .image + .content {
    padding: 0 !important;
  }
}
</style>
