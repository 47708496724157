var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui grouped fields",class:{
    disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
    required: _vm.isFieldRequired(_vm.field),
  }},[(_vm.field.sectionTitle)?_c('h4',{staticClass:"ui dividing header"},[_vm._v(" "+_vm._s(_vm.field.sectionTitle)+" ")]):_vm._e(),(_vm.field.sectionDescription)?_c('p',[_vm._v(" "+_vm._s(_vm.field.sectionDescription)+" ")]):_vm._e(),_c('div',{staticClass:"ui field grouped fields",class:{
      disabled: _vm.response.editStatus > 0 && !_vm.userIsAdmin,
      required: _vm.isFieldRequired(_vm.field),
    },attrs:{"required":_vm.isFieldRequired(_vm.field)}},[(_vm.field.headingTitle)?_c('label',[_vm._v(" "+_vm._s(_vm.field.headingTitle)+" ")]):_vm._e(),(_vm.field.headingDescription)?_c('p',[_vm._v(" "+_vm._s(_vm.field.headingDescription)+" ")]):_vm._e(),_c('div',{staticClass:"ui input",class:{
        disabled: (_vm.response.editStatus > 0 && !_vm.userIsAdmin) || _vm.field.readonly,
        required: _vm.isFieldRequired(_vm.field),
      },attrs:{"required":_vm.isFieldRequired(_vm.field)}},[_c('textarea',{staticClass:"multi",attrs:{"type":"text","name":"description","placeholder":"Bemerkung hinzufügen…","spellcheck":"true","autocorrect":"on","rows":"3","required":_vm.isFieldRequired(_vm.field)},domProps:{"value":_vm.descriptionValue},on:{"blur":function($event){return _vm.setDescriptionText(_vm.field, $event)}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }