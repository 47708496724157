const uploadType = "resumable";
const key = process.env.VUE_APP_GCS_KEY;
const bucketName = process.env.VUE_APP_GCS_BUCKET;

export default {
  /**
   * Download an uploadId from Google Cloud Storage
   */
  downloadUploadId(params) {
    const header = {
      "Accept-Encoding": "application/json",
      "Content-Type": "application/json; charset=UTF-8",
      "Content-Length": params.contentSize,
      "X-Upload-Content-Length": params.imageSize,
      "X-Upload-Content-Type": params.imageType,
    };
    const url = `https://www.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=${uploadType}&key=${key}&name=uploads/${params.fileName}`;

    return fetch(url, {
      method: "post",
      headers: header,
    });
  },
  /**
   * Upload the given image chunk to Google Cloud Storage
   */
  uploadImageChunk(params) {
    const request = {
      method: "put",
      headers: {
        "Accept-Encoding": "application/json",
        "Content-Type": params.imageType,
        "Content-Length": params.isResuming ? params.chunkSize : 0,
        "Content-Range": params.isResuming
          ? params.unknownRangeHeader
          : params.rangeHeader,
      },
      body: params.isResuming ? null : params.imageBlob,
    };
    const url = `https://www.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=${uploadType}&key=${key}&upload_id=${params.uploadId}`;

    return fetch(url, request);
  },
};
