<template>
  <div
    class="ui grouped fields form__block"
    :class="{
      disabled: inquiry.editStatus > 0 && !userIsAdmin,
      required: isFieldRequired(field),
    }"
    :required="isFieldRequired(field)"
  >
    <h4 v-if="field.sectionTitle" class="ui dividing header">
      {{ field.sectionTitle }}
    </h4>
    <p v-if="field.sectionDescription">
      {{ field.sectionDescription }}
    </p>
    <label v-if="field.headingTitle">
      {{ field.headingTitle }}
    </label>
    <p v-if="field.headingDescription">
      {{ field.headingDescription }}
    </p>

    <template v-for="choice in field.choices">
      <div
        class="field"
        v-bind="choice"
        v-bind:key="choice.id"
        :required="isFieldRequired(field)"
        v-if="isOptionVisible(choice)"
      >
        <div
          class="ui checkbox"
          :required="isFieldRequired(field)"
          @click="setCheckbox(field, choice, $event)"
        >
          <input
            type="checkbox"
            tabindex="0"
            class="hidden"
            :name="field.id"
            :checked="isCheckboxChecked(field, choice)"
            :required="isFieldRequired(field)"
          />
          <label :id="choice.id">{{ choice.text }}</label>
        </div>
      </div>
    </template>

    <div
      class="ui field"
      v-if="field.descriptionVisible"
      :class="{
        disabled: inquiry.editStatus > 0 && !userIsAdmin,
        required: isDescriptionRequired(field),
      }"
      :required="isDescriptionRequired(field)"
    >
      <label class="label-small">Bemerkung</label>
      <div class="ui input" :required="isDescriptionRequired(field)">
        <textarea
          type="text"
          name="description"
          placeholder="Bemerkung hinzufügen…"
          spellcheck="true"
          autocorrect="on"
          rows="3"
          :class="{
            disabled: inquiry.editStatus > 0 && !userIsAdmin,
            required: isDescriptionRequired(field),
          }"
          :value="field.answers[0].text"
          :required="isDescriptionRequired(field)"
          @blur="setDescriptionText(field, $event)"
        >
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isFieldRequired,
  isDescriptionRequired,
  isOptionVisible,
  isCheckboxChecked,
  setCheckbox,
  setSelectionCondition,
  setDescriptionText,
} from "@/lib/FormUtil";

export default {
  name: "FormMultipleValueVertical",
  props: ["field", "inquiry", "job", "response", "userIsAdmin"],
  mounted() {},
  methods: {
    isOptionVisible(choice) {
      return isOptionVisible(choice, this.response);
    },
    isFieldRequired(field) {
      return isFieldRequired(field);
    },
    isDescriptionRequired(field) {
      return isDescriptionRequired(field, this.response);
    },
    isCheckboxChecked(field, choice) {
      return isCheckboxChecked(field.id, this.response, choice);
    },
    setCheckbox(field, choice, event) {
      setCheckbox(field.id, this.response, event.target.id, true);

      // If a selection condition (e.g. add value to another field) is set,
      // set the selected value in the belonging field.
      if (choice.selectionConditions && choice.selectionConditions.length > 0) {
        choice.selectionConditions.forEach(condition => {
          setSelectionCondition(
            condition.fieldId,
            this.response,
            condition.comparator,
            condition.value
          );
        });
      }
    },
    setDescriptionText(field, event) {
      setDescriptionText(field.id, this.response, event.target.value);
    },
  },
};
</script>
