/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("App is being served from cache by a service worker.");
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log(
        "New content is available, sending refresh message to client."
      );
      if ("BroadcastChannel" in self) {
        const swListener = new BroadcastChannel("swListener");
        swListener.postMessage({
          cmd: "installed",
        });
      }
      let worker = registration.waiting;
      worker.postMessage({
        action: "skipWaiting",
      });
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
