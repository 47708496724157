import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import VuexPersistPatch from "@/plugins/vuex-persist-patch";
import localforage from "localforage";
import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";

import * as user from "./modules/user";
import * as inquiry from "./modules/inquiry";
import * as job from "./modules/job";
import * as response from "./modules/response";
import * as image from "./modules/image";
import * as sync from "./modules/sync";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  strictMode: true,
  asyncStorage: true,
  storage: localforage,
  key: "survello",
});

const logrocketPlugin = createPlugin(LogRocket);

export default {
  strict: process.env.NODE_ENV !== "production",
  modules: {
    user,
    inquiry,
    job,
    response,
    image,
    sync,
  },
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
  },
  plugins: [vuexLocal.plugin, VuexPersistPatch(), logrocketPlugin],
};
