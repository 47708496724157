<template>
  <div v-if="inquiryName" class="ui bottom left attached label">
    {{ inquiryName }}
  </div>
</template>

<script>
export default {
  name: "JobCellInquiryName",
  props: ["job", "inquiries"],
  data() {
    return {
      inquiryName: null,
    };
  },
  mounted() {
    if (!this.inquiries || this.inquiries.length === 0 || !this.job) {
      return;
    }
    const localInquiry = this.inquiries.find(
      inquiry => inquiry.id === this.job.inquiryId
    );
    if (localInquiry) {
      this.inquiryName = `${localInquiry.campaignName} / ${localInquiry.name}`;
    }
  },
  methods: {},
};
</script>
