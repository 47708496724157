<template>
  <modal black v-model="isDeletePhotoModalShowing" modalVariation="basic">
    <div slot="header" class="center">
      <i class="trash icon"></i>
      Foto löschen
    </div>
    <div class="content" slot="content">
      <p>Möchten Sie dieses Foto wirklich löschen?</p>
    </div>
    <template slot="actions">
      <div
        class="ui black deny inverted button"
        @click="cancelDeletePhotoModal"
      >
        <i class="remove icon"></i> Nein
      </div>
      <div
        class="ui green right inverted button"
        @click="confirmDeletePhotoModal"
      >
        <i class="checkmark icon"></i> Ja
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "vue-semantic-modal";

export default {
  name: "ModalImageCaptureDelete",
  props: [
    "photoToDeleteField",
    "inquiry",
    "job",
    "response",
    "isDeletePhotoModalShowing",
    "isDeletePhotoModalConfirmed",
    "userIsAdmin",
  ],
  components: {
    modal,
  },
  methods: {
    cancelDeletePhotoModal() {
      this.$emit("update:isDeletePhotoModalShowing", false);
      this.$emit("update:isDeletePhotoModalConfirmed", true);
    },
    confirmDeletePhotoModal() {
      this.cancelDeletePhotoModal();
      this.$emit("update:isDeletePhotoModalConfirmed", false);
      this.deleteImage();
    },
    deleteImage() {
      if (!this.photoToDeleteField || !this.photoToDeleteField.id) {
        return;
      }

      // Delete photo from data object
      const localField = this.response.fields.find(
        f => f.id === this.photoToDeleteField.id.toString()
      );
      if (!localField) {
        throw Error("Cannot find field for photo");
      }

      if (this.userIsAdmin) {
        this.$store.dispatch("response/resetFieldFileName", {
          id: this.response.id,
          field: {
            id: localField.id,
          },
        });
      } else {
        this.$store
          .dispatch("image/delete", {
            fileName: localField.fileName,
          })
          .then(() =>
            this.$store.dispatch("response/resetFieldFileName", {
              id: this.response.id,
              field: {
                id: localField.id,
              },
            })
          );
      }
    },
  },
};
</script>
