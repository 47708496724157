<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import { EventBus } from "./event-bus";

export default {
  name: "app",
  created() {
    if ("serviceWorker" in navigator) {
      if ("BroadcastChannel" in self) {
        // BroadcastChannel API supported!
        // eslint-disable-next-line no-undef
        const swListener = new BroadcastChannel("swListener");
        swListener.onmessage = event => {
          if (event.data && event.data.cmd === "installed") {
            this.showNewVersionAvailableToast();
          }
        };
      } else {
        // Wait for update message by service worker for new version
        self.addEventListener("message", evt => {
          if (evt.data && evt.data.cmd === "installed") {
            this.showNewVersionAvailableToast();
          }
        });
      }
    }
    this.addEventBusListener();
  },
  methods: {
    addEventBusListener() {
      EventBus.$on("added", () => this.reloadStoreData());
      EventBus.$on("updated", () => this.reloadStoreData());
      EventBus.$on("deleted", () => this.reloadStoreData());
      EventBus.$on("synced", () => {
        this.showSyncFinishedWithSuccessToast();
      });
      EventBus.$on("synced-error", () => {
        this.showSyncFinishedWithErrorToast();
      });
    },
    reloadStoreData() {
      this.$store.dispatch("inquiry/setInquiries");
      this.$store.dispatch("image/setImages");
      this.$store.dispatch("job/setJobs");
      this.$store.dispatch("response/setResponses");
    },
    showNewVersionAvailableToast() {
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      Vue.toasted.show(
        "Eine neue Version von Survello wird automatisch installiert.",
        {
          theme: "primary",
          position: "bottom-center",
          duration: null,
          fullWidth: true,
          className: "toast-update",
          action: {
            text: "Neu laden",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
              window.location.reload();
            },
          },
        }
      );
    },
    showSyncFinishedWithSuccessToast() {
      Vue.toasted.show("Warteschlange übertragen", {
        theme: "primary",
        position: "bottom-left",
        duration: 2500,
        fullWidth: false,
        className: "toast-green",
      });
    },
    showSyncFinishedWithErrorToast() {
      Vue.toasted.show("Warteschlange nicht übertragen", {
        theme: "primary",
        position: "bottom-left",
        duration: 2500,
        fullWidth: false,
        className: "toast-error",
      });
    },
  },
};
</script>

<style>
:root {
  --blue: #2c3e50;
  --orange: #f05f40;
  --light-gray: #aaaaaa;
  --red: #d0021b;
}

body {
  margin: 0;
  padding-top: 54px;
  background-color: #f6f9fc;
  background: #f6f9fc;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--blue);
}

main {
  margin: 0 auto;
  padding: 16px 0;
  max-width: 650px !important;
}

.ui.menu {
  color: #ffffff;
  background: var(--orange);
  background-color: var(--orange);
}
.ui.container {
  max-width: 650px !important;
}

nav {
  height: 56px;
  padding: 0 16px 0 16px;
}

nav span {
  display: block;
  position: relative;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  box-sizing: border-box;
  padding-top: 16px;
}

.container-empty {
  padding-top: 10%;
}

.modal-active {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

.deactive {
  color: var(--light-gray) !important;
}

.ui.segment {
  background: none;
  background-color: none;
  border: none;
  box-shadow: none;
}
.ui.dimmer {
  padding-top: 250px;
}
.ui.radio.checkbox {
  margin-top: 5px;
}

.toasted-container {
  bottom: 60px !important;
}
.toasted-container a {
  color: #000;
  text-align: center;
}
.toasted.primary {
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 0.28571429rem !important;
  background: var(--orange);
  margin-left: 1em;
  margin-right: 1em;
}
.toasted.primary.action {
  border-radius: 4px;
  background: var(--orange);
}
.toast-update.primary.default {
  color: black;
  border-radius: 4px;
  background: var(--orange);
}
.toast-update.primary.default a {
  color: lightgray;
}
.toast-green.primary.default {
  color: white;
  border-radius: 4px;
  background: #7ebb3b;
}
.toast-error.primary.default {
  color: white;
  border-radius: 4px;
  background: var(--red);
}
</style>
