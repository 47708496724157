var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container__edit"},[_c('nav',{staticClass:"ui top fixed menu two column grid wide"},[_vm._m(0),_c('div',{staticClass:"column menu__add",on:{"click":_vm.closeManual}},[_vm._m(1)])]),_c('div',{staticClass:"ui container",staticStyle:{"padding-top":"2em","padding-bottom":"2em","background":"transparent"}},[(_vm.browser.isIos)?_c('div',[_c('h3',[_vm._v("App dem Home-Screen hinzufügen")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_c('div',[_c('h3',[_vm._v("App dem Home-Screen hinzufügen")]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column menu-title__column"},[_c('span',{staticClass:"menu-title",staticStyle:{"width":"230px"}},[_vm._v(" Anleitung "),_c('br'),_c('small',{staticClass:"menu-subtitle"},[_vm._v("App dem Home-Screen hinzufügen")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"ui icon button menu__close-button right floated inverted"},[_c('i',{staticClass:"remove icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"25px"}},[_c('p',[_vm._v(" 1. Wählen Sie die "),_c('strong',[_vm._v("„Teilen”-Option")]),_vm._v(" im Safari-Browser aus ")]),_c('img',{staticClass:"ui image",attrs:{"src":require("@/assets/manual/ios-home-01.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"25px"}},[_c('p',[_vm._v(" 2. Wählen Sie die Option "),_c('strong',[_vm._v("„Zum Home-Bildschirm”")]),_vm._v(" aus ")]),_c('img',{staticClass:"ui image",attrs:{"src":require("@/assets/manual/ios-home-02.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"25px"}},[_c('p',[_vm._v("3. Wählen Sie "),_c('strong',[_vm._v("„Hinzufügen”")]),_vm._v(" aus")]),_c('img',{staticClass:"ui image",attrs:{"src":require("@/assets/manual/ios-home-03.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v(" 4. Öffnen Sie die "),_c('strong',[_vm._v("Survello-App")]),_vm._v(" vom Home-Bildschirm aus ")]),_c('img',{staticClass:"ui image",attrs:{"src":require("@/assets/manual/ios-home-04.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"25px"}},[_c('p',[_vm._v(" 1. Öffnen Sie das "),_c('strong',[_vm._v("Menü über die drei Punkte")]),_vm._v(" im Browser aus ")]),_c('img',{staticClass:"ui image",attrs:{"src":require("@/assets/manual/android-home-01.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"25px"}},[_c('p',[_vm._v(" 2. Wählen Sie die Option "),_c('strong',[_vm._v("„App installieren”")]),_vm._v(" aus ")]),_c('img',{staticClass:"ui image",attrs:{"src":require("@/assets/manual/android-home-02.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"25px"}},[_c('p',[_vm._v(" 3. Wählen Sie "),_c('strong',[_vm._v("„Installieren”")]),_vm._v(" aus. Die Installation kann je nach Internetverbindung einen Moment dauern und wird über eine Benachrichtigung bestätigt. ")]),_c('img',{staticClass:"ui image",attrs:{"src":require("@/assets/manual/android-home-03.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v(" 4. Öffnen Sie die "),_c('strong',[_vm._v("Survello-App")]),_vm._v(" vom Home-Bildschirm aus ")]),_c('img',{staticClass:"ui image",attrs:{"src":require("@/assets/manual/android-home-04.jpg")}})])
}]

export { render, staticRenderFns }