<template>
    <div class="ui fluid  card" style="margin-top:0px;box-shadow: none;border: 1px solid #ddd;" >
        <JobListItemInquiryName :job="job" :inquiries="inquiries" />
        <div class="content">
            
            <div class="header">{{ job.jobNumber }}</div>
            <div class="description">
                <JobListItemDueTime :dueAt="job.dueAt" v-if="job.dueAt" />
                <div class="ui grid">
                    <div class="seven wide column" style="word-break: break-all;">
                        <p>
                            {{ job.customer.name }}
                            <br />
                            {{ job.customer.phone }}<br />
                            {{ job.customer.mobile }}
                        </p>
                    </div>
                    <div class="nine wide column">
                        <p>
                            {{ job.customer.address.street }}
                            <br />
                            {{ job.customer.address.zip }}
                            {{ job.customer.address.city }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="job.info" class="extra content" v-html="job.info" />

    </div>
</template>
  
<script>
import { EventBus } from "@/event-bus";
import JobListItemInquiryName from "@/components/JobListItemInquiryName";
import JobListItemDueTime from "@/components/JobListItemDueTime";

export default {
    name: "JobListItem",
    props: ["job", "inquiries"],
    components: {
        JobListItemInquiryName,
        JobListItemDueTime,
    }
};
</script>
  