<template lang="html">
  <div>
    <nav class="ui top fixed menu">
      <span>Anmelden</span>
    </nav>
    <main class="ui">
      <div class="ui container">
        <div v-if="browser.isLoginAllowed">
          <!-- START Login expired //-->
          <div class="ui yellow message" v-if="sessionInvalid">
            <div class="header">Sitzung abgelaufen</div>
            <p>
              Ihre letzte Sitzung ist abgelaufen, da Ihr Benutzerkonto auf einem
              anderen Gerät genutzt worden ist.
            </p>
          </div>
          <p v-if="sessionInvalid">
            Bitte melden Sie sich bei Survello an, um erneut auf Ihre Aufträge
            zugreifen zu können.
          </p>
          <!-- END Login expired //-->
          <!-- START Login form //-->
          <p v-if="!sessionInvalid">
            Bitte melden Sie sich bei Survello an, um auf Ihre Aufträge
            zugreifen zu können.
          </p>
          <form class="ui form" v-on:submit.prevent="login" :disabled="loading">
            <div class="field required">
              <label class="label__username">Benutzername</label>
              <input
                @input="error = null"
                type="text"
                name="username"
                placeholder="Benutzername"
                autocomplete="username"
                spellcheck="false"
                autocorrect="off"
                autocapitalize="none"
                minlength="3"
                v-model.trim="user.username"
                :disabled="loading"
                required
              />
            </div>
            <div class="field required">
              <label class="label__password">Passwort</label>
              <input
                @input="error = null"
                type="password"
                name="password"
                placeholder="Passwort"
                autocomplete="current-password"
                spellcheck="false"
                autocorrect="off"
                autocapitalize="none"
                minlength="3"
                v-model.trim="user.password"
                :disabled="loading"
                required
              />
            </div>
            <div v-if="error" class="ui red message">
              <div class="header">Anmeldung fehlgeschlagen</div>
              <p>{{ error }}</p>
            </div>
            <button
              class="fluid ui right labeled icon primary button large btn-submit"
              :class="{ loading: loading }"
              :disabled="loading"
            >
              <i class="right arrow icon"></i>Anmelden
            </button>
          </form>
          <!-- END Login form //-->
        </div>
        <div v-if="!browser.isLoginAllowed">
          <UnsupportedDevice :browser="browser" />

          <router-link
            :to="{ name: 'home' }"
            class="fluid ui labeled icon basic large button btn-submit"
            :class="{ loading: loading }"
            :disabled="loading"
          >
            <i class="left arrow icon"></i>Zurück zum Start
          </router-link>
        </div>
      </div>
    </main>
    <FooterVersionInfo />
  </div>
</template>

<script>
const browserDetection = require("@braintree/browser-detection");

import UnsupportedDevice from "@/components/UnsupportedDevice";
import FooterVersionInfo from "@/components/FooterVersionInfo";

export default {
  name: "login",
  components: {
    UnsupportedDevice,
    FooterVersionInfo,
  },
  data() {
    return {
      loading: false,
      error: null,
      sessionInvalid: false,
      user: {
        username: null,
        password: null,
      },
      browser: {
        isLoginAllowed: false,
        isSupported: false,
        isStandalone: false,
        isIos: false,
        isAndroid: false,
      },
    };
  },
  mounted() {
    if (this.$route.query.sessionInvalid) {
      this.sessionInvalid = true;
    }

    // Check browser environment
    this.browser.isIos = browserDetection.isIos();
    this.browser.isAndroid = browserDetection.isAndroid();
    const isMobile = this.browser.isIos || this.browser.isAndroid;
    const isChrome = browserDetection.isChrome();
    const isSafari = browserDetection.isIosSafari();

    if (!isMobile) {
      this.browser.isSupported = true;
      this.browser.isLoginAllowed = true;
    } else {
      this.browser.isSupported =
        (this.browser.isIos && isSafari) ||
        (this.browser.isAndroid && isChrome);
      this.browser.isStandalone =
        isMobile &&
        ((this.browser.isIos &&
          this.$navigator &&
          this.$navigator.standalone) ||
          (this.browser.isAndroid &&
            window.matchMedia("(display-mode: standalone)").matches));
      this.browser.isLoginAllowed =
        !this.sessionInvalid &&
        this.browser.isSupported &&
        this.browser.isStandalone;
    }

    // if (process.env.NODE_ENV === "development") {
    //   this.browser.isSupported = true;
    //   this.browser.isLoginAllowed = true;
    // }
  },
  methods: {
    setIsLoginAllowed(value) {
      this.isLoginAllowed = value;
    },
    login() {
      this.loading = true;
      const credentials = {
        username: this.user.username,
        password: this.user.password,
      };

      this.$store
        .dispatch("user/login", credentials)
        .then(() => this.$store.dispatch("sync/download", credentials))
        .then(() => {
          this.$router.push({
            name: "jobs",
          });
        })
        .catch(() => {
          this.error =
            "Die verwendeten Zugangsdaten sind ungültig. Bitte prüfen Sie Ihre Angaben und probieren es erneut!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.btn-submit {
  margin-top: 24px !important;
}

.btn-contact {
  border: none !important;
  box-shadow: none !important;
}
</style>
