/**
 * FormUtil.js
 *
 * @author Marcel Bender
 * @since 1.0.0
 */

import store from "@/store/store";

/**
 *
 * @param {*} field
 * @param {*} response
 */
const isBlockVisible = (field, response) => {
  let isVisible = true;

  if (field && field.visibleConditions && field.visibleConditions.length > 0) {
    const condition = field.visibleConditions.filter(c => {
      const localCondition = c;
      if (localCondition.type === "attribute") {
        if (localCondition.comparator === "equals") {
          if (
            response[`${localCondition.attribute}`] === localCondition.value
          ) {
            return true;
          }
        }
      } else if (localCondition.type === "field") {
        if (localCondition.comparator === "choiceId") {
          // Get field from fields array of current response by passed in ID
          const responseField = response.fields.find(
            obj => obj.id === localCondition.fieldId
          );

          if (
            responseField &&
            responseField[`${localCondition.comparator}`] &&
            responseField[`${localCondition.comparator}`] ===
              localCondition.value
          ) {
            return true;
          }
        }
      }
      return false;
    });

    if (condition.length === 0) {
      isVisible = false;
    }
  }

  return isVisible;
};

/**
 * Returns if a given option should be visible
 *
 * @param {*} field
 * @param {*} response
 */

const isOptionVisible = (field, response) => {
  let isVisible = true;

  if (
    !field ||
    !field.visibleConditions ||
    !field.visibleConditions.length === 0
  ) {
    return isVisible;
  }

  field.visibleConditions.filter(currentCondition => {
    if (!currentCondition.condition || !currentCondition.options) {
      return false;
    }

    const options = currentCondition.options.filter(option => {
      if (option.type === "attribute") {
        if (option.comparator === "equals") {
          if (response[`${option.attribute}`] === option.value) {
            return true;
          }
        } else if (option.comparator === "notEquals") {
          if (response[`${option.attribute}`] !== option.value) {
            return true;
          }
        }
      } else if (option.type === "photo") {
        // Get field from fields array of current response by passed in ID
        const inquiryField = response.fields.find(
          obj => obj.id === option.fieldId
        );

        // If no referenced value is present, option should never be visible
        if (!inquiryField || !inquiryField.fileName) {
          return false;
        }
        return true;
      } else if (option.type === "field") {
        if (option.comparator === "choiceId") {
          // Check if comparator should be negated
          let negateOperatorSet = false;
          if (option.negate && option.negate === true) {
            negateOperatorSet = true;
          }

          // Get field from fields array of current response by passed in ID
          const inquiryField = response.fields.find(
            obj => obj.id === option.fieldId
          );

          if (
            inquiryField &&
            inquiryField.choiceIds &&
            inquiryField.choiceIds.includes(option.value)
          ) {
            return !negateOperatorSet;
          }

          if (negateOperatorSet) return true;
        } else if (option.comparator === "text") {
          // Get field from fields array of current response by passed in ID
          const responseField = response.fields.find(
            obj => obj.id === option.fieldId
          );

          // If no referenced value is present, option should never be visible
          if (!responseField || !responseField.text) {
            return false;
          }

          let isValid = true;
          // const comparatorValue = parseInt(inquiryField.text, 10);
          const comparatorValue = responseField.text.trim();

          // Check min and max conditions
          if (option.min && option.max) {
            isValid =
              comparatorValue.length >= option.min &&
              comparatorValue.length <= option.max;
          } else if (option.min) {
            isValid = comparatorValue.length >= option.min;
          } else if (option.max) {
            isValid = comparatorValue.length <= option.max;
          }

          // Check value to compare value against
          if (option.value) {
            isValid = comparatorValue == option.value;
          }

          // Check numerical operand to compare against
          if (option.operand && option.value) {
            const cReferenceValue = parseInt(option.value, 10);
            const cComparator = parseInt(responseField.text, 10);

            if (option.operand === "SMALLER") {
              isValid = cComparator < cReferenceValue;
            } else if (option.operand === "SMALLER_EQUAL") {
              isValid = cComparator <= cReferenceValue;
            } else if (option.operand === "EQUAL") {
              isValid = cComparator === cReferenceValue;
            } else if (option.operand === "GREATER_EQUAL") {
              isValid = cComparator >= cReferenceValue;
            } else if (option.operand === "GREATER") {
              isValid = cComparator > cReferenceValue;
            }
          }

          return isValid;
        }
      }
      return false;
    });

    // Check whether requirement of met based on condition
    if (currentCondition.condition === "allOf") {
      isVisible = options.length === currentCondition.options.length;
    } else if (currentCondition.condition === "anyOf") {
      isVisible = options.length > 0;
    } else if (currentCondition.condition === "nonOf") {
      isVisible = options.length === 0;
    }
    return true;
  });

  return isVisible;
};

/**
 *
 * @param {*} field
 */
const isFieldRequired = field =>
  field.required ||
  (!field.required && field.name) ||
  (!field.required && field.description);

const isDescriptionRequired = (field, response) => {
  if (!field) {
    return false;
  }

  // If description field is required, return right away.
  if (field.descriptionRequired) {
    return true;
  }

  // Check if selected choice requires description field
  const responseField = response.fields.find(f => f.id === field.id);
  if (!responseField) {
    return false;
  }

  if (
    responseField &&
    responseField.choiceIds &&
    responseField.choiceIds.length > 0
  ) {
    const choiceIds = responseField.choiceIds;
    const filtered = field.choices.filter(choice =>
      choiceIds.includes(choice.id)
    );
    
    if (!filtered || filtered.length === 0) {
      return false;
    }

    const currentChoice = filtered[0];
    if (currentChoice && currentChoice.descriptionRequired) {
      return true;
    }
  }

  return false;
};

const isDescriptionVisible = (field, response) => {
  if (!field) {
    return false;
  }

  // If description field is visible, return right away.
  if (field.descriptionVisible) {
    return true;
  }

   // Check if selected choice requires description field
   const responseField = response.fields.find(f => f.id === field.id);
   if (!responseField) {
     return false;
   }
 
   if (
     responseField &&
     responseField.choiceIds &&
     responseField.choiceIds.length > 0
   ) {
     const choiceIds = responseField.choiceIds;
     const filtered = field.choices.filter(choice =>
       choiceIds.includes(choice.id)
     );
     if (!filtered || filtered.length === 0) {
       return false;
     }
 
     const currentChoice = filtered[0];
     if (currentChoice && currentChoice.descriptionVisible) {
       return true;
     }
   }
};

/**
 *
 * @param {*} field
 * @param {*} response
 * @param {*} choice
 */
const isCheckboxChecked = (fieldId, response, choice) => {
  // Get current value for field from response
  const responseField = response.fields.find(f => f.id === fieldId);
  if (!responseField) {
    return false;
  }

  return responseField.choiceIds && responseField.choiceIds.includes(choice.id);
};

/**
 *
 * @param {*} fieldId
 * @param {*} inquiryId
 * @param {*} value
 */
const setCheckbox = (fieldId, response, value, multipleChoice = false) => {
  store.dispatch("response/setField", {
    id: response.id,
    field: {
      id: fieldId,
      attribute: "choiceIds",
      value,
      multipleChoice,
    },
  });
};

/**
 *
 * @param {*} fieldId
 * @param {*} inquiryId
 * @param {*} attribute
 * @param {*} value
 */
const setSelectionCondition = (fieldId, response, attribute, value) => {
  store.dispatch("response/setField", {
    id: response.id,
    field: {
      id: fieldId,
      attribute,
      value,
    },
  });
};

/**
 *
 * @param {*} fieldId
 * @param {*} inquiryId
 * @param {*} value
 */
const setDescriptionText = (fieldId, response, value) => {
  store.dispatch("response/setField", {
    id: response.id,
    field: {
      id: fieldId,
      attribute: "text",
      value,
    },
  });
};

/**
 *
 * @param {*} fieldId
 * @param {*} inquiryId
 * @param {*} userInput
 */
const setCalculatedValue = async (fieldId, response, subfield, operation, userInput) => {
  // Get field from response by passed in ID
  const responseField = response.fields.find(obj => obj.id === fieldId);

  // Get existing calculation inputs from response field
  let inputs = responseField ? JSON.parse(JSON.stringify(responseField.inputs)) : [];

  // Add new input or update existing one if it already exists
  const inputIndex = inputs.findIndex(input => input.fieldId == subfield.fieldId);
  if (inputIndex === -1) {
    inputs.push({ "fieldId": userInput.fieldId, "value": userInput.value });
  } else {
    inputs[inputIndex] = { "fieldId": userInput.fieldId, "value": userInput.value };
  }
  // Remove entry if user entry value is null
  if (userInput === null) {
    inputs.splice(inputIndex, 1);
  }

  // Update inputs in response field
  await store.dispatch("response/setField", {
    id: response.id,
    field: {
      id: fieldId,
      attribute: "inputs",
      inputs,
    },
  });

  // Calculate the sum of all inputs and set it as the value of the field. Make sure that the first value is used as the initial value for the sum
  let sum = 0;
  let initial = true;
  inputs.forEach(input => {
    // Check if input is a number
    if (input.value === "") {
      return;
    }
    
    let userInputValue = input.value;
    if (input.value && isNaN(input.value)) {
      userInputValue = input.value.replace(',', '.');
    }

    // To avoid multiplication with 0, set first value as initial value    
    if (initial) {
      sum = parseFloat(userInputValue);
      initial = false;
    } else if (operation === "multiply") {
      sum *= parseFloat(userInputValue);
    } else if (operation === "add") {
      sum += parseFloat(userInputValue);
    }
  });

  // Set calculated value as the text value of the field
  store.dispatch("response/setField", {
    id: response.id,
    field: {
      id: fieldId,
      attribute: "text",
      value: sum.toLocaleString("de-DE"),
    },
  });
};

export {
  isBlockVisible,
  isOptionVisible,
  isFieldRequired,
  isDescriptionRequired,
  isDescriptionVisible,
  setSelectionCondition,
  isCheckboxChecked,
  setCheckbox,
  setDescriptionText,
  setCalculatedValue
};
